import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../Checkbox';
import CustomSlider from '../CustomSlider';
import AnimationControl from '../AnimationControl';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as RightArrowIcon } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/arrow-down.svg';

import styles from './styles.module.scss';

const CharacterInfo: React.FC = () => {
  const { t } = useTranslation();
  const { changeCharacterDetails, sceneCharacters, selectedCharacterIndex } = useContext(UserContext);
  const [showAnimation, setShowAnimation] = useState(true);
  const [isShow, setIsShow] = useState(true);

  if (selectedCharacterIndex === -1 || sceneCharacters.length === 0 || !sceneCharacters[selectedCharacterIndex].visible)
    return null;

  const selectedObject = sceneCharacters[selectedCharacterIndex];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {isShow ? (
          <DownArrowIcon className={styles.icon} onClick={() => setIsShow(false)} />
        ) : (
          <RightArrowIcon className={styles.icon} onClick={() => setIsShow(true)} />
        )}
        <p className={styles.title}>{t(selectedObject?.name) ?? t('Character Information')}</p>
      </div>
      {isShow && (
        <div className={styles.contentWrapper}>
          <Checkbox
            id={selectedCharacterIndex}
            label={'Show Model'}
            parameter={'showModel'}
            isSelected={selectedObject.showModel}
            changeState={changeCharacterDetails}
          />
          <Checkbox
            id={selectedCharacterIndex}
            label={'Show Skeleton'}
            parameter={'showSkeleton'}
            isSelected={selectedObject.showSkeleton}
            changeState={changeCharacterDetails}
          />
          <hr className={styles.line} />
          <CustomSlider
            id={selectedCharacterIndex}
            parameter={'opacity'}
            label={'Opacity'}
            defaultValue={selectedObject.opacity}
            changeValue={changeCharacterDetails}
            step={5}
            min={0}
            max={100}
          />
          <hr className={styles.line} />
          <div
            className={styles.animationTitle}
            onClick={() => {
              setShowAnimation(prev => !prev);
            }}
          >
            <span className={styles.animation}>{t('Animation')}</span>
            {showAnimation ? <DownArrowIcon /> : <RightArrowIcon />}
          </div>
          {showAnimation && (
            <div className={styles.animationWrapper}>
              {selectedObject.animationList?.map((animation, index: number) => (
                <AnimationControl
                  key={index}
                  id={selectedCharacterIndex}
                  index={index}
                  label={animation}
                  currentAnimationIndex={selectedObject.currentAnimationIndex}
                  changeIndex={changeCharacterDetails}
                />
              ))}
            </div>
          )}
          <hr className={styles.line} />
          <CustomSlider
            id={selectedCharacterIndex}
            parameter={'modifySpeed'}
            label={'Modify Speed'}
            defaultValue={selectedObject.modifySpeed}
            changeValue={changeCharacterDetails}
            step={0.2}
            min={0}
            max={3}
          />
        </div>
      )}
    </div>
  );
};

export default CharacterInfo;
