import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ReactComponent as PlusBlueIcon } from '../../../assets/icons/plus-blue.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import NewSessionIdea from '../NewSessionIdea';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  sessionIndex: number;
  isAddNewSubSession: boolean;
  setIsSubSessionDraggable: React.Dispatch<React.SetStateAction<boolean>>;
  canvasRef: React.RefObject<ExportObject>;
};

const SubSession: React.FC<Props> = ({ sessionIndex, isAddNewSubSession, setIsSubSessionDraggable, canvasRef }) => {
  const { t } = useTranslation();
  const { newSession, selectedLang, setNewSession } = useContext(UserContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [len, setLen] = useState(newSession.subSessions[sessionIndex].ideas.length);
  const [isAddNewIdea, setIsAddNewIdea] = useState(false);

  const onPlus = () => {
    const tempArray = { ...newSession };
    if (tempArray.subSessions[sessionIndex].ideas[tempArray.subSessions[sessionIndex].ideas.length - 1].idea === -1) {
      setIsAddNewIdea(true);
    } else {
      tempArray.subSessions[sessionIndex].ideas.push({
        id: len,
        idea: -1,
      });
      tempArray.subSessions[sessionIndex].repeat_number.push(0);
      tempArray.subSessions[sessionIndex].execution_time.push('');
      setNewSession(tempArray);
      setLen(prev => prev + 1);
      setIsAddNewIdea(false);
    }
  };

  const onDelete = () => {
    const tempArray = { ...newSession };
    if (tempArray.subSessions.length === 1) {
      tempArray.subSessions = [
        {
          id: 0,
          session: -1,
          title_EN: '',
          title_DU: '',
          title_FR: '',
          title_GE: '',
          text_EN: '',
          text_DU: '',
          text_FR: '',
          text_GE: '',
          repeat_number: [0],
          execution_time: [''],
          ideas: [
            {
              id: 0,
              idea: -1,
            },
          ],
        },
      ];
      setNewSession(tempArray);
    } else {
      tempArray.subSessions.splice(sessionIndex, 1);
      setNewSession(tempArray);
    }
  };

  const onDragEnd = (result: any, e: any) => {
    if (!result.destination) return;
    const tempArray = { ...newSession };
    if (
      tempArray.subSessions[sessionIndex].ideas[result.source.index].idea === -1 ||
      (tempArray.subSessions[sessionIndex].ideas[result.source.index].idea !== -1 &&
        tempArray.subSessions[sessionIndex].ideas[result.destination.index].idea === -1)
    ) {
      return;
    } else {
      const [movedIdea] = tempArray.subSessions[sessionIndex].ideas.splice(result.source.index, 1);
      const [movedNumber] = tempArray.subSessions[sessionIndex].repeat_number.splice(result.source.index, 1);
      const [movedTime] = tempArray.subSessions[sessionIndex].execution_time.splice(result.source.index, 1);
      tempArray.subSessions[sessionIndex].ideas.splice(result.destination.index, 0, movedIdea);
      tempArray.subSessions[sessionIndex].repeat_number.splice(result.destination.index, 0, movedNumber);
      tempArray.subSessions[sessionIndex].execution_time.splice(result.destination.index, 0, movedTime);
      setNewSession(tempArray);
    }
  };

  useEffect(() => {
    setTitle(newSession.subSessions[sessionIndex][`title_${selectedLang}`]);
    setDescription(newSession.subSessions[sessionIndex][`text_${selectedLang}`]);
  }, [selectedLang]);

  useMemo(() => {
    setTitle(newSession.subSessions[sessionIndex][`title_${selectedLang}`]);
  }, [newSession.subSessions[sessionIndex][`title_${selectedLang}`]]);

  useMemo(() => {
    setDescription(newSession.subSessions[sessionIndex][`text_${selectedLang}`]);
  }, [newSession.subSessions[sessionIndex][`text_${selectedLang}`]]);

  useEffect(() => {
    const tempArray = { ...newSession };
    tempArray.subSessions[sessionIndex][`title_${selectedLang}`] = title;
    setNewSession(tempArray);
  }, [title]);

  useEffect(() => {
    const tempArray = { ...newSession };
    tempArray.subSessions[sessionIndex][`text_${selectedLang}`] = description;
    setNewSession(tempArray);
  }, [description]);

  useEffect(() => {
    if (newSession.subSessions[sessionIndex].ideas[newSession.subSessions[sessionIndex].ideas.length - 1].idea !== -1) {
      setIsAddNewIdea(false);
    }
  }, [newSession.subSessions[sessionIndex].ideas[newSession.subSessions[sessionIndex].ideas.length - 1].idea]);

  return (
    <div className={styles.wrapper}>
      <CloseIcon className={styles.close} onClick={onDelete} />
      <div
        className={classNames(styles.content, {
          [styles.isAddNewSubSession]: isAddNewSubSession && sessionIndex === newSession.subSessions.length - 1,
        })}
      >
        <div className={styles.header}></div>
        <input
          placeholder={t('Text SubTitle')}
          value={title}
          onChange={e => setTitle(e.target.value)}
          type="text"
          className={styles.title}
        />
        <textarea
          placeholder={t('Text SubSession Description')}
          value={description}
          onChange={e => setDescription(e.target.value)}
          className={styles.description}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableIdea" direction="horizontal">
            {({ innerRef }) => (
              <div className={styles.ideasWrapper} ref={innerRef}>
                {newSession.subSessions[sessionIndex].ideas.map((item, index) => (
                  <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          onMouseEnter={(e: any) => {
                            e.stopPropagation();
                            setIsSubSessionDraggable(true);
                          }}
                          onMouseLeave={(e: any) => {
                            e.stopPropagation();
                            setIsSubSessionDraggable(false);
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <NewSessionIdea
                            ideaIndex={index}
                            sessionIndex={sessionIndex}
                            canvasRef={canvasRef}
                            isAddNewIdea={isAddNewIdea}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                <div className={styles.plusWrapper}>
                  <PlusBlueIcon className={styles.icon} onClick={onPlus} />
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default SubSession;
