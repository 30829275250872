import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Modal from '../Modal';
import Dropdown from '../Dropdown';
import LanguageTabButton from '../LanguageTabButton';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SaveSessionModal: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const {
    newSession,
    sessionCustomCategories,
    selectedLang,
    memberType,
    sessions,
    setSessions,
    userId,
    userInfo,
    setIsLoading,
    setNewSession,
    setOpenSessionsModal,
    setModifySessionId,
    setSessionID,
    setOpenIdeasModal,
    setSubSessionIds,
  } = useContext(UserContext);
  const [language, setLanguage] = useState<'EN' | 'FR' | 'DU' | 'GE'>(selectedLang);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [category, setCategory] = useState<string>(sessionCustomCategories[language][0]);

  const saveSession = async () => {
    try {
      setIsLoading(true);
      const tempNewSession = { ...newSession };

      for (let i = tempNewSession.subSessions.length - 1; i >= 0; i--) {
        if (
          tempNewSession.subSessions[i][`title_${selectedLang}`] === '' ||
          tempNewSession.subSessions[i].ideas[0].idea === -1
        ) {
          tempNewSession.subSessions.splice(i, 1);
        }
      }

      const subSessionIDArray = [];

      for await (const subSession of tempNewSession.subSessions) {
        const ideaIDArray = [];
        for (let i = 0; i < subSession.ideas.length; i++) {
          if (subSession.ideas[i].idea !== -1) {
            ideaIDArray.push(subSession.ideas[i].idea);
          }
        }
        const resIdeasCount = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/create-ideas`, {
          ids: ideaIDArray,
        });

        const resIdeas = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/lastRecords`, {
          count: resIdeasCount.data.count,
        });

        const resIdeasDescription = await axios.post(
          `${process.env.REACT_APP_MAIN_API}session_idea_description/create-descriptions`,
          {
            ids: ideaIDArray,
            sids: resIdeas.data.data,
          }
        );

        const tempTime: { [key: string]: any } = {};
        const tempNumber: { [key: string]: any } = {};
        for (let j = 0; j < resIdeas.data.data.length; j++) {
          tempTime[`idea_${resIdeas.data.data[j]}`] = subSession.execution_time[j];
          tempNumber[`idea_${resIdeas.data.data[j]}`] = subSession.repeat_number[j];
        }

        const resSub = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/create_sub_session`, {
          data: {
            title_EN: subSession.title_EN === '' ? subSession[`title_${selectedLang}`] : subSession.title_EN,
            title_FR: subSession.title_FR === '' ? subSession[`title_${selectedLang}`] : subSession.title_FR,
            title_DU: subSession.title_DU === '' ? subSession[`title_${selectedLang}`] : subSession.title_DU,
            title_GE: subSession.title_GE === '' ? subSession[`title_${selectedLang}`] : subSession.title_GE,
            text_EN: subSession.title_EN === '' ? subSession[`title_${selectedLang}`] : subSession.text_EN,
            text_FR: subSession.title_FR === '' ? subSession[`title_${selectedLang}`] : subSession.text_FR,
            text_DU: subSession.title_DU === '' ? subSession[`title_${selectedLang}`] : subSession.text_DU,
            text_GE: subSession.title_GE === '' ? subSession[`title_${selectedLang}`] : subSession.text_GE,
            ideas: JSON.stringify(resIdeas.data.data),
            execution_time: JSON.stringify(tempTime),
            repeat_number: JSON.stringify(tempNumber),
          },
        });

        subSessionIDArray.push(resSub.data.id);
      }

      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/create-session/-1`, {
        data: {
          uid: userId,
          author: userInfo.fname,
          title_EN: tempNewSession.title_EN === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_EN,
          title_FR: tempNewSession.title_FR === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_FR,
          title_DU: tempNewSession.title_DU === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_DU,
          title_GE: tempNewSession.title_GE === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_GE,
          text_EN: tempNewSession.text_EN === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_EN,
          text_FR: tempNewSession.text_FR === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_FR,
          text_DU: tempNewSession.text_DU === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_DU,
          text_GE: tempNewSession.text_GE === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_GE,
          category_EN: '',
          category_FR: '',
          category_DU: '',
          category_GE: '',
          custom_category_EN: tempNewSession.custom_category_EN,
          custom_category_FR: tempNewSession.custom_category_FR,
          custom_category_DU: tempNewSession.custom_category_DU,
          custom_category_GE: tempNewSession.custom_category_GE,
          sub_data: JSON.stringify(subSessionIDArray.sort((a, b) => a - b)),
          approval: false,
          isShared: false,
          rejected: false,
        },
      });

      setSessionID(res.data.id);
      setSubSessionIds(subSessionIDArray.sort((a, b) => a - b));

      const tempSessions = [...sessions];
      tempSessions.push({
        id: res.data.id,
        uid: res.data.uid,
        author: res.data.author,
        title_EN: res.data.title_EN,
        title_DU: res.data.title_DU,
        title_FR: res.data.title_FR,
        title_GE: res.data.title_GE,
        text_EN: res.data.text_EN,
        text_DU: res.data.text_DU,
        text_FR: res.data.text_FR,
        text_GE: res.data.text_GE,
        category_EN: res.data.category_EN,
        category_DU: res.data.category_DU,
        category_FR: res.data.category_FR,
        category_GE: res.data.category_GE,
        customCategory_EN: res.data.custom_category_EN,
        customCategory_DU: res.data.custom_category_DU,
        customCategory_FR: res.data.custom_category_FR,
        customCategory_GE: res.data.custom_category_GE,
        subData: JSON.parse(res.data.sub_data),
        approval: res.data?.approval,
        isShared: res.data?.isShared,
        rejected: res.data?.rejected,
        date: res.data.date,
      });
      setSessions(tempSessions);

      setOpenSessionsModal(true);
      setTimeout(() => {
        setOpenIdeasModal(true);
      }, 100);
      setNewSession({
        title_EN: '',
        title_DU: '',
        title_FR: '',
        title_GE: '',
        text_EN: '',
        text_DU: '',
        text_FR: '',
        text_GE: '',
        category_EN: '',
        category_DU: '',
        category_FR: '',
        category_GE: '',
        custom_category_EN: '',
        custom_category_DU: '',
        custom_category_FR: '',
        custom_category_GE: '',
        approval: false,
        isShared: false,
        rejected: false,
        subSessions: [
          {
            id: 0,
            session: -1,
            title_EN: '',
            title_DU: '',
            title_FR: '',
            title_GE: '',
            text_EN: '',
            text_DU: '',
            text_FR: '',
            text_GE: '',
            repeat_number: [0],
            execution_time: [''],
            ideas: [
              {
                id: 0,
                idea: -1,
              },
            ],
          },
        ],
      });
      setModifySessionId(-1);
      setOpen(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    if (sessionCustomCategories[language][0]) {
      setCategory(sessionCustomCategories[language][0]);
    }
  }, [sessionCustomCategories[language][0]]);

  useEffect(() => {
    setCategory(sessionCustomCategories[language][categoryIndex]);
  }, [language]);

  useEffect(() => {
    setCategoryIndex(sessionCustomCategories[language].findIndex(e => e === category));
  }, [category]);

  useEffect(() => {
    const tempNewSession = { ...newSession };
    tempNewSession.custom_category_EN = sessionCustomCategories.EN[categoryIndex];
    tempNewSession.custom_category_FR = sessionCustomCategories.FR[categoryIndex];
    tempNewSession.custom_category_DU = sessionCustomCategories.DU[categoryIndex];
    tempNewSession.custom_category_GE = sessionCustomCategories.GE[categoryIndex];

    // if (memberType !== 'member') {
    //   tempNewSession.category_EN = sessionCustomCategories.EN[categoryIndex];
    //   tempNewSession.category_FR = sessionCustomCategories.FR[categoryIndex];
    //   tempNewSession.category_DU = sessionCustomCategories.DU[categoryIndex];
    //   tempNewSession.category_GE = sessionCustomCategories.GE[categoryIndex];
    // }

    setNewSession(tempNewSession);
  }, [categoryIndex, open]);

  useEffect(() => {
    setCategory(sessionCustomCategories[language][0]);
    setCategoryIndex(0);
  }, [open]);

  useEffect(() => {
    setLanguage(selectedLang);
  }, [selectedLang]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.saveModal}
      actionButtonLabel={t('Save')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={saveSession}
      closeButtonColor="Black"
    >
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{t('Save Session')}</p>
        </div>
        <div className={styles.categoryWrapper}>
          <div className={styles.title}>
            {t('Select Category')}
            <span className={styles.lang}>{`(${language.toLowerCase()})`}</span>
          </div>
          <Dropdown
            className={styles.category}
            selectedItem={category}
            setSelectedItem={setCategory}
            items={sessionCustomCategories[language]}
          />
        </div>
      </div>
      {/* <LanguageTabButton language={language} setLanguage={setLanguage} /> */}
    </Modal>
  );
};

export default SaveSessionModal;
