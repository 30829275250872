import React, { useContext, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  uploadImage: any;
};

const ImageUploadModal: React.FC<Props> = ({ open, setOpen, uploadImage }) => {
  const { t } = useTranslation();
  const { customImage } = useContext(UserContext);
  const [selectedImage, setSelectedImage] = useState('');
  const [file, setFile] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e: any) => {
    setFile(e);
  };

  const toImage = (e: any) => {
    const fr = new FileReader();
    fr.readAsArrayBuffer(e);
    fr.onload = function () {
      if (fr.result !== null) {
        const blob = new Blob([fr.result]);
        setSelectedImage(URL.createObjectURL(blob));
      }
    };
  };

  const saveImage = () => {
    if (file !== null) {
      uploadImage(file[0]);
      setOpen(false);
    } else {
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (file !== null) {
      toImage(file[0]);
      setIsValid(false);
    }
  }, [file]);

  useEffect(() => {
    // if (!open) {
    setIsValid(false);
    setFile(null);
    setSelectedImage(customImage);
    // }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.modal}
      actionButtonLabel={t('Save')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={saveImage}
      closeButtonColor="Black"
    >
      <div className={styles.wrapper}>
        <p className={styles.title}>{t('Select Image')}</p>
        <FileUploader multiple={true} handleChange={handleChange} name="file" types={['JPEG', 'PNG', 'GIF', 'JPG']} />
        {selectedImage !== '' && (
          <div className={styles.imageWrapper}>
            <img className={styles.image} src={selectedImage} alt="" />
          </div>
        )}
        {isValid && <p className={styles.validText}>{t('Select Image')}</p>}
      </div>
    </Modal>
  );
};

export default ImageUploadModal;
