import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomSlider from '../CustomSlider';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as RightArrowIcon } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/arrow-down.svg';

import styles from './styles.module.scss';

const SportsObjectInfo = () => {
  const { t } = useTranslation();
  const { changeSportsObjectDetails, selectedSportsObjectIndex, sceneSportsObjects } = useContext(UserContext);
  const [isShow, setIsShow] = useState(true);

  if (
    selectedSportsObjectIndex === -1 ||
    sceneSportsObjects.length === 0 ||
    !sceneSportsObjects[selectedSportsObjectIndex].visible
  )
    return null;

  const selectedObject = sceneSportsObjects[selectedSportsObjectIndex];
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {isShow ? (
          <DownArrowIcon className={styles.icon} onClick={() => setIsShow(false)} />
        ) : (
          <RightArrowIcon className={styles.icon} onClick={() => setIsShow(true)} />
        )}
        <p className={styles.title}>{selectedObject?.name ?? t('SportsObject Information')}</p>
      </div>
      {isShow && (
        <div className={styles.contentWrapper}>
          <CustomSlider
            id={selectedSportsObjectIndex}
            parameter={'opacity'}
            label={'Opacity'}
            defaultValue={selectedObject.opacity}
            changeValue={changeSportsObjectDetails}
            step={5}
            min={0}
            max={100}
          />
        </div>
      )}
    </div>
  );
};

export default SportsObjectInfo;
