import React, { useContext, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';
import ReactPlayer from 'react-player';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const VideoUploadModal: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { customVideo, setCustomVideo, setCustomVideoBlob } = useContext(UserContext);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [file, setFile] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e: any) => {
    setFile(e);
  };

  const toVideo = (e: any, isSave: boolean) => {
    const fr = new FileReader();
    fr.readAsArrayBuffer(e);
    fr.onload = function () {
      if (fr.result !== null) {
        const blob = new Blob([fr.result]);
        if (isSave) {
          setCustomVideoBlob(blob);
          setCustomVideo(URL.createObjectURL(blob));
        } else {
          setSelectedVideo(URL.createObjectURL(blob));
        }
      }
    };
  };

  const saveVideo = () => {
    if (file !== null) {
      toVideo(file[0], true);
      setOpen(false);
    } else if (selectedVideo !== '') {
      setOpen(false);
      setCustomVideo(selectedVideo);
    } else {
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (file !== null) {
      toVideo(file[0], false);
      setIsValid(false);
    }
  }, [file]);

  useEffect(() => {
    setSelectedVideo(customVideo);
  }, [customVideo]);

  useEffect(() => {
    if (!open) {
      setIsValid(false);
      setFile(null);
      setSelectedVideo(customVideo);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.modal}
      actionButtonLabel={t('Save')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={saveVideo}
      closeButtonColor="Black"
    >
      <div className={styles.wrapper}>
        <p className={styles.title}>{t('Select Video')}</p>
        <input
          className={styles.input}
          placeholder={t('Enter Video Link')}
          value={selectedVideo}
          onChange={e => {
            setFile(null);
            setSelectedVideo(e.target.value);
          }}
        />
        <FileUploader multiple={true} handleChange={handleChange} name="file" types={['MP4', 'MOV', 'WEBM']} />
        {selectedVideo !== '' && (
          <div className={styles.imageWrapper}>
            <ReactPlayer
              className={styles.image}
              url={selectedVideo}
              controls={true}
              loop={true}
              width="100%"
              height="100%"
              config={{
                facebook: {
                  attributes: {
                    height: '300px',
                  },
                },
              }}
            />
          </div>
        )}
        {isValid && <p className={styles.validText}>{t('Select Video')}</p>}
      </div>
    </Modal>
  );
};

export default VideoUploadModal;
