import classNames from 'classnames';
import React, { useContext, useState } from 'react';

import SubscribeModal from '../SubscribeModal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

const SubSportsField: React.FC = () => {
  const { setSportsFieldObject, firstLevel, isSubSportsField, membershipState, memberType } = useContext(UserContext);
  const [isMemberModal, setIsMemberModal] = useState(false);

  if (!isSubSportsField || !firstLevel) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.overflow}>
        {firstLevel.map((item, index: number) => (
          <button
            key={index}
            className={classNames(styles.button)}
            onClick={() => {
              if (!item?.demo && membershipState === 0 && memberType === 'member') {
                setIsMemberModal(true);
              } else {
                setSportsFieldObject({ object: item.object ?? '' });
              }
            }}
          >
            <div className={styles.imageWrapper}>
              <img
                className={classNames(styles.img, {
                  [styles.demo]: !item?.demo && membershipState === 0 && memberType === 'member',
                })}
                alt="objectImage"
                src={item.image}
              />
            </div>
          </button>
        ))}
      </div>
      <SubscribeModal
        open={isMemberModal}
        setOpen={setIsMemberModal}
        description={'Please upgrade your subscription plan to unlock it.'}
      />
    </div>
  );
};

export default SubSportsField;
