import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Modal from '../Modal';
import Idea from '../Idea';
import FolderCategoryBar from '../FolderCategoryBar';
import SortIdeas from '../SortIdeas';
import Pagination from '../Pagination';
import Filter from '../Filter';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus1.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import { UserContext } from '../../../providers/UserProvider';
import useDebounce from '../../../hooks/useDebounce';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOriginIdeaIndex?: React.Dispatch<React.SetStateAction<number>>;
  canvasRef: React.RefObject<ExportObject>;
};

const FoldersModal: React.FC<Props> = ({ open, setOpen, setOriginIdeaIndex, canvasRef }) => {
  const { t } = useTranslation();

  const {
    userId,
    folderIdeas,
    folderType,
    selectedCategory,
    sortType,
    keyWord,
    memberType,
    membershipState,
    isNewSession,
    isResetIdeas,
    setIsNewSession,
    setIsModify,
    setSceneCharacters,
    setSceneSportsObjects,
    setSceneId,
    setCharacterRotationInfo,
    setSportsObjectRotationInfo,
    setSportsFieldObject,
    setIsField,
    setCharacterMaterials,
    setDescription,
    setIsCustomImage,
    setIsSubSportsField,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    setDescriptionTitle,
    setEditorState,
    setIsSearch,
    setFolderIdeas,
    setCustomImage,
    setCustomVideo,
    setIsLoading,
    setSelectedMenuTitle,
    setIdeaType,
    setKeyWord,
    setCurrentImageBlob,
    setCustomImageBlob,
    setCustomVideoBlob,
    setCurrentImage,
  } = useContext(UserContext);

  const [pageNum, setPageNum] = useState(1);
  const [totalNum, setTotalNum] = useState(0);
  const [limit, setLimit] = useState(24);

  const [searchKeyWord, setSearchKeyWord] = useState(keyWord);
  const searchName = useDebounce(searchKeyWord, 1000);

  useEffect(() => {
    setKeyWord(searchName);
  }, [searchName]);

  const selectNewIdea = () => {
    setSelectedMenuTitle('IDEAS');
    setIdeaType('idea');
    setIsModify(true);
    // setSelectedCategory(selectedCategory === '' ? 'My Exercise' : selectedCategory);
    // setFolderType(folderType === '' ? 'Folders' : folderType);
    setSceneCharacters([]);
    setSceneSportsObjects([]);
    setSceneId(-1);
    setCharacterRotationInfo([]);
    setSportsObjectRotationInfo([]);
    setSportsFieldObject({
      object: '',
    });
    setIsField(false);
    setCharacterMaterials([]);
    setDescription(null);
    setIsCustomImage(false);
    setIsSubSportsField(false);
    setIsObjectColor(false);
    setIsObjectColorTwo(false);
    setIsObjectColorThree(false);
    setDescriptionTitle('');
    setEditorState('');
    setCustomImage('');
    setCustomVideo('');
    setCurrentImageBlob('');
    setCustomImageBlob('');
    setCustomVideoBlob('');
    setCurrentImage('');
    setOpen(false);
  };

  const getSearchIdeas = async (selected_category: string, page: number) => {
    try {
      setIsLoading(true);
      const tempArray: FolderIdeaType[] = [];
      const res = await axios.post(
        `${process.env.REACT_APP_MAIN_API}scene-data/search-ideas/${userId}?page=${page}&limit=${limit}`,
        {
          category: selected_category,
          key: keyWord,
          sort: sortType,
          folderType: folderType,
          memberType: memberType,
          membershipState: membershipState,
        }
      );
      setTotalNum(res?.data.totalCount);
      res?.data.data.map((item: any) => {
        tempArray.push({
          id: item.id,
          name: item.name,
          category: item.category,
          customCategory: item.custom_category,
          currentImage: item?.custom_image,
          customImage: item?.image ?? '',
          customVideo: item?.custom_video,
          time: item?.time,
          approval: item?.approval,
          isShared: item?.isShared,
          rejected: item?.rejected,
        });
      });
      setFolderIdeas(tempArray);
      setIsLoading(false);
      setIsSearch(false);
    } catch (error) {
      setIsSearch(false);
      setIsLoading(false);
      console.error(error);
    }
  };

  const closeModal = () => {
    setIsModify(true);
  };

  useEffect(() => {
    if (userId > -1) {
      setPageNum(1);
      getSearchIdeas(selectedCategory, 1);
    }
  }, [folderType, selectedCategory, sortType, limit, keyWord, userId, memberType, membershipState]);

  useEffect(() => {
    if (userId > -1) {
      getSearchIdeas(selectedCategory, pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    if (isResetIdeas) {
      if (userId > -1) {
        getSearchIdeas(selectedCategory, 1);
      }
    }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.folderModal}
      closeButtonColor="Black"
      closeIconEvent={closeModal}
    >
      <div className={styles.wrapper}>
        <FolderCategoryBar setPageNum={setPageNum} isNewSession={isNewSession} />
        <div className={styles.contentWrapper}>
          <div className={styles.buttonField}>
            {isNewSession ? (
              <div></div>
            ) : (
              <button className={styles.newIdeaBtn} onClick={selectNewIdea}>
                <PlusIcon fill="#ffffff" className={styles.icon} />
                {t('New Idea')}
              </button>
            )}
            <div className={styles.searchBar}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                // placeholder={selectedMenuTitle === 'IDEAS' ? 'name of idea' : 'name of session'}
                placeholder={t('name of idea')}
                className={styles.input}
                value={searchKeyWord}
                onChange={e => {
                  setSearchKeyWord(e.target.value);
                }}
              />
              {searchKeyWord !== '' && (
                <div className={styles.closeIconWrapper} onClick={() => setSearchKeyWord('')}>
                  <CloseIcon className={styles.closeIcon} />
                </div>
              )}
              <Filter />
            </div>
            <div className={styles.sortButton}>
              <SortIdeas />
            </div>
          </div>
          <div className={styles.contents}>
            <div className={styles.pagination}>
              <Pagination limit={limit} totalLength={totalNum} setCurrentPage={setPageNum} currentPage={pageNum} />
            </div>
            <div className={styles.ideas}>
              {folderIdeas.length !== 0 &&
                folderIdeas.map((item, index: number) => (
                  <Idea
                    key={index}
                    id={item.id}
                    name={item.name}
                    category={item.category}
                    customCategory={item.customCategory}
                    currentImage={item.currentImage}
                    customImage={item.customImage}
                    customVideo={item.customVideo}
                    approval={item.approval}
                    isShared={item.isShared}
                    rejected={item.rejected}
                    setOpen={setOpen}
                    canvasRef={canvasRef}
                    setOriginIdeaIndex={setOriginIdeaIndex}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FoldersModal;
