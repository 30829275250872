import React from 'react';

import Modal from '../Modal';
import Description from '../Description';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getIdeasNames: any;
  canvasRef: React.RefObject<ExportObject>;
};

const DescriptionModal: React.FC<Props> = ({ open, setOpen, getIdeasNames, canvasRef }) => {
  return (
    <Modal open={open} setOpen={setOpen} closeButtonColor="Black" className={styles.modal}>
      <div className={styles.content}>
        <Description getIdeasNames={getIdeasNames} setOpen={setOpen} canvasRef={canvasRef} />
      </div>
    </Modal>
  );
};

export default DescriptionModal;
