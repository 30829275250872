import { TransformControls } from '@react-three/drei';
import { useLoader, useThree } from '@react-three/fiber';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Color } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';
import { UserContext } from '../../../providers/UserProvider';

type Props = {
  id: number;
  orbit: any;
  object: SceneSportsObjectType;
};

const SportsObject: React.FC<Props> = ({
  id,
  orbit,
  object: {
    name,
    object,
    isColor,
    color,
    colorObj,
    isColorTwo,
    colorTwo,
    colorTwoObj,
    isColorThree,
    colorThree,
    colorThreeObj,
    position,
    scale,
    rotation,
    visible,
    opacity,
  },
}) => {
  const transform = useRef<any>();

  const {
    transformState,
    selectedSportsObjectIndex,
    sceneSportsObjects,
    isShowCanvasTools,
    setSelectedCharacterIndex,
    setSelectedSportsObjectIndex,
    setSecondLevel,
    setThirdLevel,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    setIsSubSportsField,
  } = useContext(UserContext);

  const { gl } = useThree();
  const { scene } = useLoader(GLTFLoader, object, (loader: any) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/');
    loader.setDRACOLoader(dracoLoader);
  });

  const cloneObject = useMemo(() => clone(scene), [scene]);
  cloneObject.traverse((item: any) => {
    if (item.isMesh) {
      item.material.transparent = true;
      item.material.opacity = opacity / 100;
      const clonedMaterial = item.material.clone();
      clonedMaterial.anisotropy = Math.min(gl.capabilities.getMaxAnisotropy(), 50);

      if (isColor) {
        if (colorObj && colorObj !== '') {
          if (color && color !== '') {
            if (item.name === colorObj) {
              clonedMaterial.color = new Color(parseInt(color));
              clonedMaterial.map = null;
            }
          }
        } else {
          if (color && color !== '') {
            clonedMaterial.color = new Color(parseInt(color));
            clonedMaterial.map = null;
          }
        }
      }

      if (isColorTwo) {
        if (colorTwoObj && colorTwoObj !== '') {
          if (colorTwo && colorTwo !== '') {
            if (item.name === colorTwoObj) {
              clonedMaterial.color = new Color(parseInt(colorTwo));
              clonedMaterial.map = null;
            }
          }
        }
      }

      if (isColorThree) {
        if (colorThreeObj && colorThreeObj !== '') {
          if (colorThree && colorThree !== '') {
            if (item.name === colorThreeObj) {
              clonedMaterial.color = new Color(parseInt(colorThree));
              clonedMaterial.map = null;
            }
          }
        }
      }

      item.material = clonedMaterial;
    }
    if (item.type === 'SkinnedMesh') {
    }
  });

  useEffect(() => {
    if (transformState !== '' && transform.current) {
      const controls: any = transform.current;
      controls.setMode(transformState);
      const callback = (event: any) => {
        orbit.current.enabled = !event.value;
      };
      controls.addEventListener('dragging-changed', callback);
      return () => controls.removeEventListener('dragging-changed', callback);
    }
  }, [transformState]);

  return (
    <group visible={visible}>
      <primitive
        name={name}
        object={cloneObject}
        position={position ? position : [0, 0, 0]}
        scale={scale ?? [1, 1, 1]}
        rotation={rotation ?? [0, 0, 0]}
        onClick={(e: any) => {
          e.stopPropagation();
          setSelectedSportsObjectIndex(id);
          setSelectedCharacterIndex(-1);
          setIsSubSportsField(false);
          if (sceneSportsObjects[id].isColor === true) {
            setIsObjectColor(true);
          }
          if (sceneSportsObjects[id].isColorTwo === true) {
            setIsObjectColorTwo(true);
          }
          if (sceneSportsObjects[id].isColorThree === true) {
            setIsObjectColorThree(true);
          }
        }}
        onPointerMissed={() => {
          setSelectedSportsObjectIndex(-1);
          setIsSubSportsField(false);
          setSecondLevel([]);
          setThirdLevel([]);
          setIsObjectColor(false);
          setIsObjectColorTwo(false);
          setIsObjectColorThree(false);
        }}
      />
      <TransformControls
        ref={transform}
        onUpdate={(self: any) => self.attach(cloneObject)}
        position={position ? position : [0, 0, 0]}
        // enabled={selectedSportsObjectIndex === id && transformState.length > 0}
        showX={selectedSportsObjectIndex === id && isShowCanvasTools}
        showY={selectedSportsObjectIndex === id && isShowCanvasTools}
        showZ={selectedSportsObjectIndex === id && isShowCanvasTools}
      />
    </group>
  );
};

export default SportsObject;
