import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import ShareModal from '../ShareModal';
import SubscribeModal from '../SubscribeModal';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-text.svg';

import styles from './styles.module.scss';

type Props = {
  id: number;
  subID: number;
  addNumber: number;
  setAddNumber: React.Dispatch<React.SetStateAction<number>>;
  addTime: string;
  setAddTime: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  editIdea: any;
};

const SessionIdeaAction: React.FC<Props> = ({
  id,
  subID,
  addNumber,
  setAddNumber,
  addTime,
  setAddTime,
  className,
  editIdea,
}) => {
  const { t } = useTranslation();
  const { sessionDetail, setSessionDetail, setIsLoading, setIsModify } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);
  const [isAddNumber, setIsAddNumber] = useState(false);
  const [isAddTime, setIsAddTime] = useState(false);
  const [tempAddNumber, setTempAddNumber] = useState<number>(addNumber);
  const [tempAddTime, setTempAddTime] = useState<string>(addTime);

  const onAction = (e: any) => {
    e.stopPropagation();
    setOpen(prev => !prev);
  };

  const onClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
    setIsAddNumber(false);
    setIsAddTime(false);
  };

  const onEdit = (e: any) => {
    e.stopPropagation();
    onClose(e);
    editIdea();
    setIsModify(true);
  };

  const onAddNumber = async (e: any) => {
    e.stopPropagation();
    setIsAddNumber(prev => !prev);
    if (isAddNumber && addNumber !== tempAddNumber) {
      try {
        setIsLoading(true);
        const currentSubSession = sessionDetail.find((s: any) => s.id === subID);

        const temp: ExecutionType = currentSubSession ? currentSubSession?.repeat_number : {};
        temp[`idea_${id}`] = tempAddNumber;

        const res = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/update_sub_session/${subID}`, {
          repeat_number: JSON.stringify(temp),
        });

        setAddNumber(tempAddNumber);
        setIsLoading(false);
        onClose(e);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  const onAddTime = async (e: any) => {
    e.stopPropagation();
    setIsAddTime(prev => !prev);
    if (isAddTime && addTime !== tempAddTime) {
      try {
        setIsLoading(true);
        const currentSubSession = sessionDetail.find((s: any) => s.id === subID);

        const temp: ExecutionType = currentSubSession ? currentSubSession?.execution_time : {};
        temp[`idea_${id}`] = tempAddTime;

        const res = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/update_sub_session/${subID}`, {
          execution_time: JSON.stringify(temp),
        });

        setAddTime(tempAddTime);
        setIsLoading(false);
        onClose(e);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  const onDelete = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const currentSubSession = sessionDetail.find((s: any) => s.id === subID);
      const ideas: number[] = [];
      if (currentSubSession && currentSubSession?.ideas.length > 0) {
        for (let i = 0; i < currentSubSession?.ideas.length; i++) {
          ideas.push(currentSubSession?.ideas[i].id);
        }
      }
      const newIdeas = ideas.filter((s: number) => s !== id);

      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/update_sub_session/${subID}`, {
        ideas: JSON.stringify(newIdeas),
      });

      const tempSessionDetail = [...sessionDetail];
      const currentSubSessionIndex = tempSessionDetail.findIndex((s: any) => s.id === subID);
      const tempArray = tempSessionDetail[currentSubSessionIndex].ideas.filter((s: any) => s.id !== id);
      tempSessionDetail[currentSubSessionIndex].ideas = tempArray;
      setSessionDetail(tempSessionDetail);

      setIsLoading(false);
      onClose(e);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <DotsIcon
            onClick={(e: any) => {
              onAction(e);
            }}
            className={classNames(styles.listIcon, {
              [styles.select]: open,
            })}
          />
          {open && (
            <>
              <div className={styles.arrowDiv} />
              <div className={classNames(styles.arrowDiv, styles.hidden)} />
              <div
                className={styles.background}
                onClick={(e: any) => {
                  onClose(e);
                }}
              ></div>
              <div className={styles.mainField}>
                <button
                  className={styles.button}
                  onClick={(e: any) => {
                    onEdit(e);
                  }}
                >
                  <EditIcon className={styles.icon} />
                  {t('Edit')}
                </button>
                <button
                  className={styles.button}
                  onClick={(e: any) => {
                    onAddNumber(e);
                  }}
                >
                  {isAddNumber ? (
                    <input
                      className={styles.input}
                      type="number"
                      min={0}
                      value={tempAddNumber}
                      onClick={e => e.stopPropagation()}
                      onChange={(e: any) => setTempAddNumber(e.target.value)}
                    />
                  ) : (
                    <EditIcon className={styles.icon} />
                  )}
                  {isAddNumber ? t('Save') : t('Add number')}
                </button>
                <button
                  className={styles.button}
                  onClick={(e: any) => {
                    onAddTime(e);
                  }}
                >
                  {isAddTime ? (
                    <input
                      className={styles.input}
                      type="text"
                      value={tempAddTime}
                      onClick={e => e.stopPropagation()}
                      onChange={(e: any) => setTempAddTime(e.target.value)}
                    />
                  ) : (
                    <EditIcon className={styles.icon} />
                  )}
                  {isAddTime ? t('Save') : t('Add time/repetition')}
                </button>
                <hr className={styles.line} />
                <button
                  className={classNames(styles.button, styles.delete)}
                  onClick={(e: any) => {
                    onDelete(e);
                  }}
                >
                  {t('Delete')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <ShareModal open={isShareModal} setOpen={setIsShareModal} />
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={`Please upgrade your subscription plan to 'Pro' to unlock it.`}
      />
    </div>
  );
};

export default SessionIdeaAction;
