import axios from 'axios';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import IdeaActions from '../IdeaActions';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as CloseFolder } from '../../../assets/icons/folder-close.svg';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play-1.svg';

import classNames from 'classnames';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  id: number;
  name: string;
  currentImage: string;
  customImage: string;
  customVideo: string;
  category: string;
  customCategory: string;
  approval: boolean;
  isShared: boolean;
  rejected: boolean;
  canvasRef: React.RefObject<ExportObject>;
  setOriginIdeaIndex?: React.Dispatch<React.SetStateAction<number>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Idea: React.FC<Props> = ({
  id,
  name,
  currentImage,
  customImage,
  customVideo,
  category,
  customCategory,
  approval,
  isShared,
  rejected,
  canvasRef,
  setOriginIdeaIndex,
  setOpen,
}) => {
  const { t } = useTranslation();
  const {
    folderType,
    isNewSession,
    setIsNewSession,
    setFromIdea,
    setSceneCharacters,
    setSceneSportsObjects,
    setSceneId,
    setCharacterRotationInfo,
    setSportsObjectRotationInfo,
    setSportsFieldObject,
    setIsField,
    setCharacterMaterials,
    setIsSavedState,
    setCurrentImage,
    setDescription,
    setIsCustomImage,
    setIsSubSportsField,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    setDescriptionTitle,
    setEditorState,
    setIsPDFModal,
    setCustomImage,
    setCustomVideo,
    setScreenshot,
    setCurrentImageBlob,
    setIsLoading,
    setSelectedMenuTitle,
    setIdeaType,
    setSelectedSessionIdeaID,
    setSaveIdeaName,
    setCustomImageBlob,
    setCustomVideoBlob,
  } = useContext(UserContext);

  const clearScene = () => {
    setSceneCharacters([]);
    setSceneSportsObjects([]);
    setSceneId(-1);
    setCharacterRotationInfo([]);
    setSportsObjectRotationInfo([]);
    setSportsFieldObject({
      object: '',
    });
    setIsField(false);
    setCharacterMaterials([]);
    setDescription(null);
    setIsCustomImage(false);
    setIsSubSportsField(false);
    setIsObjectColor(false);
    setIsObjectColorTwo(false);
    setIsObjectColorThree(false);
    setDescriptionTitle('');
    setEditorState('');
    setCustomImage('');
    setCustomVideo('');
    setScreenshot('');
    setCurrentImageBlob('');
    setCustomImageBlob('');
    setCustomVideoBlob('');
    setCurrentImage('');
    setSelectedSessionIdeaID(-1);
  };

  const selectIdea = async () => {
    // if (isNewSession) {
    //   setOpen(false);
    //   if (!!setIsNewSession) {
    //     setIsNewSession(false);
    //   }
    //   if (!!setOriginIdeaIndex) {
    //     setOriginIdeaIndex(id);
    //   }
    // } else {
    clearScene();
    setSelectedMenuTitle('IDEAS');
    setIdeaType('idea');
    if (!isNewSession) {
      setOpen(false);
    }
    setFromIdea(true);
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}/scene-data/import-idea`, {
        id: id,
      });
      const characterString = JSON.parse(res.data.character);
      const characterTransformString = JSON.parse(res.data.character_transform);
      const objectString = JSON.parse(res.data.object);
      const objectTransformString = JSON.parse(res.data.object_transform);
      const sportsFieldString = JSON.parse(res.data.field);
      const characterMaterialString = JSON.parse(res.data.character_material);

      setSceneId(res.data.id);
      setSaveIdeaName(res.data.name);
      setCustomImage(res.data.image);
      setCurrentImage(res.data.custom_image);
      setCustomVideo(res.data.custom_video);
      setIsCustomImage(res.data.isCustomImage);

      const tempCharacterArray: SceneCharacterType[] = [];
      const tempCharacterRotationInfo: number[] = [];
      characterString?.map((item: any, index: number) => {
        tempCharacterArray.push({
          id: item?.id,
          object: item?.object,
          material: item?.material,
          name: item?.name,
          showModel: item?.showModel,
          showSkeleton: item?.showSkeleton,
          opacity: item?.opacity,
          modifySpeed: item?.modifySpeed,
          isSingleMode: item?.isSingleMode,
          position: characterTransformString[index].position,
          scale: [
            item?.scale[0] * characterTransformString[index].scale[0],
            item?.scale[1] * characterTransformString[index].scale[1],
            item?.scale[2] * characterTransformString[index].scale[2],
          ],
          rotation: characterTransformString[index].rotation,
          visible: item?.visible,
        });
        tempCharacterRotationInfo.push(characterTransformString[index].rotation);
      });
      setSceneCharacters(tempCharacterArray);
      setCharacterRotationInfo(tempCharacterRotationInfo);

      const tempSportsObjectArray: SceneSportsObjectType[] = [];
      const tempSportsObjectRotationInfo: number[] = [];
      objectString.map((item: any, index: number) => {
        tempSportsObjectArray.push({
          object: item?.object,
          name: item?.name,
          opacity: item?.opacity,
          color: item?.color,
          isColor: item?.isColor,
          colorObj: item?.colorObj,
          colorTwo: item?.colorTwo,
          isColorTwo: item?.isColorTwo,
          colorTwoObj: item?.colorTwoObj,
          colorThree: item?.colorThree,
          isColorThree: item?.isColorThree,
          colorThreeObj: item?.colorThreeObj,
          position: objectTransformString[index]?.position,
          rotation: objectTransformString[index]?.rotation,
          scale: [
            objectTransformString[index]?.scale[0] * item?.scale[0],
            objectTransformString[index]?.scale[1] * item?.scale[1],
            objectTransformString[index]?.scale[2] * item?.scale[2],
          ],
          visible: item?.visible,
        });
        tempSportsObjectRotationInfo.push(objectTransformString[index]?.rotation);
      });
      setSceneSportsObjects(tempSportsObjectArray);
      setSportsObjectRotationInfo(tempSportsObjectRotationInfo);

      setSportsFieldObject(sportsFieldString?.sportsFieldObject);
      setIsField(sportsFieldString?.isField);
      setCharacterMaterials(characterMaterialString);

      if (isNewSession) {
        const resDescription = await axios.get(`${process.env.REACT_APP_MAIN_API}description/get-data/${id}`);
        setDescription(resDescription.data);
      }

      setIsLoading(false);
      // canvasRef.current?.generateScreenObject();
      setIsPDFModal(true);
      setIsSavedState(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
    // }
  };

  const onAdd = (e: any) => {
    e.stopPropagation();
    setSelectedMenuTitle('SESSIONS');
    setOpen(false);
    if (!!setIsNewSession) {
      setIsNewSession(false);
    }
    if (!!setOriginIdeaIndex) {
      setOriginIdeaIndex(id);
    }
  };

  return (
    <div
      onClick={selectIdea}
      className={classNames(styles.wrapper, {
        [styles.approval]: approval && !rejected,
        [styles.isShared]: isShared && !approval && !rejected,
        [styles.dismiss]: rejected,
      })}
    >
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{name}</span>
        {isNewSession ? (
          <button onClick={onAdd} className={styles.addButton}>
            <span>+</span> {t('Add')}
          </button>
        ) : (
          <IdeaActions id={id} />
        )}
      </div>
      {customVideo !== '' ? (
        <div className={styles.imageWrapper}>
          {currentImage !== '' && <img className={styles.image} src={currentImage} alt="" />}
          {currentImage === '' && customImage !== '' && <img className={styles.image} src={customImage} alt="" />}
          <div className={styles.overlap}>
            <PlayIcon
              fill={customImage === '' && currentImage === '' ? '#000000' : '#ffffff'}
              className={styles.icon}
            />
          </div>
        </div>
      ) : currentImage !== '' ? (
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={currentImage} alt="" />
        </div>
      ) : customImage !== '' ? (
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={customImage} alt="" />
        </div>
      ) : (
        <div className={styles.imageWrapper}></div>
      )}
      <div className={styles.category}>
        <CloseFolder className={styles.icon} />
        <p className={styles.label}>
          {folderType === 'Folders' ? customCategory : category === '' ? customCategory : category}
        </p>
      </div>
    </div>
  );
};

export default Idea;
