import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action?: () => void;
  cancelButtonEvent?: () => void;
  actionButtonLabel: string;
  cancelButtonLabel?: string;
  description: string;
};

const NotificationModal: React.FC<Props> = ({
  open,
  setOpen,
  action,
  actionButtonLabel,
  cancelButtonLabel = 'Not now',
  description,
  cancelButtonEvent,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.subscribeModal}
      actionButtonLabel={t(actionButtonLabel)}
      cancelButtonLabel={t(cancelButtonLabel)}
      cancelButtonEvent={cancelButtonEvent}
      actionButtonEvent={action}
      closeButtonColor="Black"
    >
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t(description)}</p>
      </div>
    </Modal>
  );
};

export default NotificationModal;
