import React from 'react';

import UserProvider from './providers/UserProvider';
import MainRoute from './routes/MainRoute';

import './App.css';

const App: React.FC = () => {
  return (
    <UserProvider>
      <MainRoute />
    </UserProvider>
  );
};

export default App;
