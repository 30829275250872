import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  id: number;
  label: string;
  parameter: string;
  isSelected: boolean;
  changeState: any;
};
const Checkbox: React.FC<Props> = ({ id, label, parameter, isSelected, changeState }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.Wrapper}>
      <span className={styles.label}>{t(label)}</span>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={(e: any) => {
          changeState(id, { [parameter]: e.target.checked });
        }}
      />
    </div>
  );
};

export default Checkbox;
