import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';

import HelpModal from '../HelpModal';
import SaveModal from '../SaveModal';
import DescriptionModal from '../DescriptionModal';
import PDFModal from '../PDFModal';
import SubscribeModal from '../SubscribeModal';
import NotificationModal from '../NotificationModal';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as CameraIcon } from '../../../assets/icons/image-generate.svg';
import { ReactComponent as HelpIcon } from '../../../assets/icons/question-chat.svg';
import { ReactComponent as PDFIcon } from '../../../assets/icons/generate-pdf.svg';
import { ReactComponent as PencilIcon } from '../../../assets/icons/edit-text.svg';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  canvasRef: React.RefObject<ExportObject>;
};

const ExtraTools: React.FC<Props> = ({ canvasRef }) => {
  const {
    sceneId,
    userId,
    membershipState,
    memberType,
    names,
    isPDFModal,
    ideaType,
    selectedSessionIdeaID,
    isShowCanvasTools,
    setNames,
    setDescription,
    setIsPDFModal,
    setFromIdea,
    setIsLoading,
  } = useContext(UserContext);
  const [isHelp, setIsHelp] = useState(false);
  const [isDescriptionModal, setIsDescriptionModal] = useState(false);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);
  const [isSavedScene, setIsSavedScene] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);

  const getIdeasNames = async () => {
    try {
      // setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_MAIN_API}scene-data/names/${userId}`);
      const tempArray: any[] = [];
      res.data?.map((item: any) => {
        // setNames(prev => [...prev, item.name]);
        tempArray.push(item.name);
      });
      setNames(tempArray);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log('error: ', error);
    }
  };

  const getDescription = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_MAIN_API}description/get-data/${sceneId}`);
      setDescription(res.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getSessionIdeaDescription = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_MAIN_API}session_idea_description/description/${selectedSessionIdeaID}`
      );
      setDescription(res.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const openDescription = () => {
    if (memberType !== 'member' || (memberType === 'member' && membershipState > 3)) {
      setIsDescriptionModal(true);
    } else {
      setIsSubscribeModal(true);
    }
  };

  const openPDF = () => {
    if (memberType !== 'member' || (memberType === 'member' && membershipState > 3)) {
      if (sceneId < 0) {
        setIsSavedScene(true);
      } else {
        canvasRef.current?.generateScreenObject();
        setFromIdea(false);
        setIsPDFModal(true);
      }
    } else {
      setIsSubscribeModal(true);
    }
  };

  const openSubscribeModal = () => {
    setIsSavedScene(false);
    setIsSaveModal(true);
    canvasRef.current?.generateScreenObject();
  };

  useEffect(() => {
    if (userId !== -1) {
      getIdeasNames();
    }
  }, [userId]);

  useEffect(() => {
    if (sceneId > -1 && ideaType === 'idea') {
      getDescription();
    }
  }, [sceneId]);

  useEffect(() => {
    if (selectedSessionIdeaID > -1 && ideaType === 'sessionIdea') {
      getSessionIdeaDescription();
    }
  }, [selectedSessionIdeaID]);

  return (
    <div className={styles.wrapper}>
      {isShowCanvasTools && (
        <>
          <CameraIcon
            className={styles.icon}
            onClick={() => {
              canvasRef.current?.screenShotObject();
            }}
          />
          <PencilIcon className={classNames(styles.icon, styles.pencil)} onClick={openDescription} />
          <PDFIcon className={styles.icon} onClick={openPDF} />
        </>
      )}
      <HelpIcon
        className={styles.icon}
        onClick={() => {
          setIsHelp(true);
        }}
      />
      <HelpModal open={isHelp} setOpen={setIsHelp} />
      <SaveModal open={isSaveModal} setOpen={setIsSaveModal} names={names} getIdeasNames={getIdeasNames} />
      <DescriptionModal
        open={isDescriptionModal}
        setOpen={setIsDescriptionModal}
        getIdeasNames={getIdeasNames}
        canvasRef={canvasRef}
      />
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={"Please upgrade your subscription plan to 'Premium' or 'Pro' to unlock it."}
      />
      <NotificationModal
        open={isSavedScene}
        setOpen={setIsSavedScene}
        action={openSubscribeModal}
        actionButtonLabel="Save Idea"
        description="If you save the description, you should save the current ideas"
      />
    </div>
  );
};

export default ExtraTools;
