import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/dots-three-vertical.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy-simple.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-text.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/close.svg';

import MoveSessionCategory from '../MoveSessionCategory';
import SubscribeModal from '../SubscribeModal';
import SessionShareModal from '../SessionShareModal';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';
import { getWindowSize } from '../../../utils/getWindowSize';

type Props = {
  id: number;
  pageNum: number;
  searchSessions: any;
  setOpenSessionModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SessionAction: React.FC<Props> = ({ id, pageNum, searchSessions, setOpenSessionModal }) => {
  const { t } = useTranslation();

  const {
    memberType,
    sessionFolderType,
    selectedSessionCategory,
    userId,
    userInfo,
    sessionCategories,
    sessionCustomCategories,
    membershipState,
    sessions,
    setSessions,
    setIsLoading,
    setModifySessionId,
    setSelectedMenuTitle,
    setIdeaType,
    setNewSession,
  } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);
  const [isAction, setIsAction] = useState(0);
  const [pos, setPos] = useState('down');
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [mousePos, setMousePos] = useState<{
    x: number;
    y: number;
  }>({ x: 100, y: 100 });

  const onAction = (e: any) => {
    e.stopPropagation();
    setTimeout(() => {
      setIsAction(prev => prev + 1);
    }, 100);
  };

  useEffect(() => {
    if (isAction !== 0) {
      if (mousePos.y < windowSize.innerHeight - 250) {
        setPos('down');
      } else {
        setPos('up');
      }
      setOpen(prev => !prev);
    }
  }, [isAction]);

  const onClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
    setIsAction(0);
  };

  const onCopy = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const resSession = await axios.get(`${process.env.REACT_APP_MAIN_API}session/get_session/${id}`);

      const resSubSessions = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/sub_sessions`, {
        ids: JSON.parse(resSession.data.sub_data),
      });

      const subSessionIDArray = [];

      for await (const subSession of resSubSessions.data) {
        const ideaIDArray = JSON.parse(subSession.ideas);

        const resIdeasCount = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/copy_ideas`, {
          ids: ideaIDArray,
        });

        const resIdeas = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/lastRecords`, {
          count: resIdeasCount.data.count,
        });

        const resIdeasDescription = await axios.post(
          `${process.env.REACT_APP_MAIN_API}session_idea_description/copy_descriptions`,
          {
            ids: ideaIDArray,
            sids: resIdeas.data.data,
          }
        );

        const tempTime: { [key: string]: any } = {};
        const tempNumber: { [key: string]: any } = {};
        for (let j = 0; j < resIdeas.data.data.length; j++) {
          tempTime[`idea_${resIdeas.data.data[j]}`] = JSON.parse(subSession.execution_time)[
            Object.keys(JSON.parse(subSession.execution_time))[j]
          ];
          tempNumber[`idea_${resIdeas.data.data[j]}`] = JSON.parse(subSession.repeat_number)[
            Object.keys(JSON.parse(subSession.repeat_number))[j]
          ];
        }

        const resSub = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/create_sub_session`, {
          data: {
            title_EN: subSession.title_EN,
            title_FR: subSession.title_FR,
            title_DU: subSession.title_DU,
            title_GE: subSession.title_GE,
            text_EN: subSession.text_EN,
            text_FR: subSession.text_FR,
            text_DU: subSession.text_DU,
            text_GE: subSession.text_GE,
            ideas: JSON.stringify(resIdeas.data.data),
            execution_time: JSON.stringify(tempTime),
            repeat_number: JSON.stringify(tempNumber),
          },
        });

        subSessionIDArray.push(resSub.data.id);
      }

      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/create-session/-1`, {
        data: {
          uid: userId,
          author: userInfo.fname,
          title_EN: resSession.data.title_EN,
          title_FR: resSession.data.title_FR,
          title_DU: resSession.data.title_DU,
          title_GE: resSession.data.title_GE,
          text_EN: resSession.data.text_EN,
          text_FR: resSession.data.text_FR,
          text_DU: resSession.data.text_DU,
          text_GE: resSession.data.text_GE,
          category_EN: resSession.data.category_EN,
          category_FR: resSession.data.category_FR,
          category_DU: resSession.data.category_DU,
          category_GE: resSession.data.category_GE,
          custom_category_EN: resSession.data.custom_category_EN,
          custom_category_FR: resSession.data.custom_category_FR,
          custom_category_DU: resSession.data.custom_category_DU,
          custom_category_GE: resSession.data.custom_category_GE,
          sub_data: JSON.stringify(subSessionIDArray.sort((a, b) => a - b)),
          approval: false,
          isShared: false,
          rejected: false,
        },
      });

      searchSessions(selectedSessionCategory, pageNum);

      setIsLoading(false);
      onClose(e);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onModify = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);

      const selectedSession = await axios.get(`${process.env.REACT_APP_MAIN_API}session/get_session/${id}`);
      const tempSession: NewSessionType = {
        title_EN: selectedSession.data.title_EN,
        title_DU: selectedSession.data.title_DU,
        title_FR: selectedSession.data.title_FR,
        title_GE: selectedSession.data.title_GE,
        text_EN: selectedSession.data.text_EN,
        text_DU: selectedSession.data.text_DU,
        text_FR: selectedSession.data.text_FR,
        text_GE: selectedSession.data.text_GE,
        category_EN: selectedSession.data.category_EN,
        category_DU: selectedSession.data.category_DU,
        category_FR: selectedSession.data.category_FR,
        category_GE: selectedSession.data.category_GE,
        custom_category_EN: selectedSession.data.custom_category_EN,
        custom_category_DU: selectedSession.data.custom_category_DU,
        custom_category_FR: selectedSession.data.custom_category_FR,
        custom_category_GE: selectedSession.data.custom_category_GE,
        approval: selectedSession.data.approval,
        isShared: selectedSession.data.isShared,
        rejected: selectedSession.data.rejected,
        subSessions: [],
      };

      let idLen = 0;
      for await (const subID of JSON.parse(selectedSession.data.sub_data)) {
        const resSubSession = await axios.get(`${process.env.REACT_APP_MAIN_API}sub_session/get_sub_session/${subID}`);
        const repeatNumbers = JSON.parse(resSubSession.data.repeat_number);
        const executionTimes = JSON.parse(resSubSession.data.execution_time);
        tempSession.subSessions.push({
          id: idLen,
          session: -1,
          title_EN: resSubSession.data.title_EN,
          title_DU: resSubSession.data.title_DU,
          title_FR: resSubSession.data.title_FR,
          title_GE: resSubSession.data.title_GE,
          text_EN: resSubSession.data.text_EN,
          text_DU: resSubSession.data.text_DU,
          text_FR: resSubSession.data.text_FR,
          text_GE: resSubSession.data.text_GE,
          repeat_number: [],
          execution_time: [],
          ideas: [],
        });

        JSON.parse(resSubSession.data.ideas).map((item: number) => {
          tempSession.subSessions[idLen].repeat_number.push(repeatNumbers[`idea_${item}`]);
          tempSession.subSessions[idLen].execution_time.push(executionTimes[`idea_${item}`]);
        });

        const resIdeas = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/ideas`, {
          ids: JSON.parse(resSubSession.data.ideas),
        });
        if (resIdeas.data !== null) {
          resIdeas.data.map((item: any, index: number) => {
            tempSession.subSessions[idLen].ideas.push({
              id: index,
              idea: item.oid,
            });
          });
        }

        idLen += 1;
      }
      setNewSession(tempSession);
      setSelectedMenuTitle('Sessions');
      setIdeaType('sessionIdea');
      setModifySessionId(id);
      setIsLoading(false);
      onClose(e);
      setOpenSessionModal(false);
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(false);
    }
  };

  const onShare = async (e: any) => {
    e.stopPropagation();
    if (membershipState > 6) {
      setOpenShareModal(true);
    } else {
      setIsSubscribeModal(true);
    }
  };

  const onCopyID = (e: any) => {
    e.stopPropagation();
  };

  const onApprove = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/update-session/${id}`, {
        approval: true,
      });
      const tempArray: SessionType[] = [];
      sessions.map((item: SessionType) => {
        if (item.id !== id) {
          tempArray.push(item);
        }
      });
      setSessions(tempArray);
      setIsLoading(false);
      onClose(e);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onDelete = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/delete-session/${id}`);
      const tempArray: SessionType[] = [];
      sessions.map((item: SessionType) => {
        if (item.id !== id) {
          tempArray.push(item);
        }
      });
      setSessions(tempArray);
      setIsLoading(false);
      onClose(e);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    /** for get window size */
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    /** for get window size */

    /** for get mouse position */
    const handleMouseMove = (event: any) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    /** for get window size */

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <ThreeDotsIcon
            onClick={(e: any) => {
              onAction(e);
            }}
            className={classNames(styles.listIcon)}
          />
          {open && (
            <>
              <div
                className={classNames(styles.arrowDiv, {
                  [styles.up]: pos === 'up',
                })}
              />
              <div
                className={classNames(styles.arrowDiv, {
                  [styles.hidden]: pos === 'down',
                  [styles.upHidden]: pos === 'up',
                })}
              />
              <div
                className={styles.background}
                onClick={(e: any) => {
                  onClose(e);
                }}
              ></div>
              <div
                className={classNames(styles.mainField, {
                  [styles.up]: pos === 'up',
                })}
              >
                <button
                  className={classNames(styles.button)}
                  onClick={(e: any) => {
                    onCopy(e);
                  }}
                >
                  <CopyIcon className={styles.icon} />
                  {t('Copy')}
                </button>
                {(sessionFolderType === 'Folders' ||
                  (sessionFolderType === 'Shared Folders' &&
                    (memberType !== 'member' || (memberType === 'member' && membershipState > 6))) ||
                  (sessionFolderType === 'Shared by members' && memberType !== 'member')) && (
                  <button
                    className={classNames(styles.button)}
                    onClick={(e: any) => {
                      onModify(e);
                    }}
                  >
                    <EditIcon className={styles.icon} />
                    {t('Modify')}
                  </button>
                )}
                <CopyToClipboard text={id.toString()}>
                  <button className={classNames(styles.button)} onClick={onCopyID}>
                    <ShareIcon className={classNames(styles.icon)} />
                    {t('Copy ID')}
                  </button>
                </CopyToClipboard>
                {sessionFolderType === 'Folders' && (
                  <button
                    className={classNames(styles.button)}
                    onClick={(e: any) => {
                      onShare(e);
                    }}
                  >
                    <ShareIcon className={classNames(styles.icon)} />
                    {t('Share')}
                  </button>
                )}
                {(sessionFolderType === 'Folders' ||
                  (sessionFolderType === 'Shared Folders' && memberType !== 'member') ||
                  (sessionFolderType === 'Shared by members' && memberType !== 'member')) && (
                  <MoveSessionCategory
                    id={id}
                    categories={sessionFolderType === 'Folders' ? sessionCustomCategories : sessionCategories}
                    setCloseActionModal={setOpen}
                  />
                )}
                {sessionFolderType === 'Shared by members' && memberType !== 'member' && (
                  <>
                    <button className={styles.button} onClick={(e: any) => onApprove(e)}>
                      <CheckIcon fill={'green'} className={styles.icon} />
                      {t('Approve')}
                    </button>
                    <button
                      className={styles.button}
                      onClick={(e: any) => {
                        onDelete(e);
                      }}
                    >
                      <CrossIcon fill={'red'} className={styles.icon} />
                      {t('Dismiss')}
                    </button>
                  </>
                )}
                {(sessionFolderType === 'Folders' ||
                  (sessionFolderType === 'Shared Folders' && memberType !== 'member')) && (
                  <>
                    <hr className={styles.line} />
                    <button
                      className={classNames(styles.button, styles.delete)}
                      onClick={(e: any) => {
                        onDelete(e);
                      }}
                    >
                      {/* <DeleteIcon className={styles.icon} /> */}
                      {t('Delete')}
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <SessionShareModal open={openShareModal} setOpen={setOpenShareModal} id={id} />
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={`Please upgrade your subscription plan to 'Pro' to unlock it.`}
      />
    </div>
  );
};

export default SessionAction;
