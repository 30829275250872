import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '../Dropdown';
import Modal from '../Modal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  names: Array<string>;
  getIdeasNames: any;
  setIsImportModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

const SaveModal: React.FC<Props> = ({ open, setOpen, names, getIdeasNames, setIsImportModal }) => {
  const { t } = useTranslation();
  const {
    sceneId,
    saveIdeaName,
    sceneCharacters,
    sceneSportsObjects,
    characterGroup,
    objectGroup,
    userId,
    sportsFieldObject,
    isField,
    characterMaterials,
    characterRotationInfo,
    sportsObjectRotationInfo,
    customCategories,
    currentImageBlob,
    currentImage,
    customImageBlob,
    customVideo,
    customVideoBlob,
    selectedLang,
    description,
    descriptionTitle,
    editorState,
    customImage,
    selectedMenuTitle,
    setDescriptionTitle,
    setDescription,
    setSceneId,
    setIsSavedState,
    setCurrentImage,
    setIsCustomImage,
    setIsLoading,
    setIsModify,
    setKeyWord,
    setOpenFolderModal,
    setOpenSessionsModal,
    setIsResetIdeas,
    setIsFileUploading,
  } = useContext(UserContext);
  const [isValid, setIsValid] = useState(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  // const [tempImage, setTempImage] = useState<any>('');
  // const [isUploadLocalImage, setIsUploadLocalImage] = useState(false);

  // const imageUpload = (file: any) => {
  //   const fr = new FileReader();
  //   fr.readAsArrayBuffer(file);
  //   fr.onload = function () {
  //     if (fr.result !== null) {
  //       const blob = new Blob([fr.result]);
  //       setTempImage(blob);
  //     }
  //   };
  // };

  const saveIdeas = async () => {
    const characterString = JSON.stringify(sceneCharacters);
    const objectString = JSON.stringify(sceneSportsObjects);
    const originalCharacterLength = characterRotationInfo.length;
    const originalSportsObjectLength = sportsObjectRotationInfo.length;

    const characterTransformInfo: any[] = [];
    const objectTransformInfo: any[] = [];

    characterGroup?.current?.children.map((item: any, index: number) => {
      for (let i = 0; i < item.children.length; i++) {
        if (item.children[i].type === 'Group') {
          characterTransformInfo.push({
            position: [item?.children[i]?.position.x, item?.children[i]?.position.y, item?.children[i]?.position.z],
            scale: [item?.children[i]?.scale.x, item?.children[i]?.scale.y, item?.children[i]?.scale.z],
            rotation:
              item?.children[i]?.rotation._x === 0 &&
              item?.children[i]?.rotation._y === 0 &&
              item?.children[i]?.rotation._z === 0 &&
              index < originalCharacterLength
                ? characterRotationInfo[index]
                : [item?.children[i]?.rotation._x, item?.children[i]?.rotation._y, item?.children[i]?.rotation._z],
          });
          break;
        }
      }
    });

    objectGroup?.current?.children.map((item: any, index: number) => {
      for (let i = 0; i < item.children.length; i++) {
        if (item.children[i].type === 'Group') {
          objectTransformInfo.push({
            position: [item?.children[i]?.position.x, item?.children[i]?.position.y, item?.children[i]?.position.z],
            scale: [item?.children[i]?.scale.x, item?.children[i]?.scale.y, item?.children[i]?.scale.z],
            rotation:
              item?.children[i]?.rotation._x === 0 &&
              item?.children[i]?.rotation._y === 0 &&
              item?.children[i]?.rotation._z === 0 &&
              index < originalSportsObjectLength
                ? sportsObjectRotationInfo[index]
                : [item?.children[i]?.rotation._x, item?.children[i]?.rotation._y, item?.children[i]?.rotation._z],
          });
          break;
        }
      }
    });

    if (descriptionTitle === '') {
      setIsValid(true);
    } else {
      try {
        setIsLoading(true);
        const customImageFileName = `id-sport_${userId}_${category}_${descriptionTitle}_${Date.now()}_custom_image.jpg`;
        const currentImageFileName = `id-sport_${userId}_${category}_${descriptionTitle}_${Date.now()}_current_image.jpg`;
        const customVideoFileName = `id-sport_${userId}_${category}_${descriptionTitle}_${Date.now()}_custom_video.mp4`;

        const customFormData = new FormData();
        customFormData.append('file', customImageBlob);

        const currentFormData = new FormData();
        currentFormData.append('file', currentImageBlob);

        const customVideoFormData = new FormData();
        customVideoFormData.append('file', customVideoBlob);

        if (currentImageBlob !== '' || customImageBlob !== '' || customVideoBlob !== '') {
          setIsFileUploading(true);
        }

        let currentUploadedImage: any = '';
        if (currentImageBlob !== '') {
          currentUploadedImage = await axios.post(
            `${process.env.REACT_APP_MAIN_API}scene-data/upload-image/images/${currentImageFileName}`,
            currentFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }

        let customUploadedImage: any = '';
        if (customImageBlob !== '') {
          customUploadedImage = await axios.post(
            `${process.env.REACT_APP_MAIN_API}scene-data/upload-image/images/${customImageFileName}`,
            customFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }

        let customUploadedVideo: any = '';
        if (customVideoBlob !== '') {
          customUploadedVideo = await axios.post(
            `${process.env.REACT_APP_MAIN_API}scene-data/upload-image/video/${customVideoFileName}`,
            customVideoFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }

        setIsFileUploading(false);

        const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/save/${userId}`, {
          name: descriptionTitle,
          character: characterString,
          character_transform: JSON.stringify(characterTransformInfo),
          character_material: JSON.stringify(characterMaterials),
          object: objectString,
          object_transform: JSON.stringify(objectTransformInfo),
          field: JSON.stringify({ isField, sportsFieldObject }),
          custom_category: category === '' ? 'My exercises' : category,
          category: '',
          approval: false,
          // isShared: false,
          rejected: false,
          image: customImageBlob === '' ? customImage : customUploadedImage.data.file,
          custom_image: currentImageBlob === '' ? currentImage : currentUploadedImage.data.file,
          custom_video: customVideoBlob === '' ? customVideo : customUploadedVideo.data.file,
          isCustomImage: false,
          // image: isUploadLocalImage ? uploadedImage.data.file : isCustomImage ? currentImage : uploadedImage.data.file,
          // isCustomImage: isCustomImage ? isCustomImage : isUploadLocalImage,
        });

        const resDescription = await axios.post(`${process.env.REACT_APP_MAIN_API}description/save/${res.data.id}`, {
          title_EN: selectedLang === 'EN' ? descriptionTitle : description?.title_EN ?? '',
          title_FR: selectedLang === 'FR' ? descriptionTitle : description?.title_FR ?? '',
          title_DU: selectedLang === 'DU' ? descriptionTitle : description?.title_DU ?? '',
          title_GE: selectedLang === 'GE' ? descriptionTitle : description?.title_GE ?? '',
          EN: selectedLang === 'EN' ? editorState : description?.EN ?? '',
          FR: selectedLang === 'FR' ? editorState : description?.FR ?? '',
          DU: selectedLang === 'DU' ? editorState : description?.DU ?? '',
          GE: selectedLang === 'GE' ? editorState : description?.GE ?? '',
        });
        setDescription(resDescription.data);

        setSceneId(res.data.id);
        setCurrentImage(res.data.image);
        setIsCustomImage(res.data.isCustomImage);
        setIsSavedState(false);
        setIsLoading(false);
        setOpen(false);
        getIdeasNames();

        setIsModify(false);
        if (selectedMenuTitle !== 'IDEAS') {
          setKeyWord('');
        }
        setIsResetIdeas(true);
        setOpenFolderModal(true);
        setOpenSessionsModal(false);
      } catch (error) {
        setIsLoading(false);
        console.log('error: ', error);
      }
    }
  };

  useEffect(() => {
    if (sceneId === -1) {
      // setName('');
      // setDescriptionTitle('');
    }
    setIsValid(false);
    // setIsUploadLocalImage(false);
    // setTempImage('');
  }, [open]);

  useEffect(() => {
    setCategory(customCategories[0] ?? '');
  }, [customCategories]);

  useEffect(() => {
    if (sceneId !== -1) {
      // setName(saveIdeaName);
      setDescriptionTitle(saveIdeaName);
    }
  }, [saveIdeaName, sceneId]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.saveModal}
      actionButtonLabel={t('Save')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={saveIdeas}
      closeButtonColor="Black"
    >
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t('Save Idea')}</p>
      </div>
      <div className={styles.savedWrapper}>
        <p className={styles.title}>{t('Saved Ideas')}</p>
        <div className={styles.savedField}>
          {names.length === 0 ? (
            <p className={styles.button}>{t('No Ideas')}</p>
          ) : (
            names?.map((item, index: number) => (
              <button
                key={index}
                className={styles.button}
                onClick={() => {
                  // setName(item);
                  setDescriptionTitle(item);
                }}
              >
                {item}
              </button>
            ))
          )}
        </div>
      </div>
      <div className={styles.categoryWrapper}>
        <span className={styles.title}>{t('Select Category')}</span>
        <Dropdown
          className={styles.category}
          selectedItem={category}
          setSelectedItem={setCategory}
          items={customCategories}
        />
      </div>
      <div className={styles.inputWrapper}>
        {isValid && <p className={styles.validText}>{t('Enter the name')}</p>}
        <input
          className={styles.input}
          placeholder={t('Enter the ideas name')}
          value={descriptionTitle}
          onChange={e => {
            // setName(e.target.value);
            setDescriptionTitle(e.target.value);
          }}
        />
      </div>
      {/* <div className={styles.fileUpload}>
        <span className={styles.fileLabel}>{t('Change the image')}</span>
        <input
          type="file"
          id="file"
          name="file"
          onChange={e => {
            if (e.target.files !== null) {
              imageUpload(e.target.files[0]);
            }
            console.log(e);
            setIsUploadLocalImage(true);
          }}
        />
      </div> */}
    </Modal>
  );
};

export default SaveModal;
