import React, { useEffect, useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { PDFExport } from '@progress/kendo-react-pdf';

import loadingIcon from '../../assets/icons/loading1.gif';
import logoImage from '../../assets/logo-id-sport.png';

import './index.css';

const isBrowser = typeof window !== 'undefined';

const PDFPage = () => {
  const { t } = useTranslation();
  const pdfExportComponent = useRef<any>(null);
  const selectedImage = localStorage.getItem('selectedImage');
  console.log('selectedImage: ', selectedImage);
  const screenshot = localStorage.getItem('screenshot');
  const currentImage = localStorage.getItem('currentImage');
  const customImage = localStorage.getItem('customImage');
  const descriptionTitle = localStorage.getItem('descriptionTitle');
  const descriptionTemp = localStorage.getItem('description');

  let description = null;
  if (descriptionTemp !== null) {
    const replaceString = descriptionTemp.replaceAll('px', 'pt').replaceAll('<li>', `<li>● ${'&nbsp;'}`);
    description = JSON.parse(replaceString);
    console.log('description: ', description);
  }
  const selectedLang = localStorage.getItem('selectedLang');

  const [mainImage, setMainImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const generatePDF = () => {
    const element = document.querySelector('#report') as HTMLElement;
    const option = {
      html2canvas: { useCORS: true },
      image: { quality: 1 },
      filename: `id-sport.pdf`,
      margin: [20, 20, 20, 20],
      jsPDF: { unit: 'px', format: 'a3', orientation: 'portrait', precision: 2, hotfixes: ['px_scaling'] },
      loading: 'lazy',
    };

    if (isBrowser) {
      html2pdf().from(element).set(option).save();

      // html2pdf(element, {
      //   html2canvas: { useCORS: true, scale: 1 },
      //   filename: `id-sport.pdf`,
      //   margin: 6,
      //   jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
      //   loading: 'lazy',
      // });
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      let imageUrl = '';
      if (selectedImage === '3D') {
        if (screenshot !== '' && screenshot !== null) {
          imageUrl = screenshot;
        } else if (currentImage !== '' && currentImage !== null) {
          imageUrl = currentImage;
        }
      } else if (selectedImage === 'image') {
        if (customImage !== '' && customImage !== null) {
          imageUrl = customImage;
        }
      } else {
        if (screenshot !== '' && screenshot !== null) {
          imageUrl = screenshot;
        } else if (currentImage !== '' && currentImage !== null) {
          imageUrl = currentImage;
        } else if (customImage !== '' && customImage !== null) {
          imageUrl = customImage;
        }
      }
      console.log('imageUrl: ', imageUrl);

      if (imageUrl !== '') {
        try {
          setIsLoading(true);
          const res = await axios.get(`${process.env.REACT_APP_MAIN_API}scene-data/fetch-image?url=${imageUrl}`);
          console.log('res: ', res?.data?.result);

          const bufferData = await res?.data?.result?.data;
          const uint8Array = new Uint8Array(bufferData);
          const blob = new Blob([uint8Array], { type: 'image/jpg' });
          setMainImage(URL.createObjectURL(blob));
          setIsLoading(false);
        } catch (error) {
          console.log('error: ', error);
          setIsLoading(false);
          setMainImage('');
        }

        // fetch(imageUrl)
        //   .then(response => response.blob())
        //   .then(blob => {
        //     const url = URL.createObjectURL(blob);
        //     setMainImage(url);
        //   });
      } else {
        setMainImage('');
      }
    };

    fetchImage();
  }, [screenshot, currentImage, customImage, selectedImage]);

  return (
    <div className="pdfWrapper">
      <div className="pdfContentWrapper">
        <button
          className="pdfDownload"
          onClick={() => {
            // if (pdfExportComponent.current) {
            //   pdfExportComponent.current?.save();
            // }
            generatePDF();
          }}
        >
          {t('Download')}
        </button>
        {/* <PDFExport margin={5} imageResolution={72} paperSize="A3" ref={pdfExportComponent}> */}
        <div className="pdfMainWrapper" id="report">
          <div className="pdfMainContentWrapper">
            <div className="pdfMainContent">
              {/* <div className="pdfLogoWrapper"> */}
              <img src={logoImage} alt="logo" className="pdfLogo" />
              {/* </div> */}
              <p className="pdfTitle" style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold' }}>
                {descriptionTitle}
              </p>
              <div className="pdfImageWrapper">
                {isLoading ? (
                  <div className="pdfLoadingWrapper">
                    <img src={loadingIcon} className="pdfLoadingIcon" />
                  </div>
                ) : mainImage !== '' && mainImage !== null ? (
                  <img crossOrigin="anonymous" src={mainImage} alt="" className="pdfMainImage" />
                ) : null}
              </div>
              {description && (
                <span
                  className="pdfDescription"
                  dangerouslySetInnerHTML={{ __html: description[selectedLang === null ? 'EN' : selectedLang] }}
                ></span>
              )}
            </div>
          </div>
        </div>
        {/* </PDFExport> */}
      </div>
    </div>
  );
};

export default PDFPage;
