import axios from 'axios';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FolderIcon } from '../../../assets/icons/folder-close.svg';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';
import { getWindowSize } from '../../../utils/getWindowSize';

type Props = {
  className?: string;
  categories: SessionCategoryType;
  setCloseActionModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
};

const MoveSessionCategory: React.FC<Props> = ({ className, categories, id, setCloseActionModal }) => {
  const { t } = useTranslation();
  const { sessions, sessionFolderType, selectedLang, setSessions, setIsLoading } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [isAction, setIsAction] = useState(0);
  const [pos, setPos] = useState('down');
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [mousePos, setMousePos] = useState<{
    x: number;
    y: number;
  }>({ x: 100, y: 100 });

  const onAction = (e: any) => {
    e.stopPropagation();
    setTimeout(() => {
      setIsAction(prev => prev + 1);
    }, 100);
  };

  useEffect(() => {
    if (isAction !== 0) {
      if (mousePos.y < windowSize.innerHeight - 200) {
        setPos('down');
      } else {
        setPos('up');
      }
      setOpen(prev => !prev);
    }
  }, [isAction]);

  const onClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
    setIsAction(0);
  };

  const onMoveTo = async (e: any, index: number) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      if (sessionFolderType === 'Folders') {
        const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/update-session/${id}`, {
          custom_category_EN: categories.EN[index],
          custom_category_DU: categories.DU[index],
          custom_category_FR: categories.FR[index],
          custom_category_GE: categories.GE[index],
        });
      } else {
        const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/update-session/${id}`, {
          category_EN: categories.EN[index],
          category_DU: categories.DU[index],
          category_FR: categories.FR[index],
          category_GE: categories.GE[index],
        });
      }
      const tempArray: SessionType[] = [];
      sessions.map((item: SessionType) => {
        if (item.id !== id) {
          tempArray.push(item);
        } else if (item[`category_${selectedLang}`] === categories[selectedLang][index]) {
          tempArray.push(item);
        }
      });
      setSessions(tempArray);
      setOpen(false);
      setCloseActionModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOpen(false);
      setCloseActionModal(false);
      console.error(error);
    }
  };

  useEffect(() => {
    /** for get window size */
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    /** for get window size */

    /** for get mouse position */
    const handleMouseMove = (event: any) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    /** for get window size */

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <button
            className={styles.moveButton}
            onClick={(e: any) => {
              onAction(e);
            }}
          >
            <FolderIcon className={styles.listIcon} />
            <span>{t('Move to')}</span>
          </button>
          {open && (
            <>
              <div
                className={classNames(styles.arrowDiv, {
                  [styles.up]: pos === 'up',
                })}
              />
              <div
                className={classNames(styles.arrowDiv, {
                  [styles.hidden]: pos === 'down',
                  [styles.upHidden]: pos === 'up',
                })}
              />
              <div
                className={styles.background}
                onClick={(e: any) => {
                  onClose(e);
                }}
              ></div>
              <div
                className={classNames(styles.mainField, {
                  [styles.up]: pos === 'up',
                })}
              >
                {categories[`${selectedLang}`].map((item, index: number) => (
                  <button
                    key={index}
                    className={styles.button}
                    onClick={(e: any) => {
                      onMoveTo(e, index);
                    }}
                  >
                    <FolderIcon className={styles.icon} />
                    {t(item)}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoveSessionCategory;
