import axios from 'axios';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FolderIcon } from '../../../assets/icons/folder-close.svg';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  categories: string[];
  setCloseActionModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
};

const MoveCategories: React.FC<Props> = ({ className, categories, id, setCloseActionModal }) => {
  const { t } = useTranslation();
  const { folderIdeas, folderType, setFolderIdeas, setIsLoading } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const onAction = (e: any) => {
    e.stopPropagation();
    setOpen(prev => !prev);
  };

  const onClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onMoveTo = async (e: any, category: string) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/move-idea`, {
        id: id,
        category: category,
        type: folderType === 'Folders' ? 'custom_category' : 'category',
      });
      const tempArray: FolderIdeaType[] = [];
      folderIdeas.map((item: FolderIdeaType) => {
        if (item.id !== id) {
          tempArray.push(item);
        }
      });
      setFolderIdeas(tempArray);
      setOpen(false);
      setCloseActionModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOpen(false);
      setCloseActionModal(false);
      console.error(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <button
            className={styles.moveButton}
            onClick={(e: any) => {
              onAction(e);
            }}
          >
            <FolderIcon className={styles.listIcon} />
            <span>{t('Move to')}</span>
          </button>
          {open && (
            <>
              <div className={styles.arrowDiv} />
              <div className={classNames(styles.arrowDiv, styles.hidden)} />
              <div
                className={styles.background}
                onClick={(e: any) => {
                  onClose(e);
                }}
              ></div>
              <div className={styles.mainField}>
                {categories.map((item, index: number) => (
                  <button
                    key={index}
                    className={styles.button}
                    onClick={(e: any) => {
                      onMoveTo(e, item);
                    }}
                  >
                    <FolderIcon className={styles.icon} />
                    {t(item)}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoveCategories;
