import React, { forwardRef, Suspense, useContext, useImperativeHandle, useRef, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { useContextBridge, OrbitControls } from '@react-three/drei';
import { useTranslation } from 'react-i18next';
import { ACESFilmicToneMapping, sRGBEncoding } from 'three';
import classNames from 'classnames';

import { UserContext } from '../../../providers/UserProvider';
import Character from '../Character';
import CharacterInfo from '../../_ui/CharacterInfo';
import Tools from '../../_ui/Tools';
import SportsObject from '../SportsObject';
import SportsObjectInfo from '../../_ui/SportsObjectInfo/indes';
import Colors from '../../_ui/Colors';
import SportsField from '../SportsField';
import CharacterMaterial from '../../_ui/CharaterMaterial';
import SubSportsField from '../../_ui/SubSportsField';
import ExtraTools from '../../_ui/ExtraTools';
import NotificationModal from '../../_ui/NotificationModal';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock-black.svg';
import { ReactComponent as UnlockIcon } from '../../../assets/icons/unlock.svg';

import styles from './styles.module.scss';

const Models = ({ orbit }: { orbit: any }) => {
  const { sceneSportsObjects, sceneCharacters, characterGroup, objectGroup, isModify } = useContext(UserContext);

  return (
    <>
      <group ref={characterGroup} name="characterGroup">
        {isModify &&
          sceneCharacters.map((object, index: number) => {
            if (object !== undefined) {
              return <Character key={index} orbit={orbit} object={object} id={index} />;
            }
          })}
      </group>
      <group ref={objectGroup} name="objectGroup">
        {isModify &&
          sceneSportsObjects.map((object, index: number) => {
            return <SportsObject key={index} id={index} orbit={orbit} object={object} />;
          })}
      </group>
    </>
  );
};

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type MainContentProps = {
  name?: string;
};

const MainContent = forwardRef<ExportObject, MainContentProps>(({ name = '' }, ref) => {
  const {
    isField,
    isModify,
    sportsFieldObject,
    sceneCharacters,
    sceneSportsObjects,
    setScreenshot,
    setCurrentImageBlob,
  } = useContext(UserContext);
  const orbit = useRef<any>();
  const { gl, scene, camera } = useThree();

  const screenShot = () => {
    gl.render(scene, camera);
    gl.toneMapping = ACESFilmicToneMapping;
    gl.toneMappingExposure = 0.6;
    gl.outputEncoding = sRGBEncoding;
    gl.domElement.toBlob(
      function (blob: any) {
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = 'canvas.jpg';
        a.click();
      },
      'image/jpg',
      1.0
    );
  };

  const generateScreen = () => {
    gl.render(scene, camera);
    gl.toneMapping = ACESFilmicToneMapping;
    gl.toneMappingExposure = 0.6;
    gl.outputEncoding = sRGBEncoding;
    gl.domElement.toBlob(
      function (blob: any) {
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        if (
          sceneCharacters.length === 0 &&
          sceneSportsObjects.length === 0 &&
          (!isField || (isField && sportsFieldObject.object === ''))
        ) {
          setScreenshot('');
          setCurrentImageBlob('');
        } else {
          setScreenshot(url);
          setCurrentImageBlob(blob);
        }
        a.download = 'canvas.jpg';
        a.click();
      },
      'image/jpg',
      1.0
    );
  };

  useImperativeHandle(ref, () => ({
    screenShotObject() {
      screenShot();
    },
    generateScreenObject() {
      generateScreen();
    },
  }));

  return (
    <>
      <OrbitControls
        ref={orbit}
        minDistance={2}
        target={[0, 1, 0]}
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
        enableDamping={false}
      />
      <group>
        {isField && isModify && <SportsField object={sportsFieldObject} />}
        <Models orbit={orbit} />
      </group>
    </>
  );
});

type ConfiguratorProps = {
  canvasRef: React.RefObject<ExportObject>;
};

const Configurator: React.FC<ConfiguratorProps> = ({ canvasRef }) => {
  const ContextBridge = useContextBridge(UserContext);
  const {
    isTab,
    isObjectColor,
    isObjectColorTwo,
    isObjectColorThree,
    selectedSportsObjectIndex,
    characterMaterials,
    selectedCharacterIndex,
    isShowCanvasTools,
    memberType,
    setIsField,
    setIsCustomImage,
    setIsSavedState,
    setIsShowCanvasTools,
  } = useContext(UserContext);
  const { t } = useTranslation();

  const [lockNotificationModal, setLockNotificationModal] = useState(false);

  const onClickUnlock = () => {
    if (memberType !== 'member') {
      setIsShowCanvasTools(true);
    } else {
      setLockNotificationModal(true);
    }
  };

  const gotoContactUSPage = () => {
    window.open('https://www.id-sport.com/dashboard/', '_blank ');
  };

  return (
    <div
      className={classNames(styles.canvasWrapper, {
        [styles.increase]: !isTab,
      })}
    >
      <Canvas
        camera={{
          fov: 25,
          far: 20000,
          near: 0.01,
          position: [0, 10, 30],
        }}
        // style={{ height: isTab ? '100%' : '100%' }}
        // style={{ height: 'auto' }}
        className={styles.canvas}
      >
        <Suspense fallback={null}>
          <ambientLight intensity={0.2} color={0xffffff} />
          <hemisphereLight intensity={0.2} color={0xffffff} />
          <pointLight position={[0, -1500, 0]} intensity={0.1} />
          <pointLight position={[0, 1500, 0]} intensity={0.1} />
          <pointLight position={[300, 0, 0]} intensity={0.9} />
          <pointLight position={[-300, 0, 0]} intensity={0.9} />
          <pointLight position={[0, 0, 100]} intensity={0.3} />
          <pointLight position={[0, 0, -100]} intensity={0.5} />
          <directionalLight color={0xffffff} intensity={0.5} castShadow={true} position={[0, 100, 0]} />
          <ContextBridge>
            <MainContent ref={canvasRef} />
          </ContextBridge>
        </Suspense>
      </Canvas>
      <CharacterInfo />
      <SportsObjectInfo />
      <div className={styles.extraTools}>
        {isShowCanvasTools ? (
          <LockIcon className={styles.lockIcon} onClick={() => setIsShowCanvasTools(false)} />
        ) : (
          <UnlockIcon className={styles.lockIcon} onClick={onClickUnlock} />
        )}
        <ExtraTools canvasRef={canvasRef} />
      </div>
      <SubSportsField />
      {isShowCanvasTools && (
        <>
          <Tools />
          <div className={styles.bottomSetting}>
            <button
              onClick={() => {
                setIsField(false);
                setIsCustomImage(false);
                setIsSavedState(true);
              }}
              className={styles.deleteFloor}
            >
              {t('Delete Floor')}
              <DeleteIcon className={styles.img} />
            </button>
            <div className={styles.color}>
              {isObjectColor && selectedSportsObjectIndex !== -1 && <Colors object="one" />}
              {isObjectColorTwo && selectedSportsObjectIndex !== -1 && <Colors object="two" />}
              {isObjectColorThree && selectedSportsObjectIndex !== -1 && <Colors object="three" />}
              <CharacterMaterial materials={characterMaterials[selectedCharacterIndex]} />
            </div>
          </div>
        </>
      )}
      <NotificationModal
        open={lockNotificationModal}
        setOpen={setLockNotificationModal}
        actionButtonLabel="Contact US"
        action={gotoContactUSPage}
        description="Only for Editor - Want to be an editor, please contact us"
      />
    </div>
  );
};

export default Configurator;
