/* eslint-disable prettier/prettier */
import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import SubscribeModal from '../SubscribeModal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

const colors = [
  { name: 0, value: '0xe6194b', demo: true },
  { name: 1, value: '0xf58231', demo: true },
  { name: 2, value: '0xffe119', demo: true },
  { name: 3, value: '0xbfef45', demo: true },
  { name: 4, value: '0x3cb44b' },
  { name: 5, value: '0x42d4f4' },
  { name: 6, value: '0x0344a4' },
  { name: 7, value: '0x911eb4' },
  { name: 8, value: '0xf032e6' },
  { name: 9, value: '0x9a6324' },
  { name: 10, value: '0x000000' },
  { name: 11, value: '0xadadad' },
  { name: 12, value: '0xffffff' },
];

type Props = {
  object: string;
}

const Colors: React.FC<Props> = ({ object }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isMemberModal, setIsMemberModal] = useState(false);
  const { membershipState, changeSportsObjectDetails, selectedSportsObjectIndex, memberType } =
    useContext(UserContext);
    
  const changeColor = (item: { name: number; value: string }) => {
    if (object === 'one') {
      changeSportsObjectDetails(selectedSportsObjectIndex, { color: item.value });
    } else if (object === 'two') {
      changeSportsObjectDetails(selectedSportsObjectIndex, { colorTwo: item.value });
    } else if (object === 'three') {
      changeSportsObjectDetails(selectedSportsObjectIndex, { colorThree: item.value });
    }
    setSelectedIndex(item.name);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.group}>
        {colors.map((item, index) => (
          <div
            key={index}
            className={classNames(styles.buttonWrapper, {
              [`bg-gray-light border-color-0`]: selectedIndex === item.name,
            })}
            style={{
              borderColor: selectedIndex === index ? '#' + item.value.substring(2, item.value.length) : '#fff',
              backgroundColor:
                !item?.demo && membershipState === 0 && memberType === 'member'
                  ? '#999999'
                  : selectedIndex === index
                    ? '#E5E7EB'
                    : '#' + item.value.substring(2, item.value.length),
            }}
            onClick={() => {
              if (!item?.demo && membershipState === 0 && memberType === 'member') {
                setIsMemberModal(true);
              } else {
                changeColor(item);
              }
            }}
          >
            <div
              className={classNames(styles.button)}
              style={{
                backgroundColor: !item?.demo && membershipState === 0 && memberType === 'member'
                  ? '#999999'
                  : '#' + item.value.substring(2, item.value.length)
              }}
            ></div>
          </div>
        ))}
      </div>
      <SubscribeModal
        open={isMemberModal}
        setOpen={setIsMemberModal}
        description={'Please upgrade your subscription plan to unlock it.'}
      />
    </div>
  );
};

export default Colors;
