import React, { Fragment, useContext, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as GEFlagIcon } from '../../../assets/icons/de.svg';
import { ReactComponent as FRFlagIcon } from '../../../assets/icons/fr.svg';
import { ReactComponent as GBFlagIcon } from '../../../assets/icons/gb.svg';
import { ReactComponent as DUFlagIcon } from '../../../assets/icons/nl.svg';

import styles from './styles.module.scss';

const language = [
  { name: 'EN', flag: GBFlagIcon },
  { name: 'FR', flag: FRFlagIcon },
  { name: 'DU', flag: DUFlagIcon },
  { name: 'GE', flag: GEFlagIcon },
];

const LangDropdown: React.FC = () => {
  const { selectedLang, setSelectedLang } = useContext(UserContext);

  const currentLanguage = useMemo(
    () => language.find(item => item.name === selectedLang) ?? language[0],
    [selectedLang]
  );

  return (
    <Listbox
      value={currentLanguage}
      onChange={value =>
        setSelectedLang(
          value.name !== 'EN' && value.name !== 'DU' && value.name !== 'FR' && value.name !== 'GE' ? 'EN' : value.name
        )
      }
    >
      <div className={styles.wrapper}>
        <Listbox.Button className={styles.selected}>
          <span className={styles.text}>{selectedLang}</span>
          <currentLanguage.flag className={styles.flag}></currentLanguage.flag>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className={styles.listWrapper}>
            {language.map((item, itemIdx) => (
              <Listbox.Option
                key={itemIdx}
                className={({ active }) =>
                  classNames(styles.item, {
                    [styles.active]: active,
                  })
                }
                value={item}
              >
                <span className={styles.text}>{item.name}</span>
                <item.flag className={styles.flag}></item.flag>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default LangDropdown;
