import React from 'react';

import items from '../../../json/sportsField.json';
import Carousel from '../../_ui/Carousel';

import styles from './styles.module.scss';

const SportFieldTap: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      {/* <p className={styles.title}>{t(tabButtonLists[currentTabIndex].label)}</p>
      <hr className={styles.line} /> */}
      <Carousel items={items}></Carousel>
      {/* {firstLevel && (
        <>
          {firstLevel.length > 0 && <hr className={styles.line} />}
          <Carousel items={firstLevel}></Carousel>
        </>
      )} */}
      <hr className={styles.line} />
    </div>
  );
};

export default SportFieldTap;
