import React, { useContext } from 'react';
import classNames from 'classnames';

import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as CloseFolder } from '../../../assets/icons/folder-close.svg';
import { ReactComponent as OpenFolder } from '../../../assets/icons/folder-open.svg';

import styles from './styles.module.scss';

type Props = {
  label: string;
  getIdeas: () => void;
  title: string;
};

const Category: React.FC<Props> = ({ label, getIdeas, title }) => {
  const { selectedCategory, folderType } = useContext(UserContext);
  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        getIdeas();
      }}
    >
      {selectedCategory === label && title === folderType ? (
        <OpenFolder className={styles.icon} />
      ) : (
        <CloseFolder className={styles.icon} />
      )}
      <p
        className={classNames(styles.label, {
          [styles.selected]: selectedCategory === label && title === folderType,
        })}
      >
        {label}
      </p>
    </div>
  );
};

export default Category;
