import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '../Dropdown';
import Modal from '../Modal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
};

const SessionShareModal: React.FC<Props> = ({ open, setOpen, id }) => {
  const { t } = useTranslation();
  const { sessionCategories, selectedLang, setIsLoading } = useContext(UserContext);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [category, setCategory] = useState<string>(sessionCategories[selectedLang][0]);

  const shareSession = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const resSession = await axios.get(`${process.env.REACT_APP_MAIN_API}session/get_session/${id}`);

      const resSubSessions = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/sub_sessions`, {
        ids: JSON.parse(resSession.data.sub_data),
      });

      const subSessionIDArray = [];

      console.log('resSubSessions.data: ', resSubSessions.data);
      for await (const subSession of resSubSessions.data) {
        const ideaIDArray = JSON.parse(subSession.ideas);

        const resIdeasCount = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/copy_ideas`, {
          ids: ideaIDArray,
        });

        const resIdeas = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/lastRecords`, {
          count: resIdeasCount.data.count,
        });

        const resIdeasDescription = await axios.post(
          `${process.env.REACT_APP_MAIN_API}session_idea_description/copy_descriptions`,
          {
            ids: ideaIDArray,
            sids: resIdeas.data.data,
          }
        );

        const tempTime: { [key: string]: any } = {};
        const tempNumber: { [key: string]: any } = {};
        for (let j = 0; j < resIdeas.data.data.length; j++) {
          tempTime[`idea_${resIdeas.data.data[j]}`] = JSON.parse(subSession.execution_time)[
            Object.keys(JSON.parse(subSession.execution_time))[j]
          ];
          tempNumber[`idea_${resIdeas.data.data[j]}`] = JSON.parse(subSession.repeat_number)[
            Object.keys(JSON.parse(subSession.repeat_number))[j]
          ];
        }

        const resSub = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/create_sub_session`, {
          data: {
            title_EN: subSession.title_EN,
            title_FR: subSession.title_FR,
            title_DU: subSession.title_DU,
            title_GE: subSession.title_GE,
            text_EN: subSession.text_EN,
            text_FR: subSession.text_FR,
            text_DU: subSession.text_DU,
            text_GE: subSession.text_GE,
            ideas: JSON.stringify(resIdeas.data.data),
            execution_time: JSON.stringify(tempTime),
            repeat_number: JSON.stringify(tempNumber),
          },
        });

        subSessionIDArray.push(resSub.data.id);
      }

      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/create-session/-1`, {
        data: {
          uid: resSession.data.uid,
          author: resSession.data.author,
          title_EN: resSession.data.title_EN,
          title_FR: resSession.data.title_FR,
          title_DU: resSession.data.title_DU,
          title_GE: resSession.data.title_GE,
          text_EN: resSession.data.text_EN,
          text_FR: resSession.data.text_FR,
          text_DU: resSession.data.text_DU,
          text_GE: resSession.data.text_GE,
          category_EN: sessionCategories.EN[categoryIndex],
          category_FR: sessionCategories.FR[categoryIndex],
          category_DU: sessionCategories.DU[categoryIndex],
          category_GE: sessionCategories.GE[categoryIndex],
          custom_category_EN: resSession.data.custom_category_EN,
          custom_category_FR: resSession.data.custom_category_FR,
          custom_category_DU: resSession.data.custom_category_DU,
          custom_category_GE: resSession.data.custom_category_GE,
          sub_data: JSON.stringify(subSessionIDArray.sort((a, b) => a - b)),
          approval: false,
          isShared: true,
          rejected: false,
        },
      });

      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (sessionCategories[selectedLang][0]) {
      setCategory(sessionCategories[selectedLang][0]);
    }
  }, [sessionCategories[selectedLang][0]]);

  useEffect(() => {
    setCategoryIndex(sessionCategories[selectedLang].findIndex(e => e === category));
  }, [category]);

  useEffect(() => {
    setCategory(sessionCategories[selectedLang][0]);
    setCategoryIndex(0);
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.saveModal}
      actionButtonLabel={t('Share')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={shareSession}
      closeButtonColor="Black"
    >
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t('Share Session')}</p>
      </div>
      <div className={styles.categoryWrapper}>
        <span className={styles.title}>{t('Select Category')}</span>
        <Dropdown
          className={styles.category}
          selectedItem={category}
          setSelectedItem={setCategory}
          items={sessionCategories[selectedLang]}
        />
      </div>
    </Modal>
  );
};

export default SessionShareModal;
