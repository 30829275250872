import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

const TabButton: React.FC<TabButtonType> = ({ id, label }) => {
  const {
    currentTabIndex,
    setCurrentTabIndex,
    setFirstLevel,
    setSecondLevel,
    setThirdLevel,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    setIsTab,
    setIsSubSportsField,
  } = useContext(UserContext);
  const { t } = useTranslation();

  const setData = () => {
    setIsSubSportsField(false);
    setCurrentTabIndex(id);
    setFirstLevel([]);
    setSecondLevel([]);
    setThirdLevel([]);
    setIsObjectColor(false);
    setIsObjectColorTwo(false);
    setIsObjectColorThree(false);
    setIsTab(true);
  };

  return (
    <button className={classNames(styles.wrapper, { [styles.selected]: id === currentTabIndex })} onClick={setData}>
      <p className={classNames(styles.groupNumWrapper, { [styles.selected]: id === currentTabIndex })}>{id + 1}</p>
      <p className={styles.groupNameWrapper}>{t(label)}</p>
    </button>
  );
};

export default TabButton;
