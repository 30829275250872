import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainPage from '../../pages/MainPage';
import PDFPage from '../../pages/PDFPage';

const MainRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/pdf-view" element={<PDFPage />} />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoute;
