import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';

import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  description: string;
};

const SubscribeModal: React.FC<Props> = ({ open, setOpen, description }) => {
  const { t } = useTranslation();

  const gotoSubscribePage = () => {
    window.open('https://www.id-sport.com/dashboard/', '_blank ');
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.subscribeModal}
      actionButtonLabel={t('Subscribe now')}
      cancelButtonLabel={t('Not now')}
      actionButtonEvent={gotoSubscribePage}
      closeButtonColor="Black"
    >
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t('This feature is locked')}</p>
        <LockIcon className={styles.lockIcon} />
      </div>
      <div className={styles.descriptionWrapper}>
        <p className={styles.description}>{t(description)}</p>
      </div>
    </Modal>
  );
};

export default SubscribeModal;
