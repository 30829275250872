import React, { useContext, useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Modal from '../Modal';
import SubscribeModal from '../SubscribeModal';

import { ReactComponent as PlayIcon } from '../../../assets/icons/play-1.svg';

import { UserContext } from '../../../providers/UserProvider';

const isBrowser = typeof window !== 'undefined';

import styles from './styles.module.scss';

const PDFViewButton = () => {
  const { t } = useTranslation();

  const { screenshot, customImage, currentImage, descriptionTitle, description, selectedLang } =
    useContext(UserContext);

  const saveData = () => {
    localStorage.setItem('screenshot', '');
    localStorage.setItem('customImage', '');
    localStorage.setItem('currentImage', '');
    localStorage.setItem('descriptionTitle', '');
    localStorage.setItem('description', '');
    localStorage.setItem('selectedLang', '');

    localStorage.setItem('screenshot', screenshot);
    localStorage.setItem('customImage', customImage);
    localStorage.setItem('currentImage', currentImage);
    localStorage.setItem('descriptionTitle', descriptionTitle);
    localStorage.setItem('description', JSON.stringify(description));
    localStorage.setItem('selectedLang', selectedLang);
  };

  return (
    <Link onClick={saveData} to="/pdf-view" target="_blank" className={styles.pdfViewButton}>
      {t('PDF View')}
    </Link>
  );
};

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PDFModal: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();

  const {
    screenshot,
    description,
    selectedLang,
    currentImage,
    customImage,
    customVideo,
    memberType,
    membershipState,
    isModify,
    ideaType,
    isNewSession,
    descriptionTitle,
    setFromIdea,
    setOpenFolderModal,
    setIsModify,
    setOpenSessionsModal,
    setOpenIdeasModal,
    setIsResetIdeas,
    setSelectedSessionIdeaID,
  } = useContext(UserContext);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [isEmbedVideo, setIsEmbedVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const generatePDF = () => {
    const element = document.querySelector('#report') as HTMLElement;
    if (isBrowser) {
      html2pdf(element, {
        html2canvas: { useCORS: true, scale: 1 },
        filename: `id-sport.pdf`,
        margin: 6,
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        loading: 'lazy',
      });
    }
  };

  const goToPDFPage = () => {
    localStorage.setItem('selectedImage', selectedImage);
    localStorage.setItem('screenshot', screenshot);
    localStorage.setItem('customImage', customImage);
    localStorage.setItem('currentImage', currentImage);
    localStorage.setItem('descriptionTitle', descriptionTitle);
    localStorage.setItem('description', JSON.stringify(description));
    localStorage.setItem('selectedLang', selectedLang);

    window.open('/pdf-view', '_blank ');
  };

  const onModify = () => {
    if (memberType !== 'member' || (memberType === 'member' && membershipState > 3)) {
      setIsModify(true);
      setOpen(false);
    } else {
      setIsSubscribeModal(true);
    }
  };

  const gotoFolderModal = () => {
    if (ideaType === 'idea') {
      if (!isNewSession) {
        setIsResetIdeas(false);
        setOpenFolderModal(true);
      }
    } else if (ideaType === 'sessionIdea') {
      setOpenSessionsModal(true);
      setSelectedSessionIdeaID(-1);
      setTimeout(() => {
        setOpenIdeasModal(true);
      }, 100);
    }
    setFromIdea(false);
  };

  useEffect(() => {
    if (customVideo.includes('/embed/') && customVideo.includes('clip')) {
      setIsEmbedVideo(true);
    } else {
      setIsEmbedVideo(false);
    }
  }, [customVideo]);

  useEffect(() => {
    localStorage.setItem('selectedImage', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    setSelectedImage('');
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.modal}
      // actionButtonLabel={isNewSession ? undefined : 'View PDF'}
      // actionButtonEvent={generatePDF}
      closeButtonColor="Black"
      cancelButtonLabel={isNewSession ? undefined : 'Modify'}
      cancelButtonEvent={onModify}
      closeIconEvent={gotoFolderModal}
      ActionButton={PDFViewButton}
    >
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper} id="report">
          <div className={styles.content}>
            {/* <img src="uploads/images/logo/logo-id-sport.png" alt="logo" className={styles.logo} /> */}
            <p className={styles.title}>
              {/*{description
                ? selectedLang === 'EN'
                  ? description.title_EN
                  : selectedLang === 'FR'
                  ? description.title_FR
                  : selectedLang === 'DU'
                  ? description.title_DU
                  : description.title_GE
              : ''}*/}
              {descriptionTitle}
            </p>
            <div className={styles.imageWrapper}>
              <div className={styles.mainImageWrapper}>
                {selectedImage === '' ? (
                  customVideo !== '' ? (
                    isEmbedVideo ? (
                      <iframe
                        className={classNames(styles.mainImage)}
                        src={`${customVideo}&loop=1?controls=0?allowfullscreen=0`}
                        title="Embed Video"
                      />
                    ) : (
                      <ReactPlayer
                        className={styles.mainImage}
                        onClick={() => setIsPlaying(prev => !prev)}
                        url={customVideo}
                        playing={isPlaying}
                        // controls={true}
                        loop={true}
                        width="100%"
                        height="100%"
                        config={{
                          facebook: {
                            attributes: {
                              height: '410px',
                            },
                          },
                        }}
                      />
                    )
                  ) : isModify && screenshot !== '' ? (
                    <img src={screenshot} alt="" className={styles.mainImage} />
                  ) : currentImage !== '' ? (
                    <img src={currentImage} alt="" className={styles.mainImage} />
                  ) : customImage !== '' ? (
                    <img src={customImage} alt="" className={styles.mainImage} />
                  ) : null
                ) : selectedImage === '3D' ? (
                  <img
                    src={isModify && screenshot !== '' ? screenshot : currentImage}
                    alt=""
                    className={styles.mainImage}
                  />
                ) : selectedImage === 'image' ? (
                  <img src={customImage} alt="" className={styles.mainImage} />
                ) : isEmbedVideo ? (
                  <iframe
                    className={classNames(styles.mainImage)}
                    src={`${customVideo}&loop=1?controls=0`}
                    title="Embed Video"
                  />
                ) : (
                  <ReactPlayer
                    className={styles.mainImage}
                    onClick={() => setIsPlaying(prev => !prev)}
                    url={customVideo}
                    // controls={true}
                    loop={true}
                    width="100%"
                    height="100%"
                    config={{
                      facebook: {
                        attributes: {
                          height: '410px',
                        },
                      },
                    }}
                  />
                )}
              </div>
              <div className={styles.buttons}>
                {customVideo !== '' && (
                  <div className={classNames(styles.buttonWrapper)}>
                    <div className={classNames(styles.button)}>
                      <div className={styles.buttonImageWrapper}>
                        {customImage !== '' && <img className={styles.image} src={customImage} alt="" />}
                        <div className={styles.overlap}>
                          <PlayIcon fill={customImage === '' ? '#000000' : '#ffffff'} className={styles.icon} />
                        </div>
                      </div>
                      <div
                        className={styles.over}
                        onClick={() => {
                          setSelectedImage('video');
                        }}
                      />
                    </div>
                    <p className={styles.label}>{t('Video')}</p>
                  </div>
                )}
                {!(currentImage === '' && screenshot === '') && (
                  <div className={classNames(styles.buttonWrapper)}>
                    <div className={classNames(styles.button)}>
                      <img
                        className={styles.image}
                        src={isModify && screenshot !== '' ? screenshot : currentImage}
                      ></img>
                      <div
                        className={styles.over}
                        onClick={() => {
                          setSelectedImage('3D');
                        }}
                      />
                    </div>
                    <p className={styles.label}>{t('3D')}</p>
                  </div>
                )}
                {customImage !== '' && (
                  <div className={classNames(styles.buttonWrapper)}>
                    <div className={classNames(styles.button)}>
                      <img className={styles.image} src={customImage}></img>
                      <div
                        className={styles.over}
                        onClick={() => {
                          setSelectedImage('image');
                        }}
                      />
                    </div>
                    <p className={styles.label}>{t('Image')}</p>
                  </div>
                )}
              </div>
            </div>
            {description && (
              <span className={styles.pdfModal} dangerouslySetInnerHTML={{ __html: description[selectedLang] }}></span>
            )}
          </div>
        </div>
      </div>
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={"Please upgrade your subscription plan to 'Premium' or 'Pro' to unlock it."}
      />
    </Modal>
  );
};

export default PDFModal;
