import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as DotIcon } from '../../../assets/icons/dot.svg';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort-down-svgrepo-com.svg';

import styles from './styles.module.scss';

const sortNames = ['Newest first', 'Older first', 'Name (A-Z)', 'Name (Z-A)'];

type Props = {
  className?: string;
};

const SortIdeas: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { sortType, setSortType } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const onAction = (e: any) => {
    e.stopPropagation();
    setOpen(prev => !prev);
  };

  const onClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const sort = (name: string) => {
    setSortType(name);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <button
            className={styles.sortButton}
            onClick={(e: any) => {
              onAction(e);
            }}
          >
            <SortIcon fill="#0787ea" className={styles.listIcon} />
            <span className={styles.text}>{t('Sort by')}</span>
          </button>
          {open && (
            <>
              <div className={styles.arrowDiv} />
              <div className={classNames(styles.arrowDiv, styles.hidden)} />
              <div
                className={styles.background}
                onClick={(e: any) => {
                  onClose(e);
                }}
              ></div>
              <div className={styles.mainField}>
                {sortNames.map((item, index: number) => (
                  <button
                    key={index}
                    className={classNames(styles.button, {
                      [styles.select]: item === sortType,
                    })}
                    onClick={() => {
                      sort(item);
                    }}
                  >
                    <DotIcon className={styles.icon} fill={item === sortType ? '#0787ea' : '#ffffff'} />
                    {t(item)}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SortIdeas;
