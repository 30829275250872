import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import SessionIdeaAction from '../SessionIdeaAction';

import { ReactComponent as PlayIcon } from '../../../assets/icons/play-1.svg';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  idea: SessionIdeaType;
  subID: number;
  repeatNumber: number;
  executionTime: string;
  currentImage: string;
  customImage: string;
  customVideo: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  canvasRef: React.RefObject<ExportObject>;
};

const SessionIdea: React.FC<Props> = ({
  subID,
  repeatNumber,
  executionTime,
  currentImage,
  customImage,
  customVideo,
  idea,
  setOpen,
  canvasRef,
}) => {
  const {
    sessionFolderType,
    memberType,
    setFromIdea,
    setSceneCharacters,
    setSceneSportsObjects,
    setSceneId,
    setCharacterRotationInfo,
    setSportsObjectRotationInfo,
    setSportsFieldObject,
    setIsField,
    setCharacterMaterials,
    setIsSavedState,
    setCurrentImage,
    setDescription,
    setIsCustomImage,
    setIsSubSportsField,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    setDescriptionTitle,
    setEditorState,
    setCustomImage,
    setCustomVideo,
    setScreenshot,
    setCurrentImageBlob,
    setSelectedMenuTitle,
    setIdeaType,
    setOpenSessionsModal,
    setSelectedSessionIdeaID,
    setIsModify,
    setIsPDFModal,
    setIsNewSession,
  } = useContext(UserContext);

  const [addNumber, setAddNumber] = useState<number>(repeatNumber);
  const [addTime, setAddTime] = useState<string>(executionTime);

  const clearScene = () => {
    setSceneCharacters([]);
    setSceneSportsObjects([]);
    setSceneId(-1);
    setCharacterRotationInfo([]);
    setSportsObjectRotationInfo([]);
    setSportsFieldObject({
      object: '',
    });
    setIsField(false);
    setCharacterMaterials([]);
    // setDescription(null);
    setIsCustomImage(false);
    setIsSubSportsField(false);
    setIsObjectColor(false);
    setIsObjectColorTwo(false);
    setIsObjectColorThree(false);
    setDescriptionTitle('');
    setEditorState('');
    setCustomImage('');
    setCustomVideo('');
    setScreenshot('');
    setCurrentImageBlob('');
    setCurrentImage('');
    setSelectedSessionIdeaID(-1);
  };

  const onEdit = () => {
    clearScene();
    setSelectedMenuTitle('IDEAS');
    setIdeaType('sessionIdea');
    setSelectedSessionIdeaID(idea.id);
    setFromIdea(true);

    const characterString = JSON.parse(idea.character);
    const characterTransformString = JSON.parse(idea.character_transform);
    const objectString = JSON.parse(idea.object);
    const objectTransformString = JSON.parse(idea.object_transform);
    const sportsFieldString = JSON.parse(idea.field);
    const characterMaterialString = JSON.parse(idea.character_material);

    // setSceneId(idea.id);
    setCustomImage(idea.image);
    setCurrentImage(idea.custom_image);
    setCustomVideo(idea.custom_video);
    setIsCustomImage(idea.isCustomImage);
    setDescriptionTitle(idea.name);

    const tempCharacterArray: SceneCharacterType[] = [];
    const tempCharacterRotationInfo: number[] = [];
    characterString?.map((item: any, index: number) => {
      tempCharacterArray.push({
        id: item?.id,
        object: item?.object,
        material: item?.material,
        name: item?.name,
        showModel: item?.showModel,
        showSkeleton: item?.showSkeleton,
        opacity: item?.opacity,
        modifySpeed: item?.modifySpeed,
        isSingleMode: item?.isSingleMode,
        position: characterTransformString[index].position,
        scale: [
          item?.scale[0] * characterTransformString[index].scale[0],
          item?.scale[1] * characterTransformString[index].scale[1],
          item?.scale[2] * characterTransformString[index].scale[2],
        ],
        rotation: characterTransformString[index].rotation,
        visible: item?.visible,
      });
      tempCharacterRotationInfo.push(characterTransformString[index].rotation);
    });
    setSceneCharacters(tempCharacterArray);
    setCharacterRotationInfo(tempCharacterRotationInfo);

    const tempSportsObjectArray: SceneSportsObjectType[] = [];
    const tempSportsObjectRotationInfo: number[] = [];
    objectString.map((item: any, index: number) => {
      tempSportsObjectArray.push({
        object: item?.object,
        name: item?.name,
        opacity: item?.opacity,
        color: item?.color,
        isColor: item?.isColor,
        colorObj: item?.colorObj,
        colorTwo: item?.colorTwo,
        isColorTwo: item?.isColorTwo,
        colorTwoObj: item?.colorTwoObj,
        colorThree: item?.colorThree,
        isColorThree: item?.isColorThree,
        colorThreeObj: item?.colorThreeObj,
        position: objectTransformString[index]?.position,
        rotation: objectTransformString[index]?.rotation,
        scale: [
          objectTransformString[index]?.scale[0] * item?.scale[0],
          objectTransformString[index]?.scale[1] * item?.scale[1],
          objectTransformString[index]?.scale[2] * item?.scale[2],
        ],
        visible: item?.visible,
      });
      tempSportsObjectRotationInfo.push(objectTransformString[index]?.rotation);
    });
    setSceneSportsObjects(tempSportsObjectArray);
    setSportsObjectRotationInfo(tempSportsObjectRotationInfo);

    setSportsFieldObject(sportsFieldString?.sportsFieldObject);
    setIsField(sportsFieldString?.isField);
    setCharacterMaterials(characterMaterialString);
    // canvasRef.current?.generateScreenObject();

    setOpenSessionsModal(false);
    setOpen(false);
    setIsSavedState(false);
  };

  const editIdea = () => {
    onEdit();
    setIsModify(true);
    setIsNewSession(false);
  };

  const onClickIdea = () => {
    if (sessionFolderType === 'Shared Folders' && memberType === 'member') {
      setIsNewSession(true);
    } else {
      setIsNewSession(false);
      onEdit();
    }
    setIsPDFModal(true);
  };

  return (
    <div className={classNames(styles.wrapper)} onClick={onClickIdea}>
      <div className={styles.titleWrapper}>
        <div className={styles.numbers}>
          {addNumber.toString() !== '0' && <div className={styles.addNumber}>{addNumber}</div>}
          {addTime !== '' && <span>{addTime}</span>}
        </div>
        <div className={styles.mainPart}>
          <span className={styles.title}>{idea.name}</span>
          {(sessionFolderType === 'Folders' ||
            (sessionFolderType === 'Shared Folders' && memberType !== 'member') ||
            (sessionFolderType === 'Shared by members' && memberType !== 'member')) && (
            <SessionIdeaAction
              id={idea.id}
              subID={subID}
              addNumber={addNumber}
              setAddNumber={setAddNumber}
              addTime={addTime}
              setAddTime={setAddTime}
              editIdea={editIdea}
            />
          )}
        </div>
      </div>
      {customVideo !== '' ? (
        <div className={styles.imageWrapper}>
          {currentImage !== '' && <img className={styles.image} src={currentImage} alt="" />}
          {currentImage === '' && customImage !== '' && <img className={styles.image} src={customImage} alt="" />}
          <div className={styles.overlap}>
            <PlayIcon fill={customImage === '' ? '#000000' : '#ffffff'} className={styles.icon} />
          </div>
        </div>
      ) : currentImage !== '' ? (
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={currentImage} alt="" />
        </div>
      ) : customImage !== '' ? (
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={customImage} alt="" />
        </div>
      ) : (
        <div className={styles.imageWrapper}></div>
      )}
    </div>
  );
};

export default SessionIdea;
