import classNames from 'classnames';
import React, { useContext, useState } from 'react';

import SubscribeModal from '../SubscribeModal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  materials: TabItemType[];
};

const CharacterMaterial: React.FC<Props> = ({ materials }) => {
  const { selectedCharacterIndex, changeCharacterDetails, sceneCharacters, membershipState, memberType } =
    useContext(UserContext);
  const [isMemberModal, setIsMemberModal] = useState(false);

  if (selectedCharacterIndex === -1 || sceneCharacters.length === 0 || !sceneCharacters[selectedCharacterIndex].visible)
    return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.overflow}>
        {materials.map((item, index: number) => (
          <button
            key={index}
            className={styles.button}
            onClick={() => {
              if (!item?.demo && membershipState === 0 && memberType === 'member') {
                setIsMemberModal(true);
              } else {
                changeCharacterDetails(selectedCharacterIndex, { material: item.object });
              }
            }}
          >
            <img
              className={classNames(styles.imageWrapper, {
                [styles.demo]: !item?.demo && membershipState === 0 && memberType === 'member',
              })}
              alt="objectImage"
              src={item.image}
            />
          </button>
        ))}
      </div>
      <SubscribeModal
        open={isMemberModal}
        setOpen={setIsMemberModal}
        description={'Please upgrade your subscription plan to unlock it.'}
      />
    </div>
  );
};

export default CharacterMaterial;
