import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useDebounce from '../../../hooks/useDebounce';
import CategoryBar from '../CategoryBar';
import Filter from '../Filter';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/right-arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/left-arrow.svg';

import styles from './styles.module.scss';

const pendingCategories = ['Ideas pending approval'];

type Props = {
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  isNewSession?: boolean;
};

const FolderCategoryBar: React.FC<Props> = ({ setPageNum, isNewSession = false }) => {
  const { t } = useTranslation();
  const { categories, customCategories, memberType, keyWord, selectedMenuTitle, setKeyWord } = useContext(UserContext);
  const [miniBarShow, setMiniBarShow] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState(keyWord);
  const searchName = useDebounce(searchKeyWord, 1000);

  useEffect(() => {
    setKeyWord(searchName);
  }, [searchName]);

  return (
    <>
      <div className={styles.menuWrapper}>
        {/*<div className={styles.searchBar}>
          <SearchIcon className={styles.searchIcon} />
          <input
            type="text"
            // placeholder={selectedMenuTitle === 'IDEAS' ? 'name of idea' : 'name of session'}
            placeholder={t('name of idea')}
            className={styles.input}
            value={searchKeyWord}
            onChange={e => {
              setSearchKeyWord(e.target.value);
            }}
          />
          <Filter />
          </div>*/}
        <CategoryBar title="Folders" categories={customCategories} setPageNum={setPageNum} />
        <CategoryBar title="Shared Folders" categories={categories} setPageNum={setPageNum} />
        {memberType !== 'member' && !isNewSession && (
          <CategoryBar
            title="Shared by members"
            categories={pendingCategories}
            addFolder={false}
            setPageNum={setPageNum}
          />
        )}
      </div>
      <div
        className={classNames(styles.miniMenuBar, {
          [styles.show]: miniBarShow,
        })}
      >
        <div className={styles.relativeDiv}>
          <div className={styles.miniMenuWrapper}>
            {/*<div className={styles.searchBar}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                placeholder={t('name of idea')}
                className={styles.input}
                onChange={e => {
                  setSearchKeyWord(e.target.value);
                }}
              />
              <Filter />
            </div>*/}
            <CategoryBar title="Folders" categories={customCategories} setPageNum={setPageNum} />
            <CategoryBar title="Shared Folders" categories={categories} setPageNum={setPageNum} />
            {memberType !== 'member' && !isNewSession && (
              <CategoryBar
                title="Shared by members"
                categories={pendingCategories}
                addFolder={false}
                setPageNum={setPageNum}
              />
            )}
          </div>
          {miniBarShow ? (
            <div className={styles.iconWrapper}>
              <LeftArrowIcon fill={'#1E7EEB'} className={styles.showIcon} onClick={() => setMiniBarShow(false)} />
            </div>
          ) : (
            <div className={classNames(styles.iconWrapper, styles.hiddenIcon)} onClick={() => setMiniBarShow(true)}>
              <span className={styles.textField}>{t('Folders')}</span>
              <RightArrowIcon fill={'#1E7EEB'} className={styles.showIcon} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FolderCategoryBar;
