import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  label: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'reset' | 'submit';
  colorType?: 'primary' | 'secondary' | 'third' | 'forth';
};

const Button: React.FC<Props> = ({ label, className = '', onClick, type = 'button', colorType = 'primary' }) => {
  const { t } = useTranslation();

  return (
    <button type={type} className={classNames(styles.wrapper, className, styles[colorType])} onClick={onClick}>
      {t(label)}
    </button>
  );
};

export default Button;
