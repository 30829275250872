import React, { useEffect } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  content: string;
};

const Alert: React.FC<Props> = ({ isShow, setIsShow, title = '', content }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        setIsShow(false);
      }, 5000);
    }
  }, [isShow]);

  return isShow ? (
    <div className={styles.wrapper}>
      <div className={styles.mainWrapper} role="alert">
        <strong className={styles.title}>{t(title)}</strong>
        <span className={styles.content}>{t(content)}</span>
        <CloseIcon
          className={styles.closeIcon}
          onClick={() => {
            setIsShow(false);
          }}
        />
      </div>
    </div>
  ) : null;
};

export default Alert;
