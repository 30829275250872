import React, { useState, useRef, useContext, useEffect } from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import Configurator from '../Configurator';
import { UserContext } from '../../../providers/UserProvider';
import TabButton from '../../_ui/TabButton';
import SportFieldTap from '../../_tab/SportFieldTab';
import CharacterTab from '../../_tab/CharacterTab';
import SportsTab from '../../_tab/SportsTab';
import PhysicalEducationTab from '../../_tab/PhysicalEducationTab';
import AccessoriesTab from '../../_tab/AccessoriesTab';
import OthersTab from '../../_tab/OthersTab';

import { ReactComponent as ListIcon } from '../../../assets/icons/fullscreen-exit.svg';
import { ReactComponent as ListArrowIcon } from '../../../assets/icons/full-screen.svg';
import { ReactComponent as NextArrowIcon } from '../../../assets/icons/right-arrow.svg';
import { ReactComponent as PrevArrowIcon } from '../../../assets/icons/left-arrow.svg';

import { getWindowSize } from '../../../utils/getWindowSize';

import tabButtonLists from '../../../json/tabButtonLists.json';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  canvasRef: React.RefObject<ExportObject>;
};

const CustomCarousel: React.FC<Props> = ({ canvasRef }) => {
  const { currentTabIndex, isTab, customImage, customVideo, sceneId, isShowCanvasTools, isModify, setIsTab } =
    useContext(UserContext);
  const [sliderNum, setSliderNum] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const width = document.getElementById('carousel')?.clientWidth;
  const height = document.getElementById('carousel')?.clientHeight;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isEmbedVideo, setIsEmbedVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const slide = useRef<any>();

  const moveSlide = (page: number) => {
    if (width !== undefined) {
      slide.current.style.marginLeft = `-${width * (page - 1)}px`;
    }
  };

  const onClickPrev = () => {
    if (currentPage > 1) {
      moveSlide(currentPage - 1);
      setCurrentPage(currentPage - 1);
    } else {
      moveSlide(sliderNum);
      setCurrentPage(sliderNum);
    }
  };

  const onClickNext = () => {
    if (currentPage < sliderNum) {
      moveSlide(currentPage + 1);
      setCurrentPage(currentPage + 1);
    } else {
      moveSlide(1);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (customVideo !== '') {
      moveSlide(2);
      setCurrentPage(2);
    } else if (customImage !== '') {
      moveSlide(2);
      setCurrentPage(2);
    } else {
      moveSlide(1);
      setCurrentPage(1);
    }
  }, [isModify]);

  useEffect(() => {
    if (customImage === '' && customVideo === '') {
      setSliderNum(1);
    } else if (customImage !== '' && customVideo !== '') {
      setSliderNum(3);
      moveSlide(2);
    } else {
      setSliderNum(2);
      moveSlide(2);
    }
  }, [customImage, customVideo]);

  useEffect(() => {
    if (customImage === '' && customVideo === '') {
      moveSlide(1);
    } else if (customImage !== '' && customVideo !== '') {
      moveSlide(3);
    } else {
      moveSlide(2);
    }
  }, [customImage]);

  useEffect(() => {
    if (customImage === '' && customVideo === '') {
      moveSlide(1);
    } else if (customImage !== '' && customVideo !== '') {
      moveSlide(2);
    } else {
      moveSlide(2);
    }
    if (customVideo.includes('/embed/') && customVideo.includes('clip')) {
      setIsEmbedVideo(true);
    } else {
      setIsEmbedVideo(false);
    }
  }, [customVideo]);

  useEffect(() => {
    if (sceneId === -1) {
      moveSlide(1);
      setCurrentPage(1);
      setSliderNum(1);
    }
  }, [sceneId]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div id="carousel" className={styles.carousel}>
      <div className={styles.sliders}>
        <div ref={slide} className={classNames(styles.item)}>
          <div className={classNames(styles.wrapper)}>
            <div className={styles.canvasPart}>
              <Configurator canvasRef={canvasRef} />
            </div>
            <div className={styles.controller}>
              <div className={styles.iconWrapper}>
                {isTab ? (
                  <ListArrowIcon
                    className={styles.icon}
                    onClick={() => {
                      setIsTab(false);
                    }}
                  />
                ) : (
                  <ListIcon
                    className={styles.icon}
                    onClick={() => {
                      setIsTab(true);
                    }}
                  />
                )}
              </div>
              <div className={styles.overflow}>
                {isShowCanvasTools && (
                  <div className={styles.tabButtonWrapper}>
                    {tabButtonLists.map((tabButtonItem: TabButtonType) => (
                      <TabButton key={tabButtonItem.id} {...tabButtonItem} />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(styles.objectsWrapper, {
                [styles.show]: isTab,
              })}
            >
              {isTab && isShowCanvasTools && (
                <>
                  {currentTabIndex === 0 && <SportFieldTap />}
                  {currentTabIndex === 1 && <CharacterTab />}
                  {currentTabIndex === 2 && <SportsTab />}
                  {currentTabIndex === 3 && <PhysicalEducationTab />}
                  {currentTabIndex === 4 && <AccessoriesTab />}
                  {currentTabIndex === 5 && <OthersTab />}
                </>
              )}
            </div>
          </div>
        </div>
        {customVideo !== '' && (
          <div className={classNames(styles.item)}>
            <div className={classNames(styles.wrapper)}>
              {isEmbedVideo ? (
                <iframe
                  className={classNames(styles.image, styles.iframeTag, styles.img)}
                  src={`${customVideo}&loop=1?controls=0`}
                  title="Embed Video"
                />
              ) : (
                <ReactPlayer
                  className={styles.image}
                  onClick={() => setIsPlaying(prev => !prev)}
                  url={customVideo}
                  playing={isPlaying}
                  // controls={true}
                  loop={true}
                  width="100%"
                  height="100%"
                  config={{
                    facebook: {
                      attributes: {
                        height: windowSize.innerHeight ? `${windowSize.innerHeight - 100}px` : '600px',
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        )}
        {customImage !== '' && (
          <div className={classNames(styles.item)}>
            <div className={classNames(styles.wrapper)}>
              <img src={customImage} alt="" className={classNames(styles.image, styles.img)} />
            </div>
          </div>
        )}
      </div>
      {sliderNum > 1 && (
        <div
          onClick={() => {
            onClickPrev();
          }}
          className={classNames(styles.button, styles.left)}
        >
          <PrevArrowIcon className={styles.icon} />
        </div>
      )}
      {sliderNum > 1 && (
        <div
          onClick={() => {
            onClickNext();
          }}
          className={classNames(styles.button, styles.right)}
        >
          <NextArrowIcon className={styles.icon} />
        </div>
      )}
    </div>
  );
};

export default CustomCarousel;
