import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categories: string[];
  categoryType: string;
};

const SaveCategoryModal: React.FC<Props> = ({ open, setOpen, categories, categoryType }) => {
  const { t } = useTranslation();
  const { setCategories, setCustomCategories, setIsLoading, userId } = useContext(UserContext);
  const [isValid, setIsValid] = useState(false);
  const [category, setCategory] = useState('');

  const saveCategory = async () => {
    const categoriesTemp: string[] = [];
    categories.map((item: string) => {
      categoriesTemp.push(item);
    });
    if (category !== '') {
      categoriesTemp.push(category);
    }

    if (category === '' || categories.find(element => element.toLowerCase() === category.toLowerCase())) {
      setIsValid(true);
    } else {
      try {
        setIsLoading(true);
        if (categoryType === 'Folders') {
          const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/save-custom-categories/${userId}`, {
            category: JSON.stringify(categoriesTemp),
          });
          setCustomCategories(categoriesTemp);
        }
        if (categoryType === 'Shared Folders') {
          const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/save-categories`, {
            category: category,
          });
          setCategories(categoriesTemp);
        }
        setIsValid(false);
        setIsLoading(false);
        setCategory('');
      } catch (error) {
        setIsLoading(false);
        setIsValid(false);
        setCategory('');
        console.log('error: ', error);
      }
    }
  };

  useEffect(() => {
    setCategory('');
    setIsValid(false);
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.saveModal}
      actionButtonLabel={t('Save')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={saveCategory}
      closeButtonColor="Black"
    >
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t('Save Category')}</p>
      </div>
      <div className={styles.savedWrapper}>
        <p className={styles.title}>{t('Saved Categories')}</p>
        <div className={styles.savedField}>
          {categories.length === 0 ? (
            <p className={styles.button}>{t('No Category')}</p>
          ) : (
            categories?.map((item, index: number) => (
              <p key={index} className={styles.button}>
                {item}
              </p>
            ))
          )}
        </div>
      </div>
      <div className={styles.inputWrapper}>
        {isValid && <p className={styles.validText}>{t('Name is empty or exists')}</p>}
        <input
          className={styles.input}
          placeholder={t('Enter the category name')}
          value={category}
          onChange={e => {
            setCategory(e.target.value);
          }}
        />
      </div>
    </Modal>
  );
};

export default SaveCategoryModal;
