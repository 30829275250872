import React, { createRef, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import Loading from '../../components/_ui/Loading';
import FoldersModal from '../../components/_ui/FoldersModal';
import Description from '../../components/_ui/Description';
import Container from '../../components/_ui/Container';
import MenuDropdown from '../../components/_ui/MenuDropdown';
import LangDropdown from '../../components/_ui/LangDropdown';
import SessionsModal from '../../components/_ui/SessionsModal';
import CustomCarousel from '../../components/_three/CustomCarousel';
import NewSession from '../../components/_ui/NewSession';
import PDFModal from '../../components/_ui/PDFModal';

import logoImage from '../../assets/logo-id-sport.png';

import { UserContext } from '../../providers/UserProvider';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

const MainPage: React.FC = () => {
  const {
    userId,
    openFolderModal,
    openSessionsModal,
    selectedMenuTitle,
    isNewSession,
    isPDFModal,
    setIsPDFModal,
    setOpenFolderModal,
    setSelectedLang,
    setMembershipState,
    setMemberType,
    setUserId,
    setCategories,
    setCustomCategories,
    setNames,
    setIsLoading,
    setOpenSessionsModal,
    setSessionCategories,
    setSessionCustomCategories,
    setUserInfo,
  } = useContext(UserContext);
  const canvasRef = createRef<ExportObject>();
  const [searchParams] = useSearchParams();

  const getMembershipState = async (uid: number) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_SUPPORT_API}user/membership/${uid}`);
      setUserInfo(response?.data);
      setMembershipState(response?.data?.membershipId ?? 0);
      // setMembershipState(8);
      setMemberType(response?.data?.type ?? 'member');
      // setMemberType('member');
      // if (response?.data?.membershipId > 2 || response?.data?.type !== 'member') {
      //   setOpenFolderModal(true);
      // } else {
      //   setIsLoading(false);
      // }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      window.location.href = 'https://www.id-sport.com/';
      console.error(error);
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_API}scene-data/categories`);
      const tempArray: string[] = [];
      response?.data.map((item: any) => {
        tempArray.push(item.categories);
      });
      setCategories(tempArray);
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomCategories = async (uid: number) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_API}scene-data/custom-categories/${uid}`);
      if (
        response?.data === null ||
        (!response?.data?.custom_categories && JSON.parse(response?.data?.custom_categories).length === 0)
      ) {
        const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/save-custom-categories/${uid}`, {
          category: JSON.stringify(['My exercises']),
        });
        setCustomCategories(['My exercises']);
      } else {
        setCustomCategories(JSON.parse(response?.data?.custom_categories));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIdeasNames = async () => {
    try {
      // setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_MAIN_API}scene-data/names/${userId}`);
      const tempArray: any[] = [];
      res.data?.map((item: any) => {
        // setNames(prev => [...prev, item.name]);
        tempArray.push(item.name);
      });
      setNames(tempArray);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log('error: ', error);
    }
  };

  const getSessionCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_API}session/categories`);
      const tempData: SessionCategoryType = {
        EN: [],
        DU: [],
        FR: [],
        GE: [],
      };
      response?.data.map((item: any) => {
        tempData.EN.push(item.category_EN);
        tempData.DU.push(item.category_DU);
        tempData.FR.push(item.category_FR);
        tempData.GE.push(item.category_GE);
      });
      setSessionCategories(tempData);
    } catch (error) {
      console.error(error);
    }
  };

  const getSessionCustomCategories = async (uid: number) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_API}session/custom-categories/${uid}`);
      if (
        response?.data === null ||
        response?.data === undefined ||
        JSON.parse(response?.data?.category_EN).length === 0
      ) {
        const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/save-custom-categories/${uid}`, {
          category: {
            category_EN: JSON.stringify(['My exercises']),
            category_DU: JSON.stringify(['Mijn oefeningen']),
            category_FR: JSON.stringify(['Mes exercices']),
            category_GE: JSON.stringify(['Meine Übungen']),
          },
        });
        setSessionCustomCategories({
          EN: ['My exercises'],
          DU: ['Mijn oefeningen'],
          FR: ['Mes exercices'],
          GE: ['Meine Übungen'],
        });
      } else {
        setSessionCustomCategories({
          EN: JSON.parse(response?.data?.category_EN),
          DU: JSON.parse(response?.data?.category_DU),
          FR: JSON.parse(response?.data?.category_FR),
          GE: JSON.parse(response?.data?.category_GE),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let userID = -1;
    if (searchParams.toString() !== '' && !!searchParams) {
      try {
        const decodeParams = JSON.parse(atob(searchParams.toString().slice(0, -1)));
        setSelectedLang(
          decodeParams.lang === '_en'
            ? 'EN'
            : decodeParams.lang === '_fr'
            ? 'FR'
            : decodeParams.lang === '_nl'
            ? 'DU'
            : 'GE'
        );
        userID = decodeParams.id;
        // console.log('userID: ', userID);
        // userID = 5;

        // const name = localStorage.getItem('name');
        // if (name !== null && name !== "") {
        //   importIdeas(name, userID);
        // }

        setUserId(userID);
      } catch (error) {
        window.location.href = 'https://www.id-sport.com/';
      }
    } else {
      window.location.href = 'https://www.id-sport.com/';
    }

    getMembershipState(userID);
    getCategories();
    getCustomCategories(userID);
    getSessionCategories();
    getSessionCustomCategories(userID);
  }, []);

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.layoutWrapper}>
        <img className={styles.image} alt="logo" src={logoImage} />
        <div className={styles.menu}>
          <MenuDropdown canvasRef={canvasRef} />
        </div>
        <LangDropdown />
      </div>
      <Container>
        {selectedMenuTitle === 'IDEAS' && !isNewSession ? (
          <div className={styles.wrapper}>
            <div className={styles.descriptionField}>
              <Description getIdeasNames={getIdeasNames} canvasRef={canvasRef} />
            </div>
            <div className={styles.canvasField}>
              {/* <MainContent canvasRef={canvasRef} /> */}
              <CustomCarousel canvasRef={canvasRef} />
            </div>
          </div>
        ) : (
          <div className={styles.newSession}>
            <NewSession canvasRef={canvasRef} />
          </div>
        )}
        <PDFModal open={isPDFModal} setOpen={setIsPDFModal} />
        <FoldersModal open={openFolderModal} setOpen={setOpenFolderModal} canvasRef={canvasRef} />
        <SessionsModal open={openSessionsModal} setOpen={setOpenSessionsModal} canvasRef={canvasRef} />
      </Container>
      <Loading />
    </div>
  );
};

export default MainPage;
