import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './lang/en.json';
import translationFR from './lang/fr.json';
import translationDU from './lang/du.json';
import translationGE from './lang/ge.json';

// the translations
const resources = {
  /*English*/
  en: {
    translation: translationEN,
  },
  /*French*/
  fr: {
    translation: translationFR,
  },
  /*Dutch*/
  du: {
    translation: translationDU,
  },
  /*German*/
  ge: {
    translation: translationGE,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
