import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ReactComponent as PlusBlueIcon } from '../../../assets/icons/plus-blue.svg';

import SubSession from '../SubSession';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  canvasRef: React.RefObject<ExportObject>;
};

const NewSession: React.FC<Props> = ({ canvasRef }) => {
  const { t } = useTranslation();
  const { newSession, selectedLang, setNewSession } = useContext(UserContext);
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState('');
  const [len, setLen] = useState(newSession.subSessions.length);
  const [isAddNewSubSession, setIsAddNewSubSession] = useState(false);
  const [isSubSessionDraggable, setIsSubSessionDraggable] = useState(false);

  const onPlus = () => {
    const tempArray = { ...newSession };
    if (
      tempArray.subSessions[tempArray.subSessions.length - 1][`title_${selectedLang}`] === '' ||
      (tempArray.subSessions[tempArray.subSessions.length - 1].ideas.length === 1 &&
        tempArray.subSessions[tempArray.subSessions.length - 1].ideas[0].idea === -1)
    ) {
      setIsAddNewSubSession(true);
    } else {
      tempArray.subSessions.push({
        id: len,
        session: -1,
        title_EN: '',
        title_DU: '',
        title_FR: '',
        title_GE: '',
        text_EN: '',
        text_DU: '',
        text_FR: '',
        text_GE: '',
        repeat_number: [0],
        execution_time: [''],
        ideas: [
          {
            id: 0,
            idea: -1,
          },
        ],
      });
      setNewSession(tempArray);
      setLen(prev => prev + 1);
      setIsAddNewSubSession(false);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const tempArray = { ...newSession };
    const [moved] = tempArray.subSessions.splice(result.source.index, 1);
    tempArray.subSessions.splice(result.destination.index, 0, moved);
    setNewSession(tempArray);
  };

  useEffect(() => {
    setTitle(newSession[`title_${selectedLang}`]);
    setText(newSession[`text_${selectedLang}`]);
  }, [selectedLang]);

  useEffect(() => {
    const tempArray = { ...newSession };
    tempArray[`title_${selectedLang}`] = title;
    setNewSession(tempArray);
  }, [title]);

  useEffect(() => {
    const tempArray = { ...newSession };
    tempArray[`text_${selectedLang}`] = text;
    setNewSession(tempArray);
  }, [text]);

  useMemo(() => {
    setTitle(newSession[`title_${selectedLang}`]);
    setText(newSession[`text_${selectedLang}`]);
    if (
      newSession.subSessions[newSession.subSessions.length - 1][`title_${selectedLang}`] !== '' &&
      newSession.subSessions[newSession.subSessions.length - 1].ideas[0].idea !== -1
    ) {
      setIsAddNewSubSession(false);
    }
  }, [newSession]);

  return (
    <div className={styles.wrapper}>
      <input
        placeholder={t('Text Session Title')}
        value={title}
        onChange={e => {
          setTitle(e.target.value);
        }}
        type="text"
        className={styles.title}
      />
      <textarea
        placeholder={t('Text Session Description')}
        value={text}
        onChange={e => setText(e.target.value)}
        className={styles.description}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableSubSession" direction="vertical" type="ROW">
          {({ innerRef }) => (
            <div className={styles.subSessionWrapper} ref={innerRef}>
              {newSession.subSessions.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`${item.id}`}
                  index={index}
                  isDragDisabled={isSubSessionDraggable}
                >
                  {(provided, snapshot) => {
                    return (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <SubSession
                          key={item.id}
                          sessionIndex={index}
                          isAddNewSubSession={isAddNewSubSession}
                          setIsSubSessionDraggable={setIsSubSessionDraggable}
                          canvasRef={canvasRef}
                        />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={styles.plusWrapper}>
        <PlusBlueIcon className={styles.icon} onClick={onPlus} />
      </div>
    </div>
  );
};

export default NewSession;
