import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Category from '../Category';
import SaveCategoryModal from '../SaveCategoryModal';
import SubscribeModal from '../SubscribeModal';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus1.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';

import styles from './styles.module.scss';

type Props = {
  title: string;
  categories: string[];
  addFolder?: boolean;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
};

const CategoryBar: React.FC<Props> = ({ title, categories, addFolder = true, setPageNum }) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(title !== 'Shared by members' ? true : false);
  const [sort, setSort] = useState(true);
  const [open, setOpen] = useState(false);
  const { memberType, membershipState, setSelectedCategory, setFolderType, selectedCategory } = useContext(UserContext);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);

  const getIdeas = async (selected_category: string) => {
    if (title === 'Folders') {
      if (membershipState > 2 || memberType !== 'member') {
        setSelectedCategory(selected_category);
        setFolderType(title);
        if (selectedCategory !== selected_category) {
          setPageNum(1);
        }
      } else {
        setIsSubscribeModal(true);
      }
    }
    if (title === 'Shared Folders') {
      if (membershipState > 6 || memberType !== 'member') {
        setSelectedCategory(selected_category);
        setFolderType(title);
      } else {
        setIsSubscribeModal(true);
      }
    }
    if (title === 'Shared by members') {
      setSelectedCategory(selected_category);
      setFolderType(title);
    }
  };

  const onClickAddFolder = () => {
    if (title === 'Folders') {
      if (membershipState > 2 || memberType !== 'member') {
        setOpen(true);
      } else {
        setIsSubscribeModal(true);
      }
    } else {
      setOpen(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div
          className={styles.title}
          onClick={() => {
            setIsShow(prev => !prev);
          }}
        >
          {isShow ? <DownArrowIcon className={styles.icon} /> : <RightArrowIcon className={styles.icon} />}
          <p className={styles.text}>{t(title)}</p>
        </div>
        {addFolder && (
          <div
            className={styles.sort}
            onClick={() => {
              setSort(prev => !prev);
            }}
          >
            <SortIcon className={styles.icon} fill={'#1E7EEB'} />
            <p className={styles.label}>{t('Sort')}</p>
          </div>
        )}
      </div>
      {isShow && (
        <div className={styles.categories}>
          {categories
            .sort((a, b) => {
              return sort ? a.localeCompare(b) : b.localeCompare(a);
            })
            .map((item, index: number) => (
              <Category key={index} label={item} title={title} getIdeas={() => getIdeas(item)} />
            ))}
          {addFolder && (title === 'Folders' || (title === 'Shared Folders' && memberType !== 'member')) && (
            <button className={styles.addCategory} onClick={onClickAddFolder}>
              <PlusIcon className={styles.icon} />
              {t('Add folder')}
            </button>
          )}
        </div>
      )}
      <SaveCategoryModal open={open} setOpen={setOpen} categories={categories} categoryType={title} />
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={`Please upgrade your subscription plan to ${
          title === 'Folders' ? `'Premium' or ` : ''
        }'Pro' to unlock it.`}
      />
    </div>
  );
};

export default CategoryBar;
