import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';

import Button from '../Button';
import NotificationModal from '../NotificationModal';
import SaveModal from '../SaveModal';
import SubscribeModal from '../SubscribeModal';
import Alert from '../Alert';
import ImageUploadModal from '../ImageUploadModal';
import VideoUploadModal from '../VideoUploadModal';
import InputModal from '../InputModal';

import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus1.svg';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play-1.svg';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  getIdeasNames: any;
  canvasRef: React.RefObject<ExportObject>;
};

const Description: React.FC<Props> = ({ setOpen, getIdeasNames, canvasRef }) => {
  const { t } = useTranslation();
  const {
    selectedLang,
    sceneId,
    description,
    names,
    descriptionTitle,
    editorState,
    membershipState,
    memberType,
    customImage,
    customVideo,
    ideaType,
    selectedSessionIdeaID,
    setCustomImage,
    setCustomImageBlob,
    setCustomVideo,
    setDescriptionTitle,
    setDescription,
    setEditorState,
    setIsLoading,
  } = useContext(UserContext);
  const [isSavedScene, setIsSavedScene] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);

  const saveDescription = async () => {
    if (ideaType === 'idea') {
      if (memberType !== 'member' || (memberType === 'member' && membershipState > 3)) {
        if (sceneId < 0) {
          setIsSavedScene(true);
        } else {
          try {
            setIsLoading(true);
            const res = await axios.post(`${process.env.REACT_APP_MAIN_API}description/save/${sceneId}`, {
              title_EN: selectedLang === 'EN' ? descriptionTitle : description?.title_EN ?? '',
              title_FR: selectedLang === 'FR' ? descriptionTitle : description?.title_FR ?? '',
              title_DU: selectedLang === 'DU' ? descriptionTitle : description?.title_DU ?? '',
              title_GE: selectedLang === 'GE' ? descriptionTitle : description?.title_GE ?? '',
              EN: selectedLang === 'EN' ? editorState : description?.EN ?? '',
              FR: selectedLang === 'FR' ? editorState : description?.FR ?? '',
              DU: selectedLang === 'DU' ? editorState : description?.DU ?? '',
              GE: selectedLang === 'GE' ? editorState : description?.GE ?? '',
            });
            setEditorState('');
            setDescriptionTitle('');
            setDescription(res.data);
            setIsLoading(false);
            if (setOpen) {
              setOpen(false);
            }
          } catch (error) {
            setIsLoading(false);
            if (setOpen) {
              setOpen(false);
            }
            console.log('error: ', error);
          }
        }
      } else {
        setIsSubscribeModal(true);
      }
    } else if (ideaType === 'sessionIdea') {
      if (memberType !== 'member' || (memberType === 'member' && membershipState > 3)) {
        if (selectedSessionIdeaID < 0) {
          setIsSavedScene(true);
        } else {
          try {
            setIsLoading(true);
            const resDescription = await axios.post(
              `${process.env.REACT_APP_MAIN_API}session_idea_description/update-description/${description.id}`,
              {
                data: {
                  title_EN: selectedLang === 'EN' ? descriptionTitle : description?.title_EN ?? '',
                  title_FR: selectedLang === 'FR' ? descriptionTitle : description?.title_FR ?? '',
                  title_DU: selectedLang === 'DU' ? descriptionTitle : description?.title_DU ?? '',
                  title_GE: selectedLang === 'GE' ? descriptionTitle : description?.title_GE ?? '',
                  EN: selectedLang === 'EN' ? editorState : description?.EN ?? '',
                  FR: selectedLang === 'FR' ? editorState : description?.FR ?? '',
                  DU: selectedLang === 'DU' ? editorState : description?.DU ?? '',
                  GE: selectedLang === 'GE' ? editorState : description?.GE ?? '',
                },
              }
            );
            setDescription(resDescription.data);
            setEditorState('');
            setDescriptionTitle('');
            setIsLoading(false);
            if (setOpen) {
              setOpen(false);
            }
          } catch (error) {
            setIsLoading(false);
            if (setOpen) {
              setOpen(false);
            }
            console.log('error: ', error);
          }
        }
      } else {
        setIsSubscribeModal(true);
      }
    }
  };

  const openSubscribeModal = () => {
    setIsSavedScene(false);
    setIsSaveModal(true);
    canvasRef.current?.generateScreenObject();
  };

  const imageUpload = (file: any) => {
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = function () {
      if (fr.result !== null) {
        const blob = new Blob([fr.result]);
        setCustomImageBlob(blob);
        setCustomImage(URL.createObjectURL(blob));
      }
    };
  };

  useEffect(() => {
    if (!!description) {
      // setDescriptionTitle(
      //   description
      //     ? selectedLang === 'EN'
      //       ? description.title_EN
      //       : selectedLang === 'FR'
      //       ? description.title_FR
      //       : selectedLang === 'DU'
      //       ? description.title_DU
      //       : description.title_GE
      //     : ''
      // );
      setEditorState(description[selectedLang]);
    }
  }, [description, selectedLang]);

  return (
    <div className={`w-full ${styles.wrapper}`}>
      <div className={styles.titleWrapper}>
        <input
          type="text"
          placeholder={t('TITLE')}
          className={styles.title}
          // value={
          //   description
          //     ? selectedLang === 'EN'
          //       ? description.title_EN
          //       : selectedLang === 'FR'
          //       ? description.title_FR
          //       : selectedLang === 'DU'
          //       ? description.title_DU
          //       : description.title_GE
          //     : descriptionTitle
          // }
          value={descriptionTitle}
          onChange={e => {
            setDescriptionTitle(e.target.value);
          }}
        />
        {(customImage !== '' || customVideo !== '') && (
          <div className={classNames(styles.addImageButtons)}>
            {customImage !== '' && (
              <button className={classNames(styles.addImageBtn)}>
                <img
                  className={styles.image}
                  // src={tempImage === '' ? customImage : URL.createObjectURL(tempImage)}
                  src={customImage}
                  alt=""
                />
                {/* <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    id="customImage"
                    name="customImage"
                    className={styles.upload}
                    onChange={e => {
                      console.log('e: ', e);
                      if (
                        e.target.files !== null &&
                        (e.target.files[0].type === 'image/png' ||
                          e.target.files[0].type === 'image/gif' ||
                          e.target.files[0].type === 'image/jpeg')
                      ) {
                        imageUpload(e.target.files[0]);
                      } else {
                        setIsAlert(true);
                      }
                    }}
                  /> */}
                <div
                  className={styles.upload}
                  onClick={() => {
                    setShowImageUploadModal(true);
                  }}
                ></div>
              </button>
            )}
            {customVideo !== '' && (
              <button
                className={classNames(styles.addImageBtn)}
                onClick={() => {
                  setShowVideoUploadModal(true);
                }}
              >
                <div className={styles.imageWrapper}>
                  {customImage !== '' && <img className={styles.image} src={customImage} alt="" />}
                  <div className={styles.overlap}>
                    <PlayIcon fill={customImage === '' ? '#000000' : '#ffffff'} className={styles.icon} />
                  </div>
                </div>
                <div
                  className={styles.upload}
                  onClick={() => {
                    setShowVideoUploadModal(true);
                  }}
                ></div>
              </button>
            )}
          </div>
        )}
        {(customImage === '' || customVideo === '') && (
          <div className={classNames(styles.addButtons)}>
            {customImage === '' && (
              <button className={classNames(styles.addBtn)}>
                <PlusIcon fill="#666666" className={styles.icon} />
                {t('Add Image')}
                {/* <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    id="customImage"
                    name="customImage"
                    className={styles.upload}
                    onChange={e => {
                      console.log('e: ', e);
                      if (
                        e.target.files !== null &&
                        (e.target.files[0].type === 'image/png' ||
                          e.target.files[0].type === 'image/gif' ||
                          e.target.files[0].type === 'image/jpeg')
                      ) {
                        imageUpload(e.target.files[0]);
                      } else {
                        setIsAlert(true);
                      }
                    }}
                  /> */}
                <div
                  className={styles.upload}
                  onClick={() => {
                    setShowImageUploadModal(true);
                  }}
                ></div>
              </button>
            )}
            {customVideo === '' && (
              <button
                className={classNames(styles.addBtn)}
                onClick={() => {
                  setShowVideoUploadModal(true);
                }}
              >
                <PlusIcon fill="#666666" className={styles.icon} />
                {t('Add Video')}
              </button>
            )}
          </div>
        )}
      </div>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onEditorChange={(evt: any) => {
          setEditorState(evt);
        }}
        // initialValue={description !== undefined && description !== null ? description[selectedLang] : editorState}
        value={editorState}
        init={{
          entity_encoding: 'raw',
          // height: 100,
          min_height: 200,
          menubar: 'format',
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | fontsize | format' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
          style_formats: [
            // { title: 'Bold text', inline: 'b' },
            // { title: 'Red text', inline: 'span', styles: { color: '#ff0000' } },
            // { name: 'my-inline', title: 'My inline', inline: 'span', classes: ['my-inline'] },
            { title: 'Bold text', inline: 'strong' },
            {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '1px solid #0787ea',
                'border-radius': '5px',
                padding: '0px 5px',
                margin: '0 2px',
                color: '#0787ea',
              },
            },
            {
              title: 'Badge2',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '1px solid #333e48',
                'border-radius': '5px',
                padding: '2px 5px',
                margin: '0 2px',
                color: '#333e48',
              },
            },
            {
              title: 'Badge3',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '1px solid #FFFFFF',
                'border-radius': '5px',
                padding: '2px 5px',
                margin: '0 2px',
                background: '#333e48',
                color: '#FFFFFF',
              },
            },
            {
              title: 'Badge4',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '1px solid #FFFFFF',
                'border-radius': '5px',
                padding: '2px 5px',
                margin: '0 2px',
                background: '#0787ea',
                color: '#FFFFFF',
              },
            },
          ],
          content_style: ' p { line-height: 1;} body { font-family:Helvetica,Arial,sans-serif; font-size: 14px } ',
        }}
      />
      {ideaType === 'idea' && <Button label="Save" onClick={saveDescription} />}
      <NotificationModal
        open={isSavedScene}
        setOpen={setIsSavedScene}
        action={openSubscribeModal}
        actionButtonLabel="Save Idea"
        description="If you save the description, you should save the current ideas"
      />
      <SaveModal open={isSaveModal} setOpen={setIsSaveModal} names={names} getIdeasNames={getIdeasNames} />
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={"Please upgrade your subscription plan to 'Premium' or 'Pro' to unlock it."}
      />
      {/*<InputModal
        open={showVideoUploadModal}
        setOpen={setShowVideoUploadModal}
        videoLink={customVideo}
        setVideoLink={setCustomVideo}
      />*/}
      <VideoUploadModal open={showVideoUploadModal} setOpen={setShowVideoUploadModal} />
      <ImageUploadModal open={showImageUploadModal} setOpen={setShowImageUploadModal} uploadImage={imageUpload} />
      <Alert isShow={isAlert} setIsShow={setIsAlert} content={'This file isn`t image file'} />
    </div>
  );
};

export default Description;
