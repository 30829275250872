import React from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type PaginationProps = {
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  currentPage?: number;
  totalLength?: number;
  limit?: number;
  disabled?: boolean;
};

const Pagination: React.FC<PaginationProps> = ({
  setCurrentPage = () => {},
  currentPage = 1,
  totalLength = 0,
  limit = 10,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const items = [...Array(totalLength).keys()];
  const pageCount = Math.ceil(items.length / limit);

  const handlePageClick = (event: any) => {
    if (!disabled) {
      setCurrentPage(event.selected + 1);
    }
  };

  return totalLength ? (
    <div className={styles.wrapper}>
      <div className={styles.resultWrapper}>
        <p className={styles.result}>
          {t('Showing')} <span className={styles.text}>{(currentPage - 1) * limit + 1}</span> {t('to')}{' '}
          <span className={styles.text}>{currentPage * limit > totalLength ? totalLength : currentPage * limit}</span>{' '}
          {t('of')} <span className={styles.text}>{totalLength}</span> {t('results')}
        </p>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        // nextLabel={
        //   <div className={styles.iconWrapper}>
        //     <RightArrowIcon className={styles.icon} />
        //   </div>
        // }
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        forcePage={currentPage - 1}
        previousLabel="<"
        // previousLabel={
        //   <div className={styles.iconWrapper}>
        //     <LeftArrowIcon className={styles.icon} />
        //   </div>
        // }
        containerClassName={styles.content}
        pageClassName={styles.page}
        activeClassName={styles.selected}
        pageLinkClassName={styles.pageLink}
        previousLinkClassName={styles.pageLink}
        nextLinkClassName={styles.pageLink}
        breakClassName={styles.pageLink}
      />
    </div>
  ) : null;
};

export default Pagination;
