import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import SaveModal from '../SaveModal';
import SubscribeModal from '../SubscribeModal';
import NotificationModal from '../NotificationModal';
import ShareModal from '../ShareModal';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as ListIcon } from '../../../assets/icons/menu.svg';
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as NewIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { ReactComponent as CloseFolder } from '../../../assets/icons/folder-close.svg';

import styles from './styles.module.scss';
import SaveSessionModal from '../SaveSessionModal';
import Button from '../Button';

const menuItem = ['IDEAS', 'SESSIONS'];

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  canvasRef: React.RefObject<ExportObject>;
};

const MenuDropdown: React.FC<Props> = ({ canvasRef }) => {
  const { t } = useTranslation();
  const {
    userId,
    membershipState,
    names,
    sceneCharacters,
    sceneSportsObjects,
    sportsFieldObject,
    isSavedState,
    memberType,
    selectedMenuTitle,
    ideaType,
    characterGroup,
    objectGroup,
    isField,
    characterMaterials,
    characterRotationInfo,
    sportsObjectRotationInfo,
    currentImageBlob,
    currentImage,
    customImageBlob,
    customVideo,
    customVideoBlob,
    selectedLang,
    description,
    descriptionTitle,
    editorState,
    customImage,
    selectedSessionCategory,
    selectedSessionIdeaID,
    newSession,
    modifySessionId,
    userInfo,
    sessionFolderType,
    setOpenFolderModal,
    setNames,
    setSceneCharacters,
    setSceneSportsObjects,
    setSceneId,
    setCharacterRotationInfo,
    setSportsObjectRotationInfo,
    setSportsFieldObject,
    setIsField,
    setCharacterMaterials,
    setDescription,
    setIsCustomImage,
    setIsSubSportsField,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    setDescriptionTitle,
    setEditorState,
    setCustomImage,
    setCustomVideo,
    setIsModify,
    setIsLoading,
    setOpenSessionsModal,
    setKeyWord,
    setIdeaType,
    setIsSavedState,
    setCurrentImage,
    setSelectedSessionIdeaID,
    setNewSession,
    setModifySessionId,
    setOpenIdeasModal,
    setSessionID,
    setSubSessionIds,
    setIsNewSession,
    setIsResetIdeas,
    setCurrentImageBlob,
    setCustomImageBlob,
    setCustomVideoBlob,
    setIsFileUploading,
  } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);
  const [isNotificationModal, setIsNotificationModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [isShareModal, setIsShareModal] = useState(false);
  const [openSaveSessionNotificationModal, setOpenSaveSessionNotificationModal] = useState(false);
  const [openSaveSessionModal, setOpenSaveSessionModal] = useState(false);
  const [notificationText, setNotificationText] = useState('');
  const [isHoverNewButton, setIsHoverNewButton] = useState(false);
  const [isHoverSaveButton, setIsHoverSaveButton] = useState(false);

  const getIdeasNames = async () => {
    try {
      // setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_MAIN_API}scene-data/names/${userId}`);
      const tempArray: any[] = [];
      res.data?.map((item: any) => {
        // setNames(prev => [...prev, item.name]);
        tempArray.push(item.name);
      });
      setNames(tempArray);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log('error: ', error);
    }
  };

  const clearScene = () => {
    setSceneCharacters([]);
    setSceneSportsObjects([]);
    setSceneId(-1);
    setCharacterRotationInfo([]);
    setSportsObjectRotationInfo([]);
    setSportsFieldObject({
      object: '',
    });
    setIsField(false);
    setCharacterMaterials([]);
    setDescription(null);
    setIsCustomImage(false);
    setIsSubSportsField(false);
    setIsObjectColor(false);
    setIsObjectColorTwo(false);
    setIsObjectColorThree(false);
    setDescriptionTitle('');
    setEditorState('');
    setCustomImage('');
    setCustomVideo('');
    setCurrentImageBlob('');
    setCustomImageBlob('');
    setCustomVideoBlob('');
    setCurrentImage('');
    setSelectedSessionIdeaID(-1);
  };

  const onMenuItem = (item: string) => {
    setSelectedLabel(item);
    setIsNewSession(false);
    setOpen(false);
    if (item === 'IDEAS') {
      if (membershipState > 2 || memberType !== 'member') {
        setIsModify(false);
        if (selectedMenuTitle !== item) {
          setKeyWord('');
        }
        setIsResetIdeas(false);
        setOpenFolderModal(true);
        setOpenSessionsModal(false);
      } else {
        setIsSubscribeModal(true);
      }
    } else if (item === 'SESSIONS') {
      if (membershipState > 2 || memberType !== 'member') {
        setIsModify(false);
        if (selectedMenuTitle !== item) {
          setKeyWord('');
        }
        setOpenSessionsModal(true);
        setOpenFolderModal(false);
      } else {
        setIsSubscribeModal(true);
      }
    }
  };

  const onNewIdea = () => {
    setSelectedLabel('New Idea');
    setIdeaType('idea');
    if (ideaType === 'idea') {
      if (membershipState > 3 || memberType !== 'member') {
        if (
          (sceneCharacters.length !== 0 || sceneSportsObjects.length !== 0 || sportsFieldObject.object !== '') &&
          isSavedState
        ) {
          setIsNotificationModal(true);
        } else {
          setIsModify(true);
          clearScene();
        }
      } else {
        setIsModify(true);
        clearScene();
      }
    } else if (ideaType === 'sessionIdea') {
      setIsModify(true);
      clearScene();
    }
  };

  const onBackToSession = () => {
    setIsSavedState(false);
    setSelectedSessionIdeaID(-1);
    setOpenSessionsModal(true);
    setTimeout(() => {
      setOpenIdeasModal(true);
    }, 100);
    setOpen(false);
  };

  const onSaveIdea = async () => {
    setSelectedLabel('Save Idea');
    if (ideaType === 'idea') {
      if (membershipState > 3 || memberType !== 'member') {
        canvasRef.current?.generateScreenObject();
        setIsSaveModal(true);
      } else {
        setIsSubscribeModal(true);
      }
    } else if (ideaType === 'sessionIdea') {
      const characterString = JSON.stringify(sceneCharacters);
      const objectString = JSON.stringify(sceneSportsObjects);
      const originalCharacterLength = characterRotationInfo.length;
      const originalSportsObjectLength = sportsObjectRotationInfo.length;

      const characterTransformInfo: any[] = [];
      const objectTransformInfo: any[] = [];

      characterGroup?.current?.children.map((item: any, index: number) => {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].type === 'Group') {
            characterTransformInfo.push({
              position: [item?.children[i]?.position.x, item?.children[i]?.position.y, item?.children[i]?.position.z],
              scale: [item?.children[i]?.scale.x, item?.children[i]?.scale.y, item?.children[i]?.scale.z],
              rotation:
                item?.children[i]?.rotation._x === 0 &&
                item?.children[i]?.rotation._y === 0 &&
                item?.children[i]?.rotation._z === 0 &&
                index < originalCharacterLength
                  ? characterRotationInfo[index]
                  : [item?.children[i]?.rotation._x, item?.children[i]?.rotation._y, item?.children[i]?.rotation._z],
            });
            break;
          }
        }
      });

      objectGroup?.current?.children.map((item: any, index: number) => {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].type === 'Group') {
            objectTransformInfo.push({
              position: [item?.children[i]?.position.x, item?.children[i]?.position.y, item?.children[i]?.position.z],
              scale: [item?.children[i]?.scale.x, item?.children[i]?.scale.y, item?.children[i]?.scale.z],
              rotation:
                item?.children[i]?.rotation._x === 0 &&
                item?.children[i]?.rotation._y === 0 &&
                item?.children[i]?.rotation._z === 0 &&
                index < originalSportsObjectLength
                  ? sportsObjectRotationInfo[index]
                  : [item?.children[i]?.rotation._x, item?.children[i]?.rotation._y, item?.children[i]?.rotation._z],
            });
            break;
          }
        }
      });

      try {
        setIsLoading(true);
        const customImageFileName = `id-sport_${userId}_${selectedSessionCategory}_${selectedSessionIdeaID}_${Date.now()}_custom_image.jpg`;
        const currentImageFileName = `id-sport_${userId}_${selectedSessionCategory}_${selectedSessionIdeaID}_${Date.now()}_current_image.jpg`;
        const customVideoFileName = `id-sport_${userId}_${selectedSessionCategory}_${selectedSessionIdeaID}_${Date.now()}_custom_video.mp4`;

        const customFormData = new FormData();
        customFormData.append('file', customImageBlob);

        const currentFormData = new FormData();
        currentFormData.append('file', currentImageBlob);

        const customVideoFormData = new FormData();
        customVideoFormData.append('file', customVideoBlob);

        if (currentImageBlob !== '' || customImageBlob !== '' || customVideoBlob !== '') {
          setIsFileUploading(true);
        }

        let currentUploadedImage: any = '';
        if (currentImageBlob !== '') {
          currentUploadedImage = await axios.post(
            `${process.env.REACT_APP_MAIN_API}scene-data/upload-image/images/${currentImageFileName}`,
            currentFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }

        let customUploadedImage: any = '';
        if (customImageBlob !== '') {
          customUploadedImage = await axios.post(
            `${process.env.REACT_APP_MAIN_API}scene-data/upload-image/images/${customImageFileName}`,
            customFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }

        let customUploadedVideo: any = '';
        if (customVideoBlob !== '') {
          customUploadedVideo = await axios.post(
            `${process.env.REACT_APP_MAIN_API}scene-data/upload-image/video/${customVideoFileName}`,
            customVideoFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }

        setIsFileUploading(false);

        const res = await axios.post(
          `${process.env.REACT_APP_MAIN_API}session_idea/update-idea/${selectedSessionIdeaID}`,
          {
            data: {
              name: descriptionTitle,
              character: characterString,
              character_transform: JSON.stringify(characterTransformInfo),
              character_material: JSON.stringify(characterMaterials),
              object: objectString,
              object_transform: JSON.stringify(objectTransformInfo),
              field: JSON.stringify({ isField, sportsFieldObject }),
              image: customImageBlob === '' ? customImage : customUploadedImage.data.file,
              custom_image: currentImageBlob === '' ? currentImage : currentUploadedImage.data.file,
              custom_video: customVideoBlob === '' ? customVideo : customUploadedVideo.data.file,
            },
          }
        );

        const resDescription = await axios.post(
          `${process.env.REACT_APP_MAIN_API}session_idea_description/update-description/${description.id}`,
          {
            data: {
              title_EN: selectedLang === 'EN' ? descriptionTitle : description?.title_EN ?? '',
              title_FR: selectedLang === 'FR' ? descriptionTitle : description?.title_FR ?? '',
              title_DU: selectedLang === 'DU' ? descriptionTitle : description?.title_DU ?? '',
              title_GE: selectedLang === 'GE' ? descriptionTitle : description?.title_GE ?? '',
              EN: selectedLang === 'EN' ? editorState : description?.EN ?? '',
              FR: selectedLang === 'FR' ? editorState : description?.FR ?? '',
              DU: selectedLang === 'DU' ? editorState : description?.DU ?? '',
              GE: selectedLang === 'GE' ? editorState : description?.GE ?? '',
            },
          }
        );
        setDescription(resDescription.data);

        setCurrentImage(res.data.image);
        setIsCustomImage(res.data.isCustomImage);
        setIsSavedState(false);
        setSelectedSessionIdeaID(-1);
        setIsLoading(false);
        setOpenSessionsModal(true);
        setTimeout(() => {
          setOpenIdeasModal(true);
        }, 100);
        setOpen(false);
      } catch (error) {
        setIsLoading(false);
        console.log('error: ', error);
      }
    }
  };

  const onShareIdea = () => {
    setSelectedLabel('Share Ideas');
    if (membershipState > 6 || memberType !== 'member') {
      if (
        (sceneCharacters.length !== 0 || sceneSportsObjects.length !== 0 || sportsFieldObject.object !== '') &&
        isSavedState
      ) {
        setIsNotificationModal(true);
      } else {
        setIsShareModal(true);
      }
    } else {
      setIsSubscribeModal(true);
    }
  };

  const onNewSession = () => {
    setModifySessionId(-1);
    setSelectedLabel('New Session');
    setNewSession({
      title_EN: '',
      title_DU: '',
      title_FR: '',
      title_GE: '',
      text_EN: '',
      text_DU: '',
      text_FR: '',
      text_GE: '',
      category_EN: '',
      category_DU: '',
      category_FR: '',
      category_GE: '',
      custom_category_EN: '',
      custom_category_DU: '',
      custom_category_FR: '',
      custom_category_GE: '',
      approval: false,
      isShared: false,
      rejected: false,
      subSessions: [
        {
          id: 0,
          session: -1,
          title_EN: '',
          title_DU: '',
          title_FR: '',
          title_GE: '',
          text_EN: '',
          text_DU: '',
          text_FR: '',
          text_GE: '',
          repeat_number: [0],
          execution_time: [''],
          ideas: [
            {
              id: 0,
              idea: -1,
            },
          ],
        },
      ],
    });
  };

  const onSaveSession = async () => {
    setSelectedLabel('Save Session');
    if (newSession[`title_${selectedLang}`] === '') {
      setOpenSaveSessionNotificationModal(true);
      setNotificationText('Please input the new session title');
    } else if (newSession.subSessions[0][`title_${selectedLang}`] === '') {
      setOpenSaveSessionNotificationModal(true);
      setNotificationText('Please input the subTitle');
    } else if (newSession.subSessions[0].ideas[0].idea === -1) {
      setOpenSaveSessionNotificationModal(true);
      setNotificationText('Please add ideas');
    } else {
      if (
        modifySessionId === -1 ||
        (modifySessionId !== -1 && sessionFolderType === 'Shared Folders' && memberType === 'member')
      ) {
        setOpenSaveSessionModal(true);
      } else {
        try {
          setIsLoading(true);
          const tempNewSession = { ...newSession };

          for (let i = tempNewSession.subSessions.length - 1; i >= 0; i--) {
            if (
              tempNewSession.subSessions[i][`title_${selectedLang}`] === '' ||
              tempNewSession.subSessions[i].ideas[0].idea === -1
            ) {
              tempNewSession.subSessions.splice(i, 1);
            }
          }

          const subSessionIDArray = [];

          for await (const subSession of tempNewSession.subSessions) {
            const ideaIDArray = [];
            for (let i = 0; i < subSession.ideas.length; i++) {
              if (subSession.ideas[i].idea !== -1) {
                ideaIDArray.push(subSession.ideas[i].idea);
              }
            }
            const resIdeasCount = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/create-ideas`, {
              ids: ideaIDArray,
            });

            const resIdeas = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/lastRecords`, {
              count: resIdeasCount.data.count,
            });

            const resIdeasDescription = await axios.post(
              `${process.env.REACT_APP_MAIN_API}session_idea_description/create-descriptions`,
              {
                ids: ideaIDArray,
                sids: resIdeas.data.data,
              }
            );

            const tempTime: { [key: string]: any } = {};
            const tempNumber: { [key: string]: any } = {};
            for (let j = 0; j < resIdeas.data.data.length; j++) {
              tempTime[`idea_${resIdeas.data.data[j]}`] = subSession.execution_time[j];
              tempNumber[`idea_${resIdeas.data.data[j]}`] = subSession.repeat_number[j];
            }

            const resSub = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/create_sub_session`, {
              data: {
                title_EN: subSession.title_EN === '' ? subSession[`title_${selectedLang}`] : subSession.title_EN,
                title_FR: subSession.title_FR === '' ? subSession[`title_${selectedLang}`] : subSession.title_FR,
                title_DU: subSession.title_DU === '' ? subSession[`title_${selectedLang}`] : subSession.title_DU,
                title_GE: subSession.title_GE === '' ? subSession[`title_${selectedLang}`] : subSession.title_GE,
                text_EN: subSession.title_EN === '' ? subSession[`title_${selectedLang}`] : subSession.text_EN,
                text_FR: subSession.title_FR === '' ? subSession[`title_${selectedLang}`] : subSession.text_FR,
                text_DU: subSession.title_DU === '' ? subSession[`title_${selectedLang}`] : subSession.text_DU,
                text_GE: subSession.title_GE === '' ? subSession[`title_${selectedLang}`] : subSession.text_GE,
                ideas: JSON.stringify(resIdeas.data.data),
                execution_time: JSON.stringify(tempTime),
                repeat_number: JSON.stringify(tempNumber),
              },
            });

            subSessionIDArray.push(resSub.data.id);
          }

          // let res: AxiosResponse<any, any>;
          // if (sessionFolderType === 'Shared Folders' && memberType === 'member') {
          //   res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/create-session/-1`, {
          //     data: {
          //       uid: userId,
          //       author: userInfo.fname,
          //       title_EN:
          //         tempNewSession.title_EN === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_EN,
          //       title_FR:
          //         tempNewSession.title_FR === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_FR,
          //       title_DU:
          //         tempNewSession.title_DU === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_DU,
          //       title_GE:
          //         tempNewSession.title_GE === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_GE,
          //       text_EN:
          //         tempNewSession.text_EN === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_EN,
          //       text_FR:
          //         tempNewSession.text_FR === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_FR,
          //       text_DU:
          //         tempNewSession.text_DU === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_DU,
          //       text_GE:
          //         tempNewSession.text_GE === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_GE,
          //       category_EN: '',
          //       category_FR: '',
          //       category_DU: '',
          //       category_GE: '',
          //       custom_category_EN: tempNewSession.custom_category_EN,
          //       custom_category_FR: tempNewSession.custom_category_FR,
          //       custom_category_DU: tempNewSession.custom_category_DU,
          //       custom_category_GE: tempNewSession.custom_category_GE,
          //       sub_data: JSON.stringify(subSessionIDArray.sort((a, b) => a - b)),
          //       approval: false,
          //       isShared: false,
          //       rejected: false,
          //     },
          //   });
          // } else {
          const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/create-session/${modifySessionId}`, {
            data: {
              title_EN:
                tempNewSession.title_EN === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_EN,
              title_FR:
                tempNewSession.title_FR === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_FR,
              title_DU:
                tempNewSession.title_DU === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_DU,
              title_GE:
                tempNewSession.title_GE === '' ? tempNewSession[`title_${selectedLang}`] : tempNewSession.title_GE,
              text_EN: tempNewSession.text_EN === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_EN,
              text_FR: tempNewSession.text_FR === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_FR,
              text_DU: tempNewSession.text_DU === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_DU,
              text_GE: tempNewSession.text_GE === '' ? tempNewSession[`text_${selectedLang}`] : tempNewSession.text_GE,
              category_EN: tempNewSession.category_EN,
              category_FR: tempNewSession.category_FR,
              category_DU: tempNewSession.category_DU,
              category_GE: tempNewSession.category_GE,
              custom_category_EN: tempNewSession.custom_category_EN,
              custom_category_FR: tempNewSession.custom_category_FR,
              custom_category_DU: tempNewSession.custom_category_DU,
              custom_category_GE: tempNewSession.custom_category_GE,
              sub_data: JSON.stringify(subSessionIDArray.sort((a, b) => a - b)),
              approval: tempNewSession.approval,
              isShared: tempNewSession.isShared,
              rejected: tempNewSession.rejected,
            },
          });
          // }

          setSessionID(res.data.id);
          setSubSessionIds(subSessionIDArray.sort((a, b) => a - b));
          setOpenSessionsModal(true);
          setTimeout(() => {
            setOpenIdeasModal(true);
          }, 100);
          setNewSession({
            title_EN: '',
            title_DU: '',
            title_FR: '',
            title_GE: '',
            text_EN: '',
            text_DU: '',
            text_FR: '',
            text_GE: '',
            category_EN: '',
            category_DU: '',
            category_FR: '',
            category_GE: '',
            custom_category_EN: '',
            custom_category_DU: '',
            custom_category_FR: '',
            custom_category_GE: '',
            approval: false,
            isShared: false,
            rejected: false,
            subSessions: [
              {
                id: 0,
                session: -1,
                title_EN: '',
                title_DU: '',
                title_FR: '',
                title_GE: '',
                text_EN: '',
                text_DU: '',
                text_FR: '',
                text_GE: '',
                repeat_number: [0],
                execution_time: [''],
                ideas: [
                  {
                    id: 0,
                    idea: -1,
                  },
                ],
              },
            ],
          });
          setModifySessionId(-1);
          setOpen(false);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log('error: ', error);
        }
      }
    }
  };

  const openSubscribeModal = () => {
    setIsNotificationModal(false);
    setIsSaveModal(true);
    canvasRef.current?.generateScreenObject();
  };

  const cancelButtonEvent = () => {
    if (selectedLabel === 'New Idea') {
      clearScene();
    }

    if (selectedLabel === 'Share Ideas') {
      setIsNotificationModal(false);
    }

    setIsNotificationModal(false);
  };

  useEffect(() => {
    if (userId !== -1) {
      getIdeasNames();
    }
  }, [userId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <ListIcon
            onClick={() => {
              setOpen(prev => !prev);
            }}
            className={classNames(styles.listIcon, {
              [styles.select]: open,
            })}
          />
          {open && (
            <>
              <div
                className={styles.background}
                onClick={() => {
                  setOpen(false);
                }}
              ></div>
              <div className={styles.mainField}>
                {menuItem.map((item, index: number) => (
                  <button key={index} className={styles.button} onClick={() => onMenuItem(item)}>
                    {t(item)}
                  </button>
                ))}
                {selectedMenuTitle === 'IDEAS' ? (
                  <>
                    {ideaType === 'sessionIdea' && (
                      <button className={styles.button} onClick={onBackToSession}>
                        <BackIcon className={styles.icon} />
                        {t('Back To Session')}
                      </button>
                    )}
                    <button className={styles.button} onClick={onNewIdea}>
                      <NewIcon className={styles.icon} />
                      {t('New Idea')}
                    </button>
                    <button className={styles.button} onClick={onSaveIdea}>
                      <SaveIcon className={styles.icon} />
                      {t('Save Idea')}
                    </button>
                    {/**{ideaType === 'idea' && (
                      <button className={styles.button} onClick={onShareIdea}>
                        <ShareIcon className={styles.icon} />
                        {t('Share Ideas')}
                      </button>
                    )} */}
                  </>
                ) : (
                  <>
                    <button className={styles.button} onClick={onNewSession}>
                      <NewIcon className={styles.icon} />
                      {t('New Session')}
                    </button>
                    <button className={styles.button} onClick={onSaveSession}>
                      <SaveIcon className={styles.icon} />
                      {t('Save Session')}
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.menuWrapper}>
        <div className={styles.menu}>
          {menuItem.map((item, index: number) => (
            <button key={index} className={styles.menuItem} onClick={() => onMenuItem(item)}>
              <CloseFolder className={styles.folderIcon} />
              {t(item)}
            </button>
          ))}
        </div>
        <div className={styles.menu}>
          {selectedMenuTitle === 'IDEAS' ? (
            <>
              {/**{ideaType === 'sessionIdea' && (
                <button className={styles.ideaItem} onClick={onBackToSession}>
                  <BackIcon className={styles.icon} />
                  {t('Back To Session')}
                </button>
              )} */}
              {ideaType === 'sessionIdea' && (
                <button className={classNames(styles.ideaItem, styles.save)} onClick={onBackToSession}>
                  {t('Back To Session')}
                </button>
              )}
              {/*<Button label="New Idea" type="button" colorType="third" onClick={onNewIdea} />
              <Button label="Save Idea" type="button" colorType="forth" onClick={onSaveIdea} />*/}
              <button
                className={classNames(styles.ideaItem, styles.new)}
                onMouseEnter={() => setIsHoverNewButton(true)}
                onMouseLeave={() => setIsHoverNewButton(false)}
                onClick={onNewIdea}
              >
                <NewIcon fill={isHoverNewButton ? '#fff' : '#000'} className={styles.icon} />
                {t('New Idea')}
              </button>
              <button
                className={classNames(styles.ideaItem, styles.save)}
                onMouseEnter={() => setIsHoverSaveButton(true)}
                onMouseLeave={() => setIsHoverSaveButton(false)}
                onClick={onSaveIdea}
              >
                <SaveIcon fill={isHoverSaveButton ? '#fff' : '#000'} className={styles.icon} />
                {t('Save Idea')}
              </button>
              {/**{ideaType === 'idea' && (
                <button className={styles.ideaItem} onClick={onShareIdea}>
                  <ShareIcon className={styles.icon} />
                  {t('Share Ideas')}
                </button>
              )} */}
            </>
          ) : (
            <>
              <button
                className={classNames(styles.ideaItem, styles.new)}
                onMouseEnter={() => setIsHoverNewButton(true)}
                onMouseLeave={() => setIsHoverNewButton(false)}
                onClick={onNewSession}
              >
                <NewIcon fill={isHoverNewButton ? '#fff' : '#000'} className={styles.icon} />
                {t('New Session')}
              </button>
              <button
                className={classNames(styles.ideaItem, styles.save)}
                onMouseEnter={() => setIsHoverSaveButton(true)}
                onMouseLeave={() => setIsHoverSaveButton(false)}
                onClick={onSaveSession}
              >
                <SaveIcon fill={isHoverSaveButton ? '#fff' : '#000'} className={styles.icon} />
                {t('Save Session')}
              </button>
            </>
          )}
        </div>
      </div>
      <SaveModal open={isSaveModal} setOpen={setIsSaveModal} names={names} getIdeasNames={getIdeasNames} />
      <ShareModal open={isShareModal} setOpen={setIsShareModal} />
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={`Please upgrade your subscription plan to ${
          selectedLabel === 'Save Idea' ||
          selectedLabel === 'IDEAS' ||
          selectedLabel === 'Save Session' ||
          selectedLabel === 'SESSION'
            ? `'Premium' or `
            : ''
        }'Pro' to unlock it.`}
      />
      <NotificationModal
        open={isNotificationModal}
        setOpen={setIsNotificationModal}
        action={openSubscribeModal}
        cancelButtonEvent={cancelButtonEvent}
        actionButtonLabel="Save"
        description={
          selectedLabel === 'New Idea' || selectedLabel === 'New Session'
            ? 'Do you want to save before ? Otherwise your work will be deleted'
            : 'If you share idea, first you should save idea'
        }
      />
      <SaveSessionModal open={openSaveSessionModal} setOpen={setOpenSaveSessionModal} />
      <NotificationModal
        open={openSaveSessionNotificationModal}
        setOpen={setOpenSaveSessionNotificationModal}
        action={() => {
          setOpenSaveSessionNotificationModal(false);
        }}
        actionButtonLabel="OK"
        cancelButtonLabel="Cancel"
        description={notificationText}
      />
      {/* <FoldersModal open={openFolderModal} setOpen={setOpenFolderModal} canvasRef={canvasRef} /> */}
    </div>
  );
};

export default MenuDropdown;
