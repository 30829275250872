import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  id: number;
  label: string;
  parameter: string;
  defaultValue: number;
  changeValue: any;
  step: number;
  min: number;
  max: number;
};

const CustomSlider: React.FC<Props> = ({ id, label, parameter, defaultValue, changeValue, step, min, max }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.Wrapper}>
      <span className={styles.label}>{t(label)}</span>
      <Slider
        className={styles.slider}
        step={step}
        min={min}
        max={max}
        value={defaultValue}
        onChange={e => {
          changeValue(id, { [parameter]: e });
        }}
      />
    </div>
  );
};

export default CustomSlider;
