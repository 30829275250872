import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/folder-close.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

import styles from './styles.module.scss';

const Filter: React.FC = () => {
  const { t } = useTranslation();
  const { categories, customCategories, memberType, membershipState, setSelectedCategory, setFolderType } =
    useContext(UserContext);
  const [open, setOpen] = useState(false);

  const onAction = (e: any) => {
    e.stopPropagation();
    setOpen(prev => !prev);
  };

  const onClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onFilter = async (e: any, category: string, folder: string) => {
    e.stopPropagation();
    setFolderType(folder);
    setSelectedCategory(category);
    setOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <FilterIcon
            onClick={(e: any) => {
              onAction(e);
            }}
            className={styles.filterIcon}
          />
          {open && (
            <>
              <div className={styles.arrowDiv} />
              <div className={classNames(styles.arrowDiv, styles.hidden)} />
              <div
                className={styles.background}
                onClick={(e: any) => {
                  onClose(e);
                }}
              ></div>
              <div className={styles.mainField}>
                <button
                  className={styles.button}
                  onClick={(e: any) => {
                    onFilter(e, '', 'All');
                  }}
                >
                  <SearchIcon className={styles.icon} />
                  {t('All Folders')}
                </button>
                <hr className={styles.line} />
                <>
                  {customCategories && (
                    <>
                      {customCategories.map((item, index: number) => (
                        <button
                          key={index}
                          className={styles.button}
                          onClick={(e: any) => {
                            onFilter(e, item, 'Folders');
                          }}
                        >
                          <FolderIcon className={styles.icon} />
                          {item}
                        </button>
                      ))}
                      <hr className={styles.line} />
                    </>
                  )}
                  {categories &&
                    (membershipState > 6 || memberType !== 'member') &&
                    categories.map((item, index: number) => (
                      <button
                        key={index}
                        className={styles.button}
                        onClick={(e: any) => {
                          onFilter(e, item, 'Shared Folders');
                        }}
                      >
                        <FolderIcon className={styles.icon} />
                        {item}
                      </button>
                    ))}
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
