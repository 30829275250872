import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import axios from 'axios';

import Modal from '../Modal';
import SessionAction from '../SessionAction';
import SessionFolderCategoryBar from '../SessionFolderCategoryBar';
import Pagination from '../Pagination';
import SortSessions from '../SortSessions';
import SessionIdeasModal from '../SessionIdeasModal';
import SessionFilter from '../SessionFilter';

import { UserContext } from '../../../providers/UserProvider';
import { convertTimezone } from '../../../utils/convertTimezone';
import useDebounce from '../../../hooks/useDebounce';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus1.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/report-file.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  canvasRef: React.RefObject<ExportObject>;
};

const SessionsModal: React.FC<Props> = ({ open, setOpen, canvasRef }) => {
  const { t } = useTranslation();

  const {
    userId,
    sessionFolderType,
    selectedSessionCategory,
    sessionSortType,
    sessionKeyWord,
    memberType,
    membershipState,
    selectedLang,
    sessions,
    sessionID,
    searchSessionId,
    openIdeasModal,
    setIsSearch,
    setIsLoading,
    setSessions,
    setSelectedMenuTitle,
    setSessionDetail,
    setSessionID,
    setIdeaType,
    setNewSession,
    setModifySessionId,
    setOpenIdeasModal,
    setSubSessionIds,
    setSearchSessionId,
    setSessionKeyWord,
    setSelectedSessionIdeaID,
  } = useContext(UserContext);

  const [pageNum, setPageNum] = useState(1);
  const [totalNum, setTotalNum] = useState(0);
  const [limit, setLimit] = useState(25);
  const [searchKeyWord, setSearchKeyWord] = useState(sessionKeyWord);
  const searchName = useDebounce(searchKeyWord, 1000);
  const [tempSearchSessionId, setTempSearchSessionId] = useState(searchSessionId);
  const sessionSearchId = useDebounce(tempSearchSessionId, 1000);

  useEffect(() => {
    setSessionKeyWord(searchName);
    setSearchSessionId('');
  }, [searchName]);

  useEffect(() => {
    setSearchSessionId(sessionSearchId);
  }, [sessionSearchId]);

  useEffect(() => {
    setTempSearchSessionId(searchSessionId);
  }, [searchSessionId]);

  const selectNewSession = () => {
    setModifySessionId(-1);
    setSelectedMenuTitle('Sessions');
    setIdeaType('sessionIdea');
    setNewSession({
      title_EN: '',
      title_DU: '',
      title_FR: '',
      title_GE: '',
      text_EN: '',
      text_DU: '',
      text_FR: '',
      text_GE: '',
      category_EN: '',
      category_DU: '',
      category_FR: '',
      category_GE: '',
      custom_category_EN: '',
      custom_category_DU: '',
      custom_category_FR: '',
      custom_category_GE: '',
      approval: false,
      isShared: false,
      rejected: false,
      subSessions: [
        {
          id: 0,
          session: -1,
          title_EN: '',
          title_DU: '',
          title_FR: '',
          title_GE: '',
          text_EN: '',
          text_DU: '',
          text_FR: '',
          text_GE: '',
          repeat_number: [0],
          execution_time: [''],
          ideas: [
            {
              id: 0,
              idea: -1,
            },
          ],
        },
      ],
    });
    setOpen(false);
  };

  const getSearchSessions = async (selected_category: string, page: number) => {
    try {
      setIsLoading(true);
      const tempArray: SessionType[] = [];
      const res = await axios.post(
        `${process.env.REACT_APP_MAIN_API}session/search-sessions/${userId}?page=${page}&limit=${limit}`,
        {
          category: selected_category,
          key: sessionKeyWord,
          sort: sessionSortType,
          folderType: sessionFolderType,
          memberType: memberType,
          membershipState: membershipState,
          selectedLang,
        }
      );
      setTotalNum(res?.data.totalCount);
      res?.data.data.map((item: any) => {
        tempArray.push({
          id: item.id,
          uid: item.uid,
          author: item.author,
          title_EN: item.title_EN,
          title_DU: item.title_DU,
          title_FR: item.title_FR,
          title_GE: item.title_GE,
          text_EN: item.text_EN,
          text_DU: item.text_DU,
          text_FR: item.text_FR,
          text_GE: item.text_GE,
          category_EN: item.category_EN,
          category_DU: item.category_DU,
          category_FR: item.category_FR,
          category_GE: item.category_GE,
          customCategory_EN: item.custom_category_EN,
          customCategory_DU: item.custom_category_DU,
          customCategory_FR: item.custom_category_FR,
          customCategory_GE: item.custom_category_GE,
          subData: JSON.parse(item.sub_data),
          approval: item?.approval,
          isShared: item?.isShared,
          rejected: item?.rejected,
          date: item.date,
        });
      });
      setSessions(tempArray);
      setIsLoading(false);
      setIsSearch(false);
    } catch (error) {
      setIsSearch(false);
      setIsLoading(false);
      console.error(error);
    }
  };

  const getSessionById = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_MAIN_API}session/get_session/${searchSessionId}`);
      if (res.data === null) {
        setSessions([]);
      } else {
        setSessions([
          {
            id: res.data.id,
            uid: res.data.uid,
            author: res.data.author,
            title_EN: res.data.title_EN,
            title_DU: res.data.title_DU,
            title_FR: res.data.title_FR,
            title_GE: res.data.title_GE,
            text_EN: res.data.text_EN,
            text_DU: res.data.text_DU,
            text_FR: res.data.text_FR,
            text_GE: res.data.text_GE,
            category_EN: res.data.category_EN,
            category_DU: res.data.category_DU,
            category_FR: res.data.category_FR,
            category_GE: res.data.category_GE,
            customCategory_EN: res.data.custom_category_EN,
            customCategory_DU: res.data.custom_category_DU,
            customCategory_FR: res.data.custom_category_FR,
            customCategory_GE: res.data.custom_category_GE,
            subData: JSON.parse(res.data.sub_data),
            approval: res.data?.approval,
            isShared: res.data?.isShared,
            rejected: res.data?.rejected,
            date: res.data.date,
          },
        ]);
      }
      setTotalNum(1);
      setIsLoading(false);
      setIsSearch(false);
    } catch (error) {
      setIsSearch(false);
      setIsLoading(false);
      console.error(error);
    }
  };

  const closeModal = () => {
    setOpen(false);
    // setIsModify(true);
  };

  const onClickSession = async (sid: number, ids: number[]) => {
    setSessionID(sid);
    setSubSessionIds(ids);
    setSelectedSessionIdeaID(-1);
    setOpenIdeasModal(true);
  };

  useEffect(() => {
    if (userId > -1 && open && searchSessionId === '') {
      setPageNum(1);
      getSearchSessions(selectedSessionCategory, 1);
    }
  }, [
    sessionFolderType,
    selectedSessionCategory,
    sessionSortType,
    limit,
    sessionKeyWord,
    userId,
    memberType,
    membershipState,
    open,
    searchSessionId,
  ]);

  useEffect(() => {
    if (userId > -1 && open && searchSessionId === '') {
      getSearchSessions(selectedSessionCategory, pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    if (searchSessionId !== '') {
      getSessionById();
    }
  }, [searchSessionId]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.sessionsModal}
      closeButtonColor="Black"
      closeIconEvent={closeModal}
    >
      <div className={styles.wrapper}>
        <SessionFolderCategoryBar setPageNum={setPageNum} />
        <div className={styles.contentWrapper}>
          <div className={styles.buttonField}>
            <button className={styles.newSessionBtn} onClick={selectNewSession}>
              <PlusIcon fill="#ffffff" className={styles.icon} />
              {t('New Session')}
            </button>
            <div className={styles.searchBarWrapper}>
              <div className={classNames(styles.searchBar)}>
                <SearchIcon className={styles.searchIcon} />
                <input
                  type="text"
                  // placeholder={selectedMenuTitle === 'IDEAS' ? 'name of idea' : 'name of session'}
                  placeholder={t('name of session')}
                  className={styles.input}
                  value={searchKeyWord}
                  onChange={e => {
                    setSearchKeyWord(e.target.value);
                  }}
                />
                {searchKeyWord !== '' && (
                  <div className={styles.closeIconWrapper} onClick={() => setSearchKeyWord('')}>
                    <CloseIcon className={styles.closeIcon} />
                  </div>
                )}
                <SessionFilter />
              </div>
              <div className={classNames(styles.searchBar)}>
                <SearchIcon className={styles.searchIDIcon} />
                <input
                  type="text"
                  // placeholder={selectedMenuTitle === 'IDEAS' ? 'name of idea' : 'name of session'}
                  placeholder={t('id of session')}
                  className={styles.input}
                  value={tempSearchSessionId}
                  onChange={e => {
                    setTempSearchSessionId(e.target.value);
                  }}
                />
                {tempSearchSessionId !== '' && (
                  <div className={styles.closeIconWrapper} onClick={() => setTempSearchSessionId('')}>
                    <CloseIcon className={styles.closeIcon} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.sortButton}>
              <SortSessions />
            </div>
          </div>
          <div className={styles.contents}>
            <div className={styles.pagination}>
              <Pagination limit={limit} totalLength={totalNum} setCurrentPage={setPageNum} currentPage={pageNum} />
            </div>
            <div className={styles.sessions}>
              <table className={styles.table}>
                <thead className={styles.thead}>
                  <tr>
                    <th scope="col" className={classNames(styles.th, styles.checkbox)}>
                      <input type="checkbox" />
                    </th>
                    <th scope="col" className={styles.th}>
                      {t('Name')}
                    </th>
                    <th scope="col" className={styles.th}>
                      {t('Last modified')}
                    </th>
                    <th scope="col" className={styles.th}>
                      {t('Author')}
                    </th>
                    <th scope="col" className={styles.th}>
                      {t('Status')}
                    </th>
                    <th scope="col" className={styles.thEvent}></th>
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {sessions.length > 0 &&
                    sessions.map((session: any, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          onClickSession(session.id, session.subData);
                        }}
                        className={styles.tr}
                      >
                        <td className={classNames(styles.td, styles.checkbox)}>
                          <input
                            type="checkbox"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          />
                        </td>
                        <td className={classNames(styles.td, styles.bold)}>
                          <div className={styles.name}>
                            <FileIcon className={styles.icon} />
                            {session[`title_${selectedLang}`]}
                          </div>
                        </td>
                        {/* <td className={styles.td}>{session.date.replace('T', ' ').replace('.000Z', '')}</td> */}
                        <td className={styles.td}>
                          {convertTimezone(session.date.replace('T', ' ').replace('.000Z', ''))}
                        </td>
                        <td className={styles.td}>{session.author}</td>
                        <td className={styles.td}>{session.approval ? t('Yes') : t('No')}</td>
                        <td className={styles.tdEvent}>
                          <button className={styles.tdEvent}>
                            <SessionAction
                              id={session.id}
                              pageNum={pageNum}
                              searchSessions={getSearchSessions}
                              setOpenSessionModal={setOpen}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SessionIdeasModal
        open={openIdeasModal}
        setOpen={setOpenIdeasModal}
        setOpenSessionModal={setOpen}
        canvasRef={canvasRef}
      />
    </Modal>
  );
};

export default SessionsModal;
