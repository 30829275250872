import React, { useContext } from 'react';

import loadingIcon from '../../../assets/icons/loading1.gif';

import styles from './styles.module.scss';
import { UserContext } from '../../../providers/UserProvider';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();
  const { isLoading, isFileUploading } = useContext(UserContext);

  return isLoading ? (
    <div className={styles.wrapper}>
      {isFileUploading ? (
        <div className={styles.uploadWrapper}>
          <span className={styles.text}>{t('File Uploading...')}</span>
          <img src={loadingIcon} className={styles.uploadImg} />
        </div>
      ) : (
        <img src={loadingIcon} className={styles.img} />
      )}
    </div>
  ) : null;
};

export default Loading;
