import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import SubscribeModal from '../SubscribeModal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

function SampleNextArrow(props: { className?: any; style?: any; onClick?: any }) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#818181', borderRadius: '100%' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: { className?: any; style?: any; onClick?: any }) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#818181', borderRadius: '100%' }}
      onClick={onClick}
    />
  );
}

type Props = {
  items: Array<TabItemType>;
};

const Carousel: React.FC<Props> = ({ items }) => {
  const {
    currentTabIndex,
    sceneCharacters,
    sceneSportsObjects,
    membershipState,
    memberType,
    setIsField,
    addCharacterToScene,
    addSportsObjectToScene,
    setFirstLevel,
    setSecondLevel,
    setThirdLevel,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    setSelectedCharacterIndex,
    setSelectedSportsObjectIndex,
    setSportsFieldObject,
    addMaterials,
    setIsSubSportsField,
    setIsSavedState,
    setIsCustomImage,
  } = useContext(UserContext);

  const { t } = useTranslation();
  const [isMemberModal, setIsMemberModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);

  const selectItem = (index: number) => {
    setSelectedItem(index);
    const sceneCharactersLength = sceneCharacters.length;
    const sceneSportsObjectsLength = sceneSportsObjects.length;
    if (currentTabIndex === 0) {
      setSportsFieldObject({ object: items[index].object ?? '' });
      setIsSavedState(true);
      setIsCustomImage(false);
      if (items[index].additional) {
        items[index].additional?.map((item, num: number) => {
          if (item.object !== '') {
            if (item?.objectType === 'object') {
              addSportsObjectToScene(
                item.object ?? '',
                item.label,
                item.isColor ?? false,
                item.color ?? '',
                item.colorObj ?? '',
                item.isColorTwo ?? false,
                item.colorTwo ?? '',
                item.colorTwoObj ?? '',
                item.isColorThree ?? false,
                item.colorThree ?? '',
                item.colorThreeObj ?? '',
                item.position ?? [0, 0, 0],
                item.scale ?? [1, 1, 1],
                sceneSportsObjectsLength + num
              );
              setSelectedCharacterIndex(-1);
              setSelectedSportsObjectIndex(-1);
              if (item.isColor === true) {
                setIsObjectColor(true);
              } else {
                setIsObjectColor(false);
              }
              if (item.isColorTwo === true) {
                setIsObjectColorTwo(true);
              } else {
                setIsObjectColorTwo(false);
              }
              if (item.isColorThree === true) {
                setIsObjectColorThree(true);
              } else {
                setIsObjectColorThree(false);
              }
            }
            if (item?.objectType === 'character') {
              addCharacterToScene(
                item.object ?? '',
                item?.children![0].object ?? '',
                item.label,
                item.position ?? [0, 0, 0],
                item.scale ?? [1, 1, 1],
                sceneCharactersLength + num
              );
              addMaterials(item?.children ?? []);
              setSelectedCharacterIndex(-1);
              setSelectedSportsObjectIndex(-1);
            }
          }
        });
      }
      if (items[index].type === 'SportField') {
        setFirstLevel(items[index]?.children);
        setIsField(true);
        setIsSubSportsField(true);
        setThirdLevel([]);
        setSecondLevel([]);
      }
    }

    if (currentTabIndex === 1) {
      setIsSubSportsField(false);
      if (items[index].type === 'Animation and poses') {
        setFirstLevel(items[index]?.children);
        setThirdLevel([]);
        setSecondLevel([]);
      } else if (items[index].type === 'firstLevel') {
        setThirdLevel([]);
        setSecondLevel(items[index]?.children ?? []);
        if (items[index].object !== '') {
          setSecondLevel([]);
          addCharacterToScene(
            items[index].object ?? '',
            items[index]?.children![0].object ?? '',
            items[index].label,
            items[index].position ?? [0, 0, 0],
            items[index].scale ?? [1, 1, 1],
            sceneCharactersLength
          );
          addMaterials(items[index]?.children ?? []);
          setIsSavedState(true);
          setIsCustomImage(false);
          setSelectedCharacterIndex(-1);
          setSelectedSportsObjectIndex(-1);
        }
        if (items[index].additional) {
          items[index].additional?.map((item, num: number) => {
            if (item.object !== '') {
              if (item?.objectType === 'object') {
                addSportsObjectToScene(
                  item.object ?? '',
                  item.label,
                  item.isColor ?? false,
                  item.color ?? '',
                  item.colorObj ?? '',
                  item.isColorTwo ?? false,
                  item.colorTwo ?? '',
                  item.colorTwoObj ?? '',
                  item.isColorThree ?? false,
                  item.colorThree ?? '',
                  item.colorThreeObj ?? '',
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneSportsObjectsLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
                if (item.isColor === true) {
                  setIsObjectColor(true);
                } else {
                  setIsObjectColor(false);
                }
                if (item.isColorTwo === true) {
                  setIsObjectColorTwo(true);
                } else {
                  setIsObjectColorTwo(false);
                }
                if (item.isColorThree === true) {
                  setIsObjectColorThree(true);
                } else {
                  setIsObjectColorThree(false);
                }
              }
              if (item?.objectType === 'character') {
                addCharacterToScene(
                  item.object ?? '',
                  item?.children![0].object ?? '',
                  item.label,
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneCharactersLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                addMaterials(item?.children ?? []);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
              }
            }
          });
        }
      } else if (items[index].type === 'secondLevel') {
        // changeCharacterDetails(sceneCharactersLength - 1, { material: items[index].object ?? '' });
        if (items[index].object !== '') {
          setThirdLevel(items[index]?.children);
          addCharacterToScene(
            items[index].object ?? '',
            items[index]?.children![0].object ?? '',
            items[index].label,
            items[index].position ?? [0, 0, 0],
            items[index].scale ?? [1, 1, 1],
            sceneCharactersLength
          );
          addMaterials(items[index]?.children ?? []);
          setIsSavedState(true);
          setIsCustomImage(false);
          setSelectedCharacterIndex(-1);
          setSelectedSportsObjectIndex(-1);
        }
        if (items[index].additional) {
          items[index].additional?.map((item, num: number) => {
            if (item.object !== '') {
              if (item?.objectType === 'object') {
                addSportsObjectToScene(
                  item.object ?? '',
                  item.label,
                  item.isColor ?? false,
                  item.color ?? '',
                  item.colorObj ?? '',
                  item.isColorTwo ?? false,
                  item.colorTwo ?? '',
                  item.colorTwoObj ?? '',
                  item.isColorThree ?? false,
                  item.colorThree ?? '',
                  item.colorThreeObj ?? '',
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneSportsObjectsLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
                if (item.isColor === true) {
                  setIsObjectColor(true);
                } else {
                  setIsObjectColor(false);
                }
                if (item.isColorTwo === true) {
                  setIsObjectColorTwo(true);
                } else {
                  setIsObjectColorTwo(false);
                }
                if (item.isColorThree === true) {
                  setIsObjectColorThree(true);
                } else {
                  setIsObjectColorThree(false);
                }
              }
              if (item?.objectType === 'character') {
                addCharacterToScene(
                  item.object ?? '',
                  item?.children![0].object ?? '',
                  item.label,
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneCharactersLength + num
                );
                addMaterials(item?.children ?? []);
                setIsSavedState(true);
                setIsCustomImage(false);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
              }
            }
          });
        }
      }
    }

    if (currentTabIndex === 2 || currentTabIndex === 3 || currentTabIndex === 4 || currentTabIndex === 5) {
      setIsSubSportsField(false);
      if (
        items[index].type === 'Sports' ||
        items[index].type === 'Physical Education' ||
        items[index].type === 'Accessories' ||
        items[index].type === 'Icons'
      ) {
        setFirstLevel(items[index]?.children);
        setThirdLevel([]);
        setSecondLevel([]);
        if (items[index].isColor === true) {
          setIsObjectColor(true);
        } else {
          setIsObjectColor(false);
        }
        if (items[index].isColorTwo === true) {
          setIsObjectColorTwo(true);
        } else {
          setIsObjectColorTwo(false);
        }
        if (items[index].isColorThree === true) {
          setIsObjectColorThree(true);
        } else {
          setIsObjectColorThree(false);
        }
      } else if (items[index].type === 'firstLevel') {
        setSecondLevel(items[index]?.children);
        setThirdLevel([]);
        if (items[index].object !== '') {
          addSportsObjectToScene(
            items[index].object ?? '',
            items[index].label,
            items[index].isColor ?? false,
            items[index].color ?? '',
            items[index].colorObj ?? '',
            items[index].isColorTwo ?? false,
            items[index].colorTwo ?? '',
            items[index].colorTwoObj ?? '',
            items[index].isColorThree ?? false,
            items[index].colorThree ?? '',
            items[index].colorThreeObj ?? '',
            items[index].position ?? [0, 0, 0],
            items[index].scale ?? [1, 1, 1],
            sceneSportsObjectsLength
          );
          setIsSavedState(true);
          setIsCustomImage(false);
          setSelectedCharacterIndex(-1);
          setSelectedSportsObjectIndex(-1);
          if (items[index].isColor === true) {
            setIsObjectColor(true);
          } else {
            setIsObjectColor(false);
          }
          if (items[index].isColorTwo === true) {
            setIsObjectColorTwo(true);
          } else {
            setIsObjectColorTwo(false);
          }
          if (items[index].isColorThree === true) {
            setIsObjectColorThree(true);
          } else {
            setIsObjectColorThree(false);
          }
        }
        if (items[index].additional) {
          items[index].additional?.map((item, num: number) => {
            if (item.object !== '') {
              if (item?.objectType === 'object') {
                addSportsObjectToScene(
                  item.object ?? '',
                  item.label,
                  item.isColor ?? false,
                  item.color ?? '',
                  item.colorObj ?? '',
                  item.isColorTwo ?? false,
                  item.colorTwo ?? '',
                  item.colorTwoObj ?? '',
                  item.isColorThree ?? false,
                  item.colorThree ?? '',
                  item.colorThreeObj ?? '',
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneSportsObjectsLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
                if (item.isColor === true) {
                  setIsObjectColor(true);
                } else {
                  setIsObjectColor(false);
                }
                if (item.isColorTwo === true) {
                  setIsObjectColorTwo(true);
                } else {
                  setIsObjectColorTwo(false);
                }
                if (item.isColorThree === true) {
                  setIsObjectColorThree(true);
                } else {
                  setIsObjectColorThree(false);
                }
              }
              if (item?.objectType === 'character') {
                addCharacterToScene(
                  item.object ?? '',
                  item?.children![0].object ?? '',
                  item.label,
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneCharactersLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                addMaterials(item?.children ?? []);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
              }
            }
          });
        }
      } else if (items[index].type === 'secondLevel') {
        setThirdLevel(items[index]?.children);
        if (items[index].object !== '') {
          addSportsObjectToScene(
            items[index].object ?? '',
            items[index].label,
            items[index].isColor ?? false,
            items[index].color ?? '',
            items[index].colorObj ?? '',
            items[index].isColorTwo ?? false,
            items[index].colorTwo ?? '',
            items[index].colorTwoObj ?? '',
            items[index].isColorThree ?? false,
            items[index].colorThree ?? '',
            items[index].colorThreeObj ?? '',
            items[index].position ?? [0, 0, 0],
            items[index].scale ?? [1, 1, 1],
            sceneSportsObjectsLength
          );
          setIsSavedState(true);
          setIsCustomImage(false);
          setSelectedCharacterIndex(-1);
          setSelectedSportsObjectIndex(-1);
          if (items[index].isColor === true) {
            setIsObjectColor(true);
          } else {
            setIsObjectColor(false);
          }
          if (items[index].isColorTwo === true) {
            setIsObjectColorTwo(true);
          } else {
            setIsObjectColorTwo(false);
          }
          if (items[index].isColorThree === true) {
            setIsObjectColorThree(true);
          } else {
            setIsObjectColorThree(false);
          }
        }
        if (items[index].additional) {
          items[index].additional?.map((item, num: number) => {
            if (item.object !== '') {
              if (item?.objectType === 'object') {
                addSportsObjectToScene(
                  item.object ?? '',
                  item.label,
                  item.isColor ?? false,
                  item.color ?? '',
                  item.colorObj ?? '',
                  item.isColorTwo ?? false,
                  item.colorTwo ?? '',
                  item.colorTwoObj ?? '',
                  item.isColorThree ?? false,
                  item.colorThree ?? '',
                  item.colorThreeObj ?? '',
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneSportsObjectsLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
                if (item.isColor === true) {
                  setIsObjectColor(true);
                } else {
                  setIsObjectColor(false);
                }
                if (item.isColorTwo === true) {
                  setIsObjectColorTwo(true);
                } else {
                  setIsObjectColorTwo(false);
                }
                if (item.isColorThree === true) {
                  setIsObjectColorThree(true);
                } else {
                  setIsObjectColorThree(false);
                }
              }
              if (item?.objectType === 'character') {
                addCharacterToScene(
                  item.object ?? '',
                  item?.children![0].object ?? '',
                  item.label,
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneCharactersLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                addMaterials(item?.children ?? []);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
              }
            }
          });
        }
      } else if (items[index].type === 'thirdLevel') {
        addSportsObjectToScene(
          items[index].object ?? '',
          items[index].label,
          items[index].isColor ?? false,
          items[index].color ?? '',
          items[index].colorObj ?? '',
          items[index].isColorTwo ?? false,
          items[index].colorTwo ?? '',
          items[index].colorTwoObj ?? '',
          items[index].isColorThree ?? false,
          items[index].colorThree ?? '',
          items[index].colorThreeObj ?? '',
          items[index].position ?? [0, 0, 0],
          items[index].scale ?? [1, 1, 1],
          sceneSportsObjectsLength
        );
        setIsSavedState(true);
        setIsCustomImage(false);
        setSelectedCharacterIndex(-1);
        setSelectedSportsObjectIndex(-1);
        if (items[index].isColor === true) {
          setIsObjectColor(true);
        } else {
          setIsObjectColor(false);
        }
        if (items[index].isColorTwo === true) {
          setIsObjectColorTwo(true);
        } else {
          setIsObjectColorTwo(false);
        }
        if (items[index].isColorThree === true) {
          setIsObjectColorThree(true);
        } else {
          setIsObjectColorThree(false);
        }
        if (items[index].additional) {
          items[index].additional?.map((item, num: number) => {
            if (item.object !== '') {
              if (item?.objectType === 'object') {
                addSportsObjectToScene(
                  item.object ?? '',
                  item.label,
                  item.isColor ?? false,
                  item.color ?? '',
                  item.colorObj ?? '',
                  item.isColorTwo ?? false,
                  item.colorTwo ?? '',
                  item.colorTwoObj ?? '',
                  item.isColorThree ?? false,
                  item.colorThree ?? '',
                  item.colorThreeObj ?? '',
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneSportsObjectsLength + num
                );
                setIsSavedState(true);
                setIsCustomImage(false);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
                if (item.isColor === true) {
                  setIsObjectColor(true);
                } else {
                  setIsObjectColor(false);
                }
                if (item.isColorTwo === true) {
                  setIsObjectColorTwo(true);
                } else {
                  setIsObjectColorTwo(false);
                }
                if (item.isColorThree === true) {
                  setIsObjectColorThree(true);
                } else {
                  setIsObjectColorThree(false);
                }
              }
              if (item?.objectType === 'character') {
                addCharacterToScene(
                  item.object ?? '',
                  item?.children![0].object ?? '',
                  item.label,
                  item.position ?? [0, 0, 0],
                  item.scale ?? [1, 1, 1],
                  sceneCharactersLength + num
                );
                addMaterials(item?.children ?? []);
                setIsSavedState(true);
                setIsCustomImage(false);
                setSelectedCharacterIndex(-1);
                setSelectedSportsObjectIndex(-1);
              }
            }
          });
        }
      }
    }
  };

  const settings = {
    infinite: false,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setSelectedItem(-1);
  }, [items]);

  return (
    <Slider {...settings}>
      {items.map(({ label, image }: TabItemType, index: number) => (
        <div key={index}>
          <button
            className={classNames(styles.wrapper)}
            onClick={() => {
              if (!items[index]?.demo && membershipState === 0 && memberType === 'member') {
                setIsMemberModal(true);
              } else {
                selectItem(index);
              }
            }}
          >
            <>
              {currentTabIndex === 0 ? (
                <>
                  <div
                    className={classNames(styles.imageWrapper, {
                      [styles.demo]: !items[index]?.demo && membershipState === 0 && memberType === 'member',
                    })}
                  >
                    <img alt="objectImage" src={image} />
                  </div>
                  <p
                    className={classNames(styles.text, {
                      [styles.demo]: !items[index]?.demo && membershipState === 0 && memberType === 'member',
                      [styles.selected]: selectedItem === index,
                    })}
                  >
                    {t(label)}
                  </p>
                </>
              ) : image !== '' ? (
                <>
                  <div
                    className={classNames(styles.imageWrapper, {
                      [styles.demo]: !items[index]?.demo && membershipState === 0 && memberType === 'member',
                    })}
                  >
                    <img alt="objectImage" src={image} />
                  </div>
                  <p
                    className={classNames(styles.text, {
                      [styles.demo]: !items[index]?.demo && membershipState === 0 && memberType === 'member',
                      [styles.selected]: selectedItem === index,
                    })}
                  >
                    {t(label)}
                  </p>
                </>
              ) : (
                <p
                  className={classNames(styles.text, {
                    [styles.demo]: !items[index]?.demo && membershipState === 0 && memberType === 'member',
                    [styles.selected]: selectedItem === index,
                  })}
                >
                  {t(label)}
                </p>
              )}
            </>
          </button>
        </div>
      ))}
      <SubscribeModal
        open={isMemberModal}
        setOpen={setIsMemberModal}
        description={'Please upgrade your subscription plan to unlock it.'}
      />
    </Slider>
  );
};

export default Carousel;
