import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import ShareModal from '../ShareModal';
import SubscribeModal from '../SubscribeModal';
import MoveCategories from '../MoveCategories';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';

import styles from './styles.module.scss';

type Props = {
  id: number;
  className?: string;
};

const IdeaActions: React.FC<Props> = ({ id, className }) => {
  const { t } = useTranslation();
  const {
    memberType,
    folderType,
    folderIdeas,
    membershipState,
    customCategories,
    categories,
    setFolderIdeas,
    setSceneId,
    setIsLoading,
  } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);

  const onAction = (e: any) => {
    e.stopPropagation();
    setOpen(prev => !prev);
  };

  const onClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onShare = (e: any) => {
    setSceneId(id);
    e.stopPropagation();
    if (membershipState > 6 || memberType !== 'member') {
      setIsShareModal(true);
    } else {
      setIsSubscribeModal(true);
    }
  };

  const onApprove = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/approve-idea`, {
        id: id,
      });
      const tempArray: FolderIdeaType[] = [];
      folderIdeas.map((item: FolderIdeaType) => {
        if (item.id !== id) {
          tempArray.push(item);
        }
      });
      setFolderIdeas(tempArray);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onDismiss = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/dismiss-idea`, {
        id: id,
      });
      const tempArray: FolderIdeaType[] = [];
      folderIdeas.map((item: FolderIdeaType) => {
        if (item.id !== id) {
          tempArray.push(item);
        }
      });
      setFolderIdeas(tempArray);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onDelete = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/delete-idea`, {
        id: id,
      });
      const tempArray: FolderIdeaType[] = [];
      folderIdeas.map((item: FolderIdeaType) => {
        if (item.id !== id) {
          tempArray.push(item);
        }
      });
      setFolderIdeas(tempArray);
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>
          <DotsIcon
            onClick={(e: any) => {
              onAction(e);
            }}
            className={classNames(styles.listIcon, {
              [styles.select]: open,
            })}
          />
          {open && (
            <>
              <div className={styles.arrowDiv} />
              <div className={classNames(styles.arrowDiv, styles.hidden)} />
              <div
                className={styles.background}
                onClick={(e: any) => {
                  onClose(e);
                }}
              ></div>
              <div className={styles.mainField}>
                {folderType === 'Folders' && (
                  <button
                    className={styles.button}
                    onClick={(e: any) => {
                      onShare(e);
                    }}
                  >
                    <ShareIcon className={styles.icon} />
                    {t('Share')}
                  </button>
                )}
                {(folderType === 'Folders' ||
                  (folderType === 'Shared Folders' && memberType !== 'member') ||
                  folderType === 'Shared by members') && (
                  <MoveCategories
                    id={id}
                    categories={folderType === 'Folders' ? customCategories : categories}
                    setCloseActionModal={setOpen}
                  />
                )}
                {folderType === 'Shared by members' && memberType !== 'member' && (
                  <>
                    <button className={styles.button} onClick={(e: any) => onApprove(e)}>
                      <CheckIcon fill={'green'} className={styles.icon} />
                      {t('Approve')}
                    </button>
                    <button
                      className={styles.button}
                      onClick={(e: any) => {
                        onDismiss(e);
                      }}
                    >
                      <CrossIcon fill={'red'} className={styles.icon} />
                      {t('Dismiss')}
                    </button>
                  </>
                )}
                {(folderType === 'Folders' ||
                  (folderType === 'Shared Folders' && memberType !== 'member') ||
                  (folderType === 'Shared by members' && memberType !== 'member')) && (
                  <>
                    <hr className={styles.line} />
                    <button
                      className={classNames(styles.button, styles.delete)}
                      onClick={(e: any) => {
                        onDelete(e);
                      }}
                    >
                      {t('Delete')}
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <ShareModal open={isShareModal} setOpen={setIsShareModal} />
      <SubscribeModal
        open={isSubscribeModal}
        setOpen={setIsSubscribeModal}
        description={`Please upgrade your subscription plan to 'Pro' to unlock it.`}
      />
    </div>
  );
};

export default IdeaActions;
