import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import mouseLeft from '../../../assets/icons/Help Gesture/left-click.png';
import mouseCenter from '../../../assets/icons/Help Gesture/click.png';
import mouseRight from '../../../assets/icons/Help Gesture/right-click.png';
import translation from '../../../assets/icons/Help Gesture/drag-translation.png';
import rotate from '../../../assets/icons/Help Gesture/tap-rotate.png';
import zoom from '../../../assets/icons/Help Gesture/tap-zoom.png';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as MoveIcon } from '../../../assets/icons/move.svg';
import { ReactComponent as RotateIcon } from '../../../assets/icons/rotate.svg';
import { ReactComponent as ScaleIcon } from '../../../assets/icons/scale.svg';

import styles from './styles.module.scss';

const contents = [
  {
    title: 'ROTATION',
    mouseImg: mouseLeft,
    or: 'OR',
    eventImg: rotate,
    description: 'Hold down the left mouse button or move with 1 finger',
  },
  {
    title: 'ZOOM',
    mouseImg: mouseCenter,
    or: 'OR',
    eventImg: zoom,
    description: 'Use the mouse knob or pinch with 2 fingers',
  },
  {
    title: 'TRANSLATION',
    mouseImg: mouseRight,
    or: 'OR',
    eventImg: translation,
    description: 'Hold down the right mouse button or move with 2 fingers',
  },
];

const buttons = [
  {
    img: DeleteIcon,
    description: 'Delete the selected object',
  },
  {
    img: MoveIcon,
    description: 'Move the selected object in 3 axes using the coloured arrows',
  },
  {
    img: RotateIcon,
    description: 'Rotate the selected object',
  },
  {
    img: ScaleIcon,
    description: 'Increase or decrease the size of the selected object',
  },
];
type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HelpModal: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} setOpen={setOpen} className={styles.helpModal}>
      <p className={styles.title}>{t('AIDE AU DEMARRAGE')}</p>
      <div className={styles.contentWrapper}>
        {contents.map((item, index: number) => (
          <div key={index} className={styles.event}>
            <p className={styles.title}>{item.title}</p>
            <div className={styles.images}>
              <img src={item.mouseImg} alt="" className={styles.mouseImg} />
              <p className={styles.or}>{item.or}</p>
              <img src={item.eventImg} alt="" className={styles.eventImg} />
            </div>
            <p className={styles.description}>{t(item.description)}</p>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        {buttons.map((item, index: number) => (
          <div key={index} className={styles.button}>
            <item.img className={styles.img} />
            <p className={styles.description}>{t(item.description)}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default HelpModal;
