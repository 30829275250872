import React, { useContext } from 'react';

import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../../assets/icons/pause.svg';
import { ReactComponent as StopIcon } from '../../../assets/icons/stop.svg';
import { ReactComponent as SingleStepIcon } from '../../../assets/icons/step.svg';

import styles from './styles.module.scss';

type Props = {
  id: number;
  index: number;
  label: string;
  currentAnimationIndex?: number;
  isSingleMode?: boolean;
  changeIndex: any;
};
const AnimationControl: React.FC<Props> = ({ id, index, label, currentAnimationIndex, changeIndex }) => {
  const { setAnimationStop, setSingleIndex } = useContext(UserContext);

  const singleMode = () => {
    setSingleIndex(index);
    // setIsSingleMode(true);
    changeIndex(id, { isSingleMode: true });
    setTimeout(() => changeIndex(id, { isSingleMode: false }), 50);
  };

  return (
    <div className={styles.Wrapper}>
      <p className={styles.name}>{label}</p>
      <button
        onClick={() => {
          changeIndex(id, { currentAnimationIndex: currentAnimationIndex === index ? undefined : index });
        }}
      >
        {currentAnimationIndex !== index ? <PlayIcon /> : <PauseIcon />}
      </button>
      <button onClick={singleMode} className={styles.singleStepIcon}>
        <SingleStepIcon />
      </button>
      <button
        // disabled={currentAnimationIndex !== index}
        onClick={() => {
          setAnimationStop(prev => !prev);
        }}
      >
        <StopIcon />
      </button>
    </div>
  );
};

export default AnimationControl;
