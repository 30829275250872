import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';

import Button from '../Button';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cancelButtonLabel?: string;
  actionButtonLabel?: string;
  actionButtonEvent?: React.MouseEventHandler<HTMLButtonElement>;
  cancelButtonEvent?: any;
  children?: React.ReactNode;
  className?: string;
  closeButtonColor?: 'Black' | 'White';
  closeIconEvent?: any;
  closeIconBackground?: boolean;
  ActionButton?: () => JSX.Element;
};

const Modal: React.FC<Props> = ({
  open,
  setOpen,
  children,
  className,
  actionButtonEvent,
  cancelButtonEvent,
  actionButtonLabel,
  cancelButtonLabel,
  closeButtonColor = 'White',
  closeIconEvent,
  closeIconBackground = false,
  ActionButton,
}) => {
  const cancelButton = () => {
    if (cancelButtonEvent) {
      cancelButtonEvent();
    } else {
      setOpen(false);
    }
  };

  const closeButton = () => {
    if (closeIconEvent) {
      closeIconEvent();
    }
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" auto-reopen="true" className={styles.wrapper} onClose={setOpen}>
        <div className={styles.content} onClick={(e: any) => e.stopPropagation()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={styles.translation} />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={classNames(styles.mainContentWrapper, className)}>
              {closeIconBackground ? (
                <div
                  className={classNames(styles.closeIconWrapper, {
                    [styles.whiteColor]: closeButtonColor !== 'White',
                  })}
                >
                  <CloseIcon
                    fill={closeButtonColor === 'White' ? '#ffffff' : '#000000'}
                    className={styles.icon}
                    onClick={closeButton}
                  />
                </div>
              ) : (
                <CloseIcon
                  fill={closeButtonColor === 'White' ? '#ffffff' : '#000000'}
                  className={styles.close}
                  onClick={closeButton}
                />
              )}
              {children}
              {(actionButtonLabel || cancelButtonLabel) && (
                <div className={styles.buttonWrapper}>
                  {ActionButton !== undefined ? (
                    <ActionButton />
                  ) : (
                    actionButtonLabel && <Button label={actionButtonLabel} onClick={actionButtonEvent} />
                  )}
                  {cancelButtonLabel && (
                    <Button label={cancelButtonLabel} onClick={cancelButton} colorType="secondary" />
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
