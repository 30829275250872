import React, { useContext } from 'react';

import characters from '../../../json/characters.json';
import { UserContext } from '../../../providers/UserProvider';
import Carousel from '../../_ui/Carousel';

import styles from './styles.module.scss';

const CharacterTab: React.FC = () => {
  const { firstLevel, secondLevel } = useContext(UserContext);
  return (
    <div className={styles.wrapper}>
      {/* <p className={styles.title}>{t(tabButtonLists[currentTabIndex].label)}</p>
      <hr className={styles.line} /> */}
      <Carousel items={characters}></Carousel>
      {firstLevel && (
        <>
          {firstLevel.length > 0 && <hr className={styles.line} />}
          <Carousel items={firstLevel}></Carousel>
        </>
      )}
      {secondLevel && (
        <>
          {secondLevel.length > 0 && <hr className={styles.line} />}
          <Carousel items={secondLevel}></Carousel>
        </>
      )}
      <hr className={styles.line} />
    </div>
  );
};

export default CharacterTab;
