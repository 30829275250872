import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import SessionIdea from '../SessionIdea';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit-text.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/close.svg';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  canvasRef: React.RefObject<ExportObject>;
  setOpenSessionModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SessionIdeasModal: React.FC<Props> = ({ open, setOpen, setOpenSessionModal, canvasRef }) => {
  const {
    sessionDetail,
    sessions,
    sessionID,
    selectedLang,
    subSessionIds,
    sessionFolderType,
    memberType,
    setSessions,
    setSessionDetail,
    setIsLoading,
    setNewSession,
    setSelectedMenuTitle,
    setIdeaType,
    setModifySessionId,
    setOpenIdeasModal,
  } = useContext(UserContext);

  const { t } = useTranslation();

  const [selectedSession, setSelectedSession] = useState<SessionType | undefined>(
    sessions.find((e: any) => e.id === sessionID)
  );

  const closeModal = () => {
    setOpen(false);
  };

  const onModify = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);

      const resSelectedSession = await axios.get(`${process.env.REACT_APP_MAIN_API}session/get_session/${sessionID}`);
      const tempSession: NewSessionType = {
        title_EN: resSelectedSession.data.title_EN,
        title_DU: resSelectedSession.data.title_DU,
        title_FR: resSelectedSession.data.title_FR,
        title_GE: resSelectedSession.data.title_GE,
        text_EN: resSelectedSession.data.text_EN,
        text_DU: resSelectedSession.data.text_DU,
        text_FR: resSelectedSession.data.text_FR,
        text_GE: resSelectedSession.data.text_GE,
        category_EN: resSelectedSession.data.category_EN,
        category_DU: resSelectedSession.data.category_DU,
        category_FR: resSelectedSession.data.category_FR,
        category_GE: resSelectedSession.data.category_GE,
        custom_category_EN: resSelectedSession.data.custom_category_EN,
        custom_category_DU: resSelectedSession.data.custom_category_DU,
        custom_category_FR: resSelectedSession.data.custom_category_FR,
        custom_category_GE: resSelectedSession.data.custom_category_GE,
        approval: resSelectedSession.data.approval,
        isShared: resSelectedSession.data.isShared,
        rejected: resSelectedSession.data.rejected,
        subSessions: [],
      };

      let idLen = 0;
      for await (const subID of JSON.parse(resSelectedSession.data.sub_data)) {
        const resSubSession = await axios.get(`${process.env.REACT_APP_MAIN_API}sub_session/get_sub_session/${subID}`);
        const repeatNumbers = JSON.parse(resSubSession.data.repeat_number);
        const executionTimes = JSON.parse(resSubSession.data.execution_time);
        tempSession.subSessions.push({
          id: idLen,
          session: -1,
          title_EN: resSubSession.data.title_EN,
          title_DU: resSubSession.data.title_DU,
          title_FR: resSubSession.data.title_FR,
          title_GE: resSubSession.data.title_GE,
          text_EN: resSubSession.data.text_EN,
          text_DU: resSubSession.data.text_DU,
          text_FR: resSubSession.data.text_FR,
          text_GE: resSubSession.data.text_GE,
          repeat_number: [],
          execution_time: [],
          ideas: [],
        });

        JSON.parse(resSubSession.data.ideas).map((item: number) => {
          tempSession.subSessions[idLen].repeat_number.push(repeatNumbers[`idea_${item}`]);
          tempSession.subSessions[idLen].execution_time.push(executionTimes[`idea_${item}`]);
        });

        const resIdeas = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/ideas`, {
          ids: JSON.parse(resSubSession.data.ideas),
        });
        if (resIdeas.data !== null) {
          resIdeas.data.map((item: any, index: number) => {
            tempSession.subSessions[idLen].ideas.push({
              id: index,
              idea: item.oid,
            });
          });
        }

        idLen += 1;
      }
      setNewSession(tempSession);
      setSelectedMenuTitle('Sessions');
      setIdeaType('sessionIdea');
      setModifySessionId(sessionID);
      setIsLoading(false);
      closeModal();
      setOpenSessionModal(false);
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(false);
    }
  };

  const getSubSessions = async (sid: number, ids: number[]) => {
    try {
      setIsLoading(true);
      const subSessions = await axios.post(`${process.env.REACT_APP_MAIN_API}sub_session/sub_sessions`, {
        ids,
      });
      const subSessionData = subSessions.data;
      const tempArray: SubSessionType[] = [];
      const subLength = subSessionData.length;
      if (subLength > 0) {
        for (let i = 0; i < subLength; i++) {
          const ideas = JSON.parse(subSessionData[i].ideas);
          if (ideas.length > 0) {
            const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session_idea/ideas`, {
              ids: ideas,
            });
            tempArray.push({
              id: subSessionData[i].id,
              title_EN: subSessionData[i].title_EN,
              title_DU: subSessionData[i].title_DU,
              title_FR: subSessionData[i].title_FR,
              title_GE: subSessionData[i].title_GE,
              text_EN: subSessionData[i].text_EN,
              text_DU: subSessionData[i].text_DU,
              text_FR: subSessionData[i].text_FR,
              text_GE: subSessionData[i].text_GE,
              ideas: res.data,
              repeat_number: JSON.parse(subSessionData[i].repeat_number),
              execution_time: JSON.parse(subSessionData[i].execution_time),
            });
          } else {
            tempArray.push({
              id: subSessionData[i].id,
              title_EN: subSessionData[i].title_EN,
              title_DU: subSessionData[i].title_DU,
              title_FR: subSessionData[i].title_FR,
              title_GE: subSessionData[i].title_GE,
              text_EN: subSessionData[i].text_EN,
              text_DU: subSessionData[i].text_DU,
              text_FR: subSessionData[i].text_FR,
              text_GE: subSessionData[i].text_GE,
              ideas: [],
              repeat_number: {},
              execution_time: {},
            });
          }
        }
      }
      setSessionDetail(tempArray);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error: ', error);
    }
  };

  const onApprove = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/update-session/${sessionID}`, {
        approval: true,
      });
      const tempArray: SessionType[] = [];
      sessions.map((item: SessionType) => {
        if (item.id !== sessionID) {
          tempArray.push(item);
        }
      });
      setSessions(tempArray);
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onDelete = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/delete-session/${sessionID}`);
      const tempArray: SessionType[] = [];
      sessions.map((item: SessionType) => {
        if (item.id !== sessionID) {
          tempArray.push(item);
        }
      });
      setSessions(tempArray);
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    // if (open) {
    setSelectedSession(sessions.find((e: any) => e.id === sessionID));
    getSubSessions(sessionID, subSessionIds);
    // }
  }, [sessionID]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.sessionIdeasModal}
      closeButtonColor="Black"
      closeIconEvent={closeModal}
    >
      <div className={styles.wrapper}>
        <div className={styles.actionWrapper}>
          <div className={styles.approveWrapper}>
            {sessionFolderType === 'Shared by members' && memberType !== 'member' && (
              <>
                <button className={styles.approve} onClick={(e: any) => onApprove(e)}>
                  <CheckIcon fill={'green'} className={styles.icon} />
                  {t('Approve')}
                </button>
                <button
                  className={styles.approve}
                  onClick={(e: any) => {
                    onDelete(e);
                  }}
                >
                  <CrossIcon fill={'red'} className={styles.icon} />
                  {t('Dismiss')}
                </button>
              </>
            )}
          </div>
          <button className={styles.modify} onClick={(e: any) => onModify(e)}>
            <EditIcon className={styles.editIcon} />
            {t('Modify')}
          </button>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{selectedSession ? selectedSession[`title_${selectedLang}`] : ''}</div>
          {selectedSession && selectedSession[`text_${selectedLang}`] !== '' && (
            <div className={styles.description}>{selectedSession ? selectedSession[`text_${selectedLang}`] : ''}</div>
          )}
          {sessionDetail.length > 0 &&
            sessionDetail.map(sub => (
              <div className={styles.subWrapper} key={sub.id}>
                <div className={styles.subTitle}>{sub[`title_${selectedLang}`]}</div>
                {sub[`text_${selectedLang}`] && sub[`text_${selectedLang}`] !== '' && (
                  <div className={styles.subDescription}>{sub[`text_${selectedLang}`]}</div>
                )}
                <div className={styles.ideas}>
                  {sub.ideas.length > 0 &&
                    sub.ideas.map(idea => (
                      <SessionIdea
                        key={idea.id}
                        idea={idea}
                        setOpen={setOpen}
                        subID={sub.id}
                        repeatNumber={parseInt(sub.repeat_number[`idea_${idea.id}`])}
                        executionTime={sub.execution_time[`idea_${idea.id}`]}
                        currentImage={idea.custom_image}
                        customImage={idea.image}
                        customVideo={idea.custom_video}
                        canvasRef={canvasRef}
                      />
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default SessionIdeasModal;
