import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import axios from 'axios';

import { ReactComponent as PlusGrayIcon } from '../../../assets/icons/plus-gray.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play-1.svg';

import image from '../../../assets/canvas.jpg';

import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';
import FoldersModal from '../FoldersModal';

type ExportObject = {
  screenShotObject: () => void;
  generateScreenObject: () => void;
};

type Props = {
  ideaIndex: number;
  sessionIndex: number;
  canvasRef: React.RefObject<ExportObject>;
  isAddNewIdea: boolean;
};

const NewSessionIdea: React.FC<Props> = ({ ideaIndex, sessionIndex, canvasRef, isAddNewIdea }) => {
  const { newSession, setNewSession, setIsLoading, setIsNewSession, setIsResetIdeas } = useContext(UserContext);
  const [isEmpty, setIsEmpty] = useState(
    newSession.subSessions[sessionIndex].ideas[ideaIndex].idea === -1 ? true : false
  );
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [originIdeaIndex, setOriginIdeaIndex] = useState(-1);
  const [title, setTitle] = useState('');
  const [customImage, setCustomImage] = useState('');
  const [currentImage, setCurrentImage] = useState('');
  const [customVideo, setCustomVideo] = useState('');

  const onPlus = (e: any) => {
    e.stopPropagation();
    setIsNewSession(true);
    setIsResetIdeas(false);
    setOpenFolderModal(true);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const tempArray = { ...newSession };
    if (tempArray.subSessions[sessionIndex].ideas.length === 1) {
      tempArray.subSessions[sessionIndex].ideas = [{ id: 0, idea: -1 }];
      setNewSession(tempArray);
      setIsEmpty(true);
    } else {
      tempArray.subSessions[sessionIndex].ideas.splice(ideaIndex, 1);
      setNewSession(tempArray);
    }
  };

  const getIdea = async (id: number) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}/scene-data/import-idea`, {
        id: id,
      });
      setTitle(res.data.name);
      setCustomImage(res.data.image);
      setCurrentImage(res.data.custom_image);
      setCustomVideo(res.data.custom_video);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useMemo(() => {
    setOriginIdeaIndex(newSession.subSessions[sessionIndex].ideas[ideaIndex].idea);
    if (newSession.subSessions[sessionIndex].ideas[ideaIndex].idea === -1) {
      setIsEmpty(true);
    } else {
      getIdea(newSession.subSessions[sessionIndex].ideas[ideaIndex].idea);
    }
  }, [newSession.subSessions[sessionIndex].ideas[ideaIndex].idea]);

  useEffect(() => {
    if (originIdeaIndex !== -1) {
      const tempArray = { ...newSession };
      newSession.subSessions[sessionIndex].ideas[ideaIndex].idea = originIdeaIndex;
      setNewSession(tempArray);
      setIsEmpty(false);
    }
  }, [originIdeaIndex]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.empty]: isEmpty,
        [styles.noEmpty]: !isEmpty,
        [styles.isAddNewIdea]: isAddNewIdea && ideaIndex === newSession.subSessions[sessionIndex].ideas.length - 1,
      })}
      onClick={onPlus}
      onMouseDown={(e: any) => e.stopPropagation()}
    >
      <CloseIcon className={styles.close} onClick={onDelete} />
      {isEmpty ? (
        <PlusGrayIcon className={styles.icon} onClick={onPlus} />
      ) : (
        <div>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{title}</div>
          </div>
          {customVideo !== '' ? (
            <div className={styles.imageWrapper}>
              {currentImage !== '' && <img className={styles.image} src={currentImage} alt="" />}
              {currentImage === '' && customImage !== '' && <img className={styles.image} src={customImage} alt="" />}
              <div className={styles.overlap}>
                <PlayIcon fill={customImage === '' ? '#000000' : '#ffffff'} className={styles.icon} />
              </div>
            </div>
          ) : currentImage !== '' ? (
            <div className={styles.imageWrapper}>
              <img className={styles.image} src={currentImage} alt="" />
            </div>
          ) : customImage !== '' ? (
            <div className={styles.imageWrapper}>
              <img className={styles.image} src={customImage} alt="" />
            </div>
          ) : (
            <div className={styles.imageWrapper}></div>
          )}
        </div>
      )}
      {openFolderModal && (
        <FoldersModal
          open={openFolderModal}
          setOpen={setOpenFolderModal}
          canvasRef={canvasRef}
          setOriginIdeaIndex={setOriginIdeaIndex}
        />
      )}
    </div>
  );
};

export default NewSessionIdea;
