export const convertTimezone = str => {
  const date = str.split(' ')[0];
  const time = str.split(' ')[1];

  var year = parseInt(date.split('-')[0]);
  var month = parseInt(date.split('-')[1]);
  var day = parseInt(date.split('-')[2]);

  var hour = parseInt(time.split(':')[0]);
  var minute = parseInt(time.split(':')[1]);
  var second = parseInt(time.split(':')[2]);

  var hourOffset = -new Date().getTimezoneOffset() / 60;
  var dayOffset = 0;

  if (hour + hourOffset > 23) {
    hour = hour + hourOffset - 24;
    dayOffset = 1;
  } else if (hour + hourOffset < 0) {
    hour = 24 + (hour + hourOffset);
    dayOffset = -1;
  } else {
    hour = hour + hourOffset;
  }

  day = day + dayOffset;

  var newMonth = '';
  if (month.toString().length === 1) {
    newMonth = '0' + month;
  } else {
    newMonth = month.toString();
  }

  var newDay = '';
  if (day.toString().length === 1) {
    newDay = '0' + day;
  } else {
    newDay = day.toString();
  }

  var newHour = '';
  if (hour.toString().length === 1) {
    newHour = '0' + hour;
  } else {
    newHour = hour.toString();
  }

  var newMin = '';
  if (minute.toString().length === 1) {
    newMin = '0' + minute;
  } else {
    newMin = minute.toString();
  }

  var newSec = '';
  if (second.toString().length === 1) {
    newSec = '0' + second;
  } else {
    newSec = second.toString();
  }

  return `${year}-${newMonth}-${newDay} ${newHour}:${newMin}:${newSec}`;
};
