import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '../Dropdown';
import Modal from '../Modal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShareModal: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { categories, sceneId, setIsLoading } = useContext(UserContext);
  const [category, setCategory] = useState('');

  const shareIdea = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API}scene-data/share-idea`, {
        id: sceneId,
        category: category,
      });
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setCategory(categories[0] ?? '');
  }, [categories]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.saveModal}
      actionButtonLabel={t('Share')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={shareIdea}
      closeButtonColor="Black"
    >
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t('Share Ideas')}</p>
      </div>
      <div className={styles.categoryWrapper}>
        <span className={styles.title}>{t('Select Category')}</span>
        <Dropdown
          className={styles.category}
          selectedItem={category}
          setSelectedItem={setCategory}
          items={categories}
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
