import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { UserContext } from '../../../providers/UserProvider';

import styles from './styles.module.scss';
import LanguageTabButton from '../LanguageTabButton';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categoryType: string;
};

const SaveSessionCategoryModal: React.FC<Props> = ({ open, setOpen, categoryType }) => {
  const { t } = useTranslation();
  const {
    sessionCategories,
    sessionCustomCategories,
    selectedLang,
    setSessionCategories,
    setSessionCustomCategories,
    setIsLoading,
    userId,
  } = useContext(UserContext);
  const [language, setLanguage] = useState<'EN' | 'FR' | 'DU' | 'GE'>(selectedLang);
  const [isValid, setIsValid] = useState(false);
  const [newCategory, setNewCategory] = useState({
    EN: '',
    DU: '',
    FR: '',
    GE: '',
  });
  const [category, setCategory] = useState('');
  const [currentCategories, setCurrentCategories] = useState<string[]>([]);

  const saveCategory = async () => {
    if (category === '' || currentCategories.find(element => element.toLowerCase() === category.toLowerCase())) {
      setIsValid(true);
    } else {
      try {
        setIsLoading(true);
        if (categoryType === 'Folders') {
          const tempCategories = { ...sessionCustomCategories };
          Object.keys(newCategory).map(key => {
            if (newCategory[key as keyof typeof newCategory] === '') {
              tempCategories[key as keyof typeof tempCategories].push(newCategory[language]);
            } else {
              tempCategories[key as keyof typeof tempCategories].push(newCategory[key as keyof typeof newCategory]);
            }
          });
          const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/save-custom-categories/${userId}`, {
            category: {
              category_EN: JSON.stringify(tempCategories.EN),
              category_DU: JSON.stringify(tempCategories.DU),
              category_FR: JSON.stringify(tempCategories.FR),
              category_GE: JSON.stringify(tempCategories.GE),
            },
          });
          setSessionCustomCategories(tempCategories);
        }
        if (categoryType === 'Shared Folders') {
          const tempCategories = { ...sessionCategories };
          Object.keys(newCategory).map(key => {
            if (newCategory[key as keyof typeof newCategory] === '') {
              tempCategories[key as keyof typeof tempCategories].push(newCategory[language]);
            } else {
              tempCategories[key as keyof typeof tempCategories].push(newCategory[key as keyof typeof newCategory]);
            }
          });
          const res = await axios.post(`${process.env.REACT_APP_MAIN_API}session/save-categories`, {
            category: {
              category_EN: newCategory.EN === '' ? newCategory[language] : newCategory.EN,
              category_DU: newCategory.DU === '' ? newCategory[language] : newCategory.DU,
              category_FR: newCategory.FR === '' ? newCategory[language] : newCategory.FR,
              category_GE: newCategory.GE === '' ? newCategory[language] : newCategory.GE,
            },
          });
          setSessionCategories(tempCategories);
        }
        setIsValid(false);
        setIsLoading(false);
        setCategory('');
        setNewCategory({
          EN: '',
          DU: '',
          FR: '',
          GE: '',
        });
      } catch (error) {
        setIsLoading(false);
        setIsValid(false);
        console.log('error: ', error);
      }
    }
  };

  useEffect(() => {
    if (categoryType === 'Folders') {
      setCurrentCategories(sessionCustomCategories[language]);
    } else if (categoryType === 'Shared Folders') {
      setCurrentCategories(sessionCategories[language]);
    }
    setCategory(newCategory[language]);
  }, [language]);

  useEffect(() => {
    const tempCategory = { ...newCategory };
    tempCategory[language] = category;
    setNewCategory(tempCategory);
  }, [category]);

  useEffect(() => {
    setCategory('');
    setNewCategory({
      EN: '',
      DU: '',
      FR: '',
      GE: '',
    });
    setIsValid(false);
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={styles.saveModal}
      actionButtonLabel={t('Save')}
      cancelButtonLabel={t('Cancel')}
      actionButtonEvent={saveCategory}
      closeButtonColor="Black"
    >
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{t('Save Category')}</p>
      </div>
      <div className={styles.savedWrapper}>
        <p className={styles.title}>{t('Saved Categories')}</p>
        <div className={styles.savedField}>
          {currentCategories.length === 0 ? (
            <p className={styles.button}>{t('No Category')}</p>
          ) : (
            currentCategories?.map((item, index: number) => (
              <p key={index} className={styles.button}>
                {item}
              </p>
            ))
          )}
        </div>
      </div>
      <div className={styles.inputWrapper}>
        {isValid && <p className={styles.validText}>{t('Name is empty or exists')}</p>}
        <input
          className={styles.input}
          placeholder={t('Enter the category name')}
          value={category}
          onChange={e => {
            setCategory(e.target.value);
          }}
        />
      </div>
      <LanguageTabButton language={language} setLanguage={setLanguage} />
    </Modal>
  );
};

export default SaveSessionCategoryModal;
