import React, { Fragment, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';

import styles from './styles.module.scss';

type Props = {
  items: string[];
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  selectedItem: string;
  className?: string;
};

const Dropdown: React.FC<Props> = ({ items, selectedItem, setSelectedItem, className = '' }) => {
  const currenItem = useMemo(() => items.find(item => item === selectedItem) ?? items[0], [selectedItem]);

  return (
    <Listbox value={currenItem} onChange={value => setSelectedItem(value)}>
      <div className={styles.wrapper}>
        <Listbox.Button className={classNames(styles.selected, className)}>
          <span>{currenItem}</span>
          <ArrowDownIcon className={styles.icon} />
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className={styles.listWrapper}>
            {items.map((item, itemIdx) => (
              <Listbox.Option
                key={itemIdx}
                className={({ active }) =>
                  classNames(styles.item, {
                    [styles.active]: active,
                  })
                }
                value={item}
              >
                <span className={styles.text}>{item}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Dropdown;
