import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useDebounce from '../../../hooks/useDebounce';
import SessionCategoryBar from '../SessionCategoryBar';
import SessionFilter from '../SessionFilter';
import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/right-arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/left-arrow.svg';

import styles from './styles.module.scss';

const pendingCategories = ['Sessions pending approval'];

type Props = {
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
};

const SessionFolderCategoryBar: React.FC<Props> = ({ setPageNum }) => {
  const { t } = useTranslation();
  const {
    sessionCategories,
    sessionCustomCategories,
    memberType,
    sessionKeyWord,
    selectedLang,
    searchSessionId,
    setSearchSessionId,
    setSessionKeyWord,
  } = useContext(UserContext);
  const [miniBarShow, setMiniBarShow] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState(sessionKeyWord);
  const searchName = useDebounce(searchKeyWord, 1000);
  const [tempSearchSessionId, setTempSearchSessionId] = useState(searchSessionId);
  const sessionSearchId = useDebounce(tempSearchSessionId, 1000);

  useEffect(() => {
    setSessionKeyWord(searchName);
    setSearchSessionId('');
  }, [searchName]);

  useEffect(() => {
    setSearchSessionId(sessionSearchId);
  }, [sessionSearchId]);

  useEffect(() => {
    setTempSearchSessionId(searchSessionId);
  }, [searchSessionId]);

  return (
    <>
      <div className={styles.menuWrapper}>
        {/*  
      <div className={styles.searchBar}>
          <SearchIcon className={styles.searchIcon} />
          <input
            type="text"
            placeholder={t('name of session')}
            className={styles.input}
            value={searchKeyWord}
            onChange={e => {
              setSearchKeyWord(e.target.value);
            }}
          />
          <SessionFilter />
        </div>
        <div className={styles.searchBar}>
          <SearchIcon className={styles.searchIdIcon} />
          <input
            type="text"
            placeholder={t('id of session')}
            className={styles.input}
            value={tempSearchSessionId}
            onChange={e => {
              setTempSearchSessionId(e.target.value);
            }}
          />
        </div>
          */}
        <SessionCategoryBar
          title="Folders"
          categories={sessionCustomCategories[selectedLang]}
          setPageNum={setPageNum}
        />
        <SessionCategoryBar
          title="Shared Folders"
          categories={sessionCategories[selectedLang]}
          setPageNum={setPageNum}
        />
        {memberType !== 'member' && (
          <SessionCategoryBar
            title="Shared by members"
            categories={pendingCategories}
            addFolder={false}
            setPageNum={setPageNum}
          />
        )}
      </div>
      <div
        className={classNames(styles.miniMenuBar, {
          [styles.show]: miniBarShow,
        })}
      >
        <div className={styles.relativeDiv}>
          <div className={styles.miniMenuWrapper}>
            <div className={styles.searchBar}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                placeholder={t('name of idea')}
                className={styles.input}
                onChange={e => {
                  setSearchKeyWord(e.target.value);
                }}
              />
              <SessionFilter />
            </div>
            <SessionCategoryBar
              title="Folders"
              categories={sessionCustomCategories[selectedLang]}
              setPageNum={setPageNum}
            />
            <SessionCategoryBar
              title="Shared Folders"
              categories={sessionCategories[selectedLang]}
              setPageNum={setPageNum}
            />
            {memberType !== 'member' && (
              <SessionCategoryBar
                title="Shared by members"
                categories={pendingCategories}
                addFolder={false}
                setPageNum={setPageNum}
              />
            )}
          </div>
          {miniBarShow ? (
            <div className={styles.iconWrapper}>
              <LeftArrowIcon
                fill={'#1E7EEB'}
                className={classNames(styles.showIcon, styles.show)}
                onClick={() => setMiniBarShow(false)}
              />
            </div>
          ) : (
            // <RightArrowIcon fill={'#1E7EEB'} className={styles.showIcon} onClick={() => setMiniBarShow(true)} />
            <div className={classNames(styles.iconWrapper, styles.hiddenIcon)} onClick={() => setMiniBarShow(true)}>
              <span className={styles.textField}>{t('Folders')}</span>
              <RightArrowIcon fill={'#1E7EEB'} className={styles.showIcon} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SessionFolderCategoryBar;
