import { useLoader, useThree } from '@react-three/fiber';
import React, { useMemo } from 'react';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';

type Props = {
  object: SceneSportsFiledObjectType;
};

const SportsFiled: React.FC<Props> = ({ object: { object } }) => {
  const { gl } = useThree();
  const { scene } = useLoader(GLTFLoader, object, (loader: any) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/');
    loader.setDRACOLoader(dracoLoader);
  });

  const cloneObject = useMemo(() => clone(scene), [scene]);
  cloneObject.traverse((item: any) => {
    if (item.isMesh) {
      item.material.transparent = true;
      const clonedMaterial = item.material.clone();
      clonedMaterial.anisotropy = Math.min(gl.capabilities.getMaxAnisotropy(), 50);
      item.material = clonedMaterial;
    }
  });

  return (
    <group>
      <primitive
        object={cloneObject}
        position={[0, 0, 0]}
        scale={[0.007, 0.007, 0.007]}
        // onClick={(e: any) => {
        //   // e.stopPropagation();
        //   setIsSubSportsField(false);
        // }}
        // onPointerMissed={() => {
        //   setIsSubSportsField(false);
        // }}
      />
    </group>
  );
};

export default SportsFiled;
