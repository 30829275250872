import React, { ReactNode, useContext, useState } from 'react';

import { ReactComponent as GEFlagIcon } from '../../../assets/icons/de.svg';
import { ReactComponent as FRFlagIcon } from '../../../assets/icons/fr.svg';
import { ReactComponent as GBFlagIcon } from '../../../assets/icons/gb.svg';
import { ReactComponent as DUFlagIcon } from '../../../assets/icons/nl.svg';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { UserContext } from '../../../providers/UserProvider';

type LanguagesType = {
  flag: any;
  name: string;
  value: 'EN' | 'FR' | 'DU' | 'GE';
};

const languages: LanguagesType[] = [
  {
    flag: GBFlagIcon,
    name: 'English',
    value: 'EN',
  },
  {
    flag: FRFlagIcon,
    name: 'Français',
    value: 'FR',
  },
  {
    flag: DUFlagIcon,
    name: 'Dutch',
    value: 'DU',
  },
  {
    flag: GEFlagIcon,
    name: 'German',
    value: 'GE',
  },
];

const languagesMobile: LanguagesType[] = [
  {
    flag: GBFlagIcon,
    name: 'EN',
    value: 'EN',
  },
  {
    flag: FRFlagIcon,
    name: 'FR',
    value: 'FR',
  },
  {
    flag: DUFlagIcon,
    name: 'DU',
    value: 'DU',
  },
  {
    flag: GEFlagIcon,
    name: 'GE',
    value: 'GE',
  },
];

type Props = {
  language: 'EN' | 'FR' | 'DU' | 'GE';
  setLanguage: React.Dispatch<React.SetStateAction<'EN' | 'FR' | 'DU' | 'GE'>>;
};

const LanguageTabButton: React.FC<Props> = ({ language, setLanguage }) => {
  const onSelectLang = (lang: 'EN' | 'FR' | 'DU' | 'GE') => {
    setLanguage(lang);
  };

  return (
    <div className={styles.wrapper}>
      {languages.map((lang, index) => (
        <button
          key={index}
          onClick={() => {
            onSelectLang(lang.value);
          }}
          className={classNames(styles.button, {
            [styles.active]: language === lang.value,
            [styles.en]: lang.name === 'English',
            [styles.fr]: lang.name === 'Français',
            [styles.du]: lang.name === 'Dutch',
            [styles.ge]: lang.name === 'German',
          })}
        >
          <lang.flag className={styles.icon} />
          <span>{lang.name}</span>
        </button>
      ))}
      {languagesMobile.map((lang, index) => (
        <button
          key={index}
          onClick={() => {
            onSelectLang(lang.value);
          }}
          className={classNames(styles.buttonMobile, {
            [styles.active]: language === lang.value,
            [styles.en]: lang.name === 'EN',
            [styles.fr]: lang.name === 'FR',
            [styles.du]: lang.name === 'DU',
            [styles.ge]: lang.name === 'GE',
          })}
        >
          <lang.flag className={styles.icon} />
          <span>{lang.name}</span>
        </button>
      ))}
    </div>
  );
};

export default LanguageTabButton;
