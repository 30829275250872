import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import { useAnimations, TransformControls } from '@react-three/drei';
import { useLoader, useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';
import { Color, SkeletonHelper, TextureLoader } from 'three';

import { UserContext } from '../../../providers/UserProvider';

type Props = {
  id: number;
  orbit: any;
  object: SceneCharacterType;
};

const Character: React.FC<Props> = ({
  id,
  orbit,
  object: {
    name,
    object,
    material,
    showModel,
    showSkeleton,
    opacity,
    modifySpeed,
    animationList,
    currentAnimationIndex,
    scale,
    visible,
    isSingleMode,
    position,
    rotation,
  },
}) => {
  const {
    selectedCharacterIndex,
    transformState,
    animationStop,
    singleIndex,
    isShowCanvasTools,
    setSelectedSportsObjectIndex,
    setSelectedCharacterIndex,
    changeCharacterDetails,
    setThirdLevel,
    setSecondLevel,
    setIsSubSportsField,
  } = useContext(UserContext);

  const group = useRef<any>();
  const transform = useRef<any>();
  // const loader = new GLTFLoader();
  const { gl } = useThree();
  const { scene, animations } = useLoader(GLTFLoader, object, (loader: any) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('gltf/');
    loader.setDRACOLoader(dracoLoader);
  });

  const { actions, names: animationNames } = useAnimations(animations, group);

  const colorMap = useLoader(TextureLoader, material);
  colorMap.anisotropy = Math.min(gl.capabilities.getMaxAnisotropy(), 50);

  colorMap.flipY = false; // we flip the texture so that its the right way up

  const cloneObject = useMemo(() => clone(scene), [scene]);
  console.log('cloneObject - character: ', cloneObject);

  cloneObject.traverse((item: any) => {
    if (item.isMesh) {
      item.visible = showModel;
      item.material.transparent = true;
      item.material.opacity = opacity / 100;
    }
    if (item.type === 'SkinnedMesh') {
      const clonedMaterial = item.material.clone();
      clonedMaterial.map = colorMap;
      clonedMaterial.color = new Color(0xffffff);
      clonedMaterial.roughness = 1;
      item.material = clonedMaterial;
    }
    if (item.type === 'Mesh') {
      const clonedMaterial = item.material.clone();
      clonedMaterial.map = colorMap;
      clonedMaterial.color = new Color(0xffffff);
      clonedMaterial.roughness = 1;
      item.material = clonedMaterial;
    }
  });

  const skeleton = new SkeletonHelper(cloneObject);
  skeleton.visible = showSkeleton;

  useEffect(() => {
    if (transform.current && selectedCharacterIndex === id) {
      const controls: any = transform.current;
      controls.setMode(transformState);
      const callback = (event: any) => {
        orbit.current.enabled = !event.value;
      };
      controls.addEventListener('dragging-changed', callback);
      return () => {
        controls.removeEventListener('dragging-changed', callback);
      };
    }
  }, [transformState, selectedCharacterIndex]);

  useEffect(() => {
    const characterAnimations: any = actions;
    animationList?.forEach((animation, index: number) => {
      if (currentAnimationIndex === index) {
        characterAnimations[animation].timeScale = modifySpeed;
        characterAnimations[animation].play();
        characterAnimations[animation].paused = false;
      } else {
        characterAnimations[animation].paused = true;
        if (currentAnimationIndex !== undefined) {
          characterAnimations[animation].stop();
        }
      }
    });
    // }
  }, [currentAnimationIndex, modifySpeed]);

  useEffect(() => {
    const characterAnimations: any = actions;
    animationList?.forEach((animation, index: number) => {
      if (singleIndex === index) {
        characterAnimations[animation].timeScale = modifySpeed;
        characterAnimations[animation].play();
        characterAnimations[animation].paused = !isSingleMode;
      }
    });
  }, [isSingleMode]);

  useEffect(() => {
    const characterAnimations: any = actions;
    animationList?.forEach((animation: any) => {
      characterAnimations[animation].stop();
      changeCharacterDetails(selectedCharacterIndex, { currentAnimationIndex: undefined });
    });
  }, [animationStop]);

  useEffect(() => {
    changeCharacterDetails(id, {
      animationList: animationNames.map(animationName => animationName),
    });
  }, []);

  return (
    <group ref={group} visible={visible}>
      <primitive
        name={name}
        position={position ?? [0, 0, 0]}
        rotation={rotation ?? [0, 0, 0]}
        scale={scale ?? [1, 1, 1]}
        object={cloneObject}
        onClick={(e: any) => {
          e.stopPropagation();
          setSelectedCharacterIndex(id);
          setSelectedSportsObjectIndex(-1);
          setIsSubSportsField(false);
        }}
        onPointerMissed={() => {
          setSelectedCharacterIndex(-1);
          setSelectedSportsObjectIndex(-1);
          setIsSubSportsField(false);
          setSecondLevel([]);
          setThirdLevel([]);
        }}
      />
      <primitive object={skeleton} />
      <TransformControls
        ref={transform}
        position={position ?? [0, 0, 0]}
        onUpdate={(self: any) => {
          self.attach(cloneObject);
          // setIsSavedState(true);
        }}
        // enabled={selectedCharacterIndex === id && transformState.length > 0}
        showX={selectedCharacterIndex === id && isShowCanvasTools}
        showY={selectedCharacterIndex === id && isShowCanvasTools}
        showZ={selectedCharacterIndex === id && isShowCanvasTools}
      />
    </group>
  );
};

const MemorizedCharacter = memo(Character);

export default MemorizedCharacter;
