import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';

import { UserContext } from '../../../providers/UserProvider';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as CloneIcon } from '../../../assets/icons/copy-simple.svg';
import { ReactComponent as MoveIcon } from '../../../assets/icons/move.svg';
import { ReactComponent as RotateIcon } from '../../../assets/icons/rotate.svg';
import { ReactComponent as ScaleIcon } from '../../../assets/icons/scale.svg';

import styles from './styles.module.scss';

const transforms = [
  { name: 'translate', image: MoveIcon },
  { name: 'rotate', image: RotateIcon },
  { name: 'scale', image: ScaleIcon },
];

const Tools: React.FC = () => {
  const {
    transformState,
    selectedCharacterIndex,
    selectedSportsObjectIndex,
    sceneCharacters,
    sceneSportsObjects,
    characterMaterials,
    setTransformState,
    changeCharacterDetails,
    changeSportsObjectDetails,
    setSecondLevel,
    setThirdLevel,
    setIsObjectColor,
    setIsObjectColorTwo,
    setIsObjectColorThree,
    addCharacterToScene,
    addSportsObjectToScene,
    setSelectedCharacterIndex,
    setSelectedSportsObjectIndex,
    addMaterials,
    setIsSavedState,
    setIsCustomImage,
  } = useContext(UserContext);

  const deleteObject = () => {
    if (selectedCharacterIndex !== -1 || selectedSportsObjectIndex !== -1) {
      changeCharacterDetails(selectedCharacterIndex, { visible: false, position: [0, 0, 100] });
      changeSportsObjectDetails(selectedSportsObjectIndex, { visible: false, position: [0, 0, 100] });
      setSecondLevel([]);
      setThirdLevel([]);
      setIsCustomImage(false);
      setIsSavedState(true);
      setIsObjectColor(false);
      setIsObjectColorTwo(false);
      setIsObjectColorThree(false);
    }
  };

  const cloneObject = () => {
    if (selectedCharacterIndex !== -1) {
      addCharacterToScene(
        sceneCharacters[selectedCharacterIndex].object ?? '',
        sceneCharacters[selectedCharacterIndex].material ?? '',
        sceneCharacters[selectedCharacterIndex].name,
        sceneCharacters[selectedCharacterIndex].position ?? [0, 0, 0],
        sceneCharacters[selectedCharacterIndex].scale ?? [1, 1, 1],
        sceneCharacters.length
      );
      addMaterials(characterMaterials[selectedCharacterIndex]);
      setIsCustomImage(false);
      setIsSavedState(true);
    }
    if (selectedSportsObjectIndex !== -1) {
      addSportsObjectToScene(
        sceneSportsObjects[selectedSportsObjectIndex].object ?? '',
        sceneSportsObjects[selectedSportsObjectIndex].name,
        sceneSportsObjects[selectedSportsObjectIndex].isColor ?? false,
        sceneSportsObjects[selectedSportsObjectIndex].color ?? '',
        sceneSportsObjects[selectedSportsObjectIndex].colorObj ?? '',
        sceneSportsObjects[selectedSportsObjectIndex].isColorTwo ?? false,
        sceneSportsObjects[selectedSportsObjectIndex].colorTwo ?? '',
        sceneSportsObjects[selectedSportsObjectIndex].colorTwoObj ?? '',
        sceneSportsObjects[selectedSportsObjectIndex].isColorThree ?? false,
        sceneSportsObjects[selectedSportsObjectIndex].colorThree ?? '',
        sceneSportsObjects[selectedSportsObjectIndex].colorThreeObj ?? '',
        sceneSportsObjects[selectedSportsObjectIndex].position ?? [0, 0, 0],
        sceneSportsObjects[selectedSportsObjectIndex].scale ?? [1, 1, 1],
        sceneSportsObjects.length
      );
      setIsCustomImage(false);
      setIsSavedState(true);
    }
    setSelectedCharacterIndex(-1);
    setSelectedSportsObjectIndex(-1);
  };

  useEffect(() => {
    if (transformState === '') {
      setTransformState('translate');
    }
  }, []);

  return (
    <>
      <div className={styles.transformWrapper}>
        {transforms.map(transform => (
          <transform.image
            key={transform.name}
            className={classNames(styles.transform, {
              [styles.selected]: transformState === transform.name,
            })}
            onClick={() =>
              transformState === transform.name ? setTransformState('') : setTransformState(transform.name)
            }
          />
        ))}
      </div>
      <DeleteIcon
        className={styles.delete}
        onClick={() => {
          deleteObject();
        }}
      />
      <CloneIcon
        className={styles.clone}
        onClick={() => {
          cloneObject();
        }}
      />
    </>
  );
};

export default Tools;
