import React, {
  createContext,
  ReactNode,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import i18n from '../i18n';

type PropsType = {
  children: ReactNode;
};

type ContextProps = {
  isField: boolean;
  setIsField: Dispatch<SetStateAction<boolean>>;
  currentTabIndex: number;
  setCurrentTabIndex: Dispatch<SetStateAction<number>>;
  firstLevel?: TabItemType[];
  setFirstLevel: Dispatch<SetStateAction<TabItemType[] | undefined>>;
  secondLevel?: TabItemType[];
  setSecondLevel: Dispatch<SetStateAction<TabItemType[] | undefined>>;
  thirdLevel?: TabItemType[];
  setThirdLevel: Dispatch<SetStateAction<TabItemType[] | undefined>>;
  currentFieldImage: string;
  setCurrentFieldImage: Dispatch<SetStateAction<string>>;
  selectedLang: 'EN' | 'DU' | 'FR' | 'GE';
  setSelectedLang: Dispatch<SetStateAction<'EN' | 'DU' | 'FR' | 'GE'>>;
  sceneCharacters: SceneCharacterType[];
  setSceneCharacters: Dispatch<SetStateAction<SceneCharacterType[]>>;
  addCharacterToScene: (
    object: string,
    material: string,
    name: string,
    position: number[],
    scale: number[],
    len: number
  ) => void;
  changeCharacterDetails: (id: number, updated: Partial<SceneCharacterType>) => void;
  characterRotationInfo: number[];
  setCharacterRotationInfo: Dispatch<SetStateAction<number[]>>;
  characterMaterials: TabItemType[][];
  setCharacterMaterials: Dispatch<SetStateAction<TabItemType[][]>>;
  addMaterials: (material: TabItemType[]) => void;
  transformState: string;
  setTransformState: Dispatch<SetStateAction<string>>;
  selectedCharacterIndex: number;
  setSelectedCharacterIndex: Dispatch<SetStateAction<number>>;
  sceneSportsObjects: SceneSportsObjectType[];
  setSceneSportsObjects: Dispatch<SetStateAction<SceneSportsObjectType[]>>;
  addSportsObjectToScene: (
    object: string,
    name: string,
    isColor: boolean,
    color: string,
    colorObj: string,
    isColorTwo: boolean,
    colorTwo: string,
    colorTwoObj: string,
    isColorThree: boolean,
    colorThree: string,
    colorThreeObj: string,
    position: number[],
    scale: number[],
    len: number
  ) => void;
  changeSportsObjectDetails: (id: number, updated: Partial<SceneSportsObjectType>) => void;
  sportsObjectRotationInfo: number[];
  setSportsObjectRotationInfo: Dispatch<SetStateAction<number[]>>;
  selectedSportsObjectIndex: number;
  setSelectedSportsObjectIndex: Dispatch<SetStateAction<number>>;
  isObjectColor: boolean;
  setIsObjectColor: Dispatch<SetStateAction<boolean>>;
  isObjectColorTwo: boolean;
  setIsObjectColorTwo: Dispatch<SetStateAction<boolean>>;
  isObjectColorThree: boolean;
  setIsObjectColorThree: Dispatch<SetStateAction<boolean>>;
  sportsFieldObject: SceneSportsFiledObjectType;
  setSportsFieldObject: Dispatch<SetStateAction<SceneSportsFiledObjectType>>;
  isTab: boolean;
  setIsTab: Dispatch<SetStateAction<boolean>>;
  isSecondLevel: boolean;
  setIsSecondLevel: Dispatch<SetStateAction<boolean>>;
  isSubSportsField: boolean;
  setIsSubSportsField: Dispatch<SetStateAction<boolean>>;
  animationStop: boolean;
  setAnimationStop: Dispatch<SetStateAction<boolean>>;
  membershipState: number;
  setMembershipState: Dispatch<SetStateAction<number>>;
  memberType: string;
  setMemberType: Dispatch<SetStateAction<string>>;
  userInfo: {
    [key: string]: any;
  };
  setUserInfo: Dispatch<
    SetStateAction<{
      [key: string]: any;
    }>
  >;
  categories: string[];
  setCategories: Dispatch<SetStateAction<string[]>>;
  customCategories: string[];
  setCustomCategories: Dispatch<SetStateAction<string[]>>;
  singleIndex: number;
  setSingleIndex: Dispatch<SetStateAction<number>>;
  characterGroup: any;
  objectGroup: any;
  userId: number;
  setUserId: Dispatch<SetStateAction<number>>;
  isReload: boolean;
  setIsReload: Dispatch<SetStateAction<boolean>>;
  sceneId: number;
  setSceneId: Dispatch<SetStateAction<number>>;
  screenshot: string;
  setScreenshot: Dispatch<SetStateAction<string>>;
  names: Array<string>;
  setNames: Dispatch<SetStateAction<Array<string>>>;
  description: any;
  setDescription: Dispatch<SetStateAction<any>>;
  folderIdeas: FolderIdeaType[];
  setFolderIdeas: Dispatch<SetStateAction<FolderIdeaType[]>>;
  folderType: string;
  setFolderType: Dispatch<SetStateAction<string>>;
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
  isSavedState: boolean;
  setIsSavedState: Dispatch<SetStateAction<boolean>>;
  sortType: string;
  setSortType: Dispatch<SetStateAction<string>>;
  isSearch: boolean;
  setIsSearch: Dispatch<SetStateAction<boolean>>;
  currentImage: string;
  setCurrentImage: Dispatch<SetStateAction<string>>;
  currentImageBlob: any;
  setCurrentImageBlob: Dispatch<SetStateAction<any>>;
  isCustomImage: boolean;
  setIsCustomImage: Dispatch<SetStateAction<boolean>>;
  descriptionTitle: string;
  setDescriptionTitle: Dispatch<SetStateAction<string>>;
  editorState: string;
  setEditorState: Dispatch<SetStateAction<string>>;
  openFolderModal: boolean;
  setOpenFolderModal: Dispatch<SetStateAction<boolean>>;
  isPDFModal: boolean;
  setIsPDFModal: Dispatch<SetStateAction<boolean>>;
  fromIdea: boolean;
  setFromIdea: Dispatch<SetStateAction<boolean>>;
  keyWord: string;
  setKeyWord: Dispatch<SetStateAction<string>>;
  isModify: boolean;
  setIsModify: Dispatch<SetStateAction<boolean>>;
  customImage: any;
  setCustomImage: Dispatch<SetStateAction<any>>;
  customImageBlob: any;
  setCustomImageBlob: Dispatch<SetStateAction<any>>;
  customVideo: any;
  setCustomVideo: Dispatch<SetStateAction<any>>;
  customVideoBlob: any;
  setCustomVideoBlob: Dispatch<SetStateAction<any>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  openSessionsModal: boolean;
  setOpenSessionsModal: Dispatch<SetStateAction<boolean>>;
  selectedMenuTitle: string;
  setSelectedMenuTitle: Dispatch<SetStateAction<string>>;
  sessionCategories: SessionCategoryType;
  setSessionCategories: Dispatch<SetStateAction<SessionCategoryType>>;
  sessionCustomCategories: SessionCategoryType;
  setSessionCustomCategories: Dispatch<SetStateAction<SessionCategoryType>>;
  sessions: SessionType[];
  setSessions: Dispatch<SetStateAction<SessionType[]>>;
  ideaType: string;
  setIdeaType: Dispatch<SetStateAction<string>>;
  sessionFolderType: string;
  setSessionFolderType: Dispatch<SetStateAction<string>>;
  selectedSessionCategory: string;
  setSelectedSessionCategory: Dispatch<SetStateAction<string>>;
  sessionKeyWord: string;
  setSessionKeyWord: Dispatch<SetStateAction<string>>;
  sessionSortType: string;
  setSessionSortType: Dispatch<SetStateAction<string>>;
  sessionDetail: SubSessionType[];
  setSessionDetail: Dispatch<SetStateAction<SubSessionType[]>>;
  sessionID: number;
  setSessionID: Dispatch<SetStateAction<number>>;
  selectedSessionIdeaID: number;
  setSelectedSessionIdeaID: Dispatch<SetStateAction<number>>;
  newSession: NewSessionType;
  setNewSession: Dispatch<SetStateAction<NewSessionType>>;
  searchSessionId: string;
  setSearchSessionId: Dispatch<SetStateAction<string>>;
  modifySessionId: number;
  setModifySessionId: Dispatch<SetStateAction<number>>;
  saveIdeaName: string;
  setSaveIdeaName: Dispatch<SetStateAction<string>>;
  openIdeasModal: boolean;
  setOpenIdeasModal: Dispatch<SetStateAction<boolean>>;
  subSessionIds: number[];
  setSubSessionIds: Dispatch<SetStateAction<number[]>>;
  isNewSession: boolean;
  setIsNewSession: Dispatch<SetStateAction<boolean>>;
  isResetIdeas: boolean;
  setIsResetIdeas: Dispatch<SetStateAction<boolean>>;
  isShowCanvasTools: boolean;
  setIsShowCanvasTools: Dispatch<SetStateAction<boolean>>;
  isFileUploading: boolean;
  setIsFileUploading: Dispatch<SetStateAction<boolean>>;
};

export const UserContext = createContext<ContextProps>({
  isField: false,
  setIsField: () => {},
  currentTabIndex: 0,
  setCurrentTabIndex: () => {},
  firstLevel: [],
  setFirstLevel: () => {},
  secondLevel: [],
  setSecondLevel: () => {},
  thirdLevel: [],
  setThirdLevel: () => {},
  currentFieldImage: 'uploads/images/Sports-field/others-wood-flooring.jpg',
  setCurrentFieldImage: () => {},
  selectedLang: 'EN',
  setSelectedLang: () => {},
  sceneCharacters: [],
  setSceneCharacters: () => {},
  addCharacterToScene: () => {},
  characterRotationInfo: [],
  setCharacterRotationInfo: () => {},
  changeCharacterDetails: () => {},
  characterMaterials: [],
  setCharacterMaterials: () => {},
  addMaterials: () => {},
  transformState: '',
  setTransformState: () => {},
  selectedCharacterIndex: -1,
  setSelectedCharacterIndex: () => {},
  sceneSportsObjects: [],
  setSceneSportsObjects: () => {},
  addSportsObjectToScene: () => {},
  changeSportsObjectDetails: () => {},
  sportsObjectRotationInfo: [],
  setSportsObjectRotationInfo: () => {},
  selectedSportsObjectIndex: -1,
  setSelectedSportsObjectIndex: () => {},
  isObjectColor: false,
  setIsObjectColor: () => {},
  isObjectColorTwo: false,
  setIsObjectColorTwo: () => {},
  isObjectColorThree: false,
  setIsObjectColorThree: () => {},
  sportsFieldObject: {
    object: '',
  },
  setSportsFieldObject: () => {},
  isTab: false,
  setIsTab: () => {},
  isSecondLevel: false,
  setIsSecondLevel: () => {},
  isSubSportsField: true,
  setIsSubSportsField: () => {},
  animationStop: true,
  setAnimationStop: () => {},
  membershipState: 0,
  setMembershipState: () => {},
  memberType: 'member',
  setMemberType: () => {},
  userInfo: {},
  setUserInfo: () => {},
  categories: [],
  setCategories: () => {},
  customCategories: [],
  setCustomCategories: () => {},
  singleIndex: -1,
  setSingleIndex: () => {},
  characterGroup: null,
  objectGroup: null,
  userId: -1,
  setUserId: () => {},
  isReload: false,
  setIsReload: () => {},
  sceneId: -1,
  setSceneId: () => {},
  screenshot: '',
  setScreenshot: () => {},
  names: [],
  setNames: () => {},
  description: {},
  setDescription: () => {},
  folderIdeas: [],
  setFolderIdeas: () => {},
  folderType: 'Folders',
  setFolderType: () => {},
  selectedCategory: '',
  setSelectedCategory: () => {},
  isSavedState: false,
  setIsSavedState: () => {},
  sortType: 'Newest first',
  setSortType: () => {},
  isSearch: false,
  setIsSearch: () => {},
  currentImage: '',
  setCurrentImage: () => {},
  currentImageBlob: '',
  setCurrentImageBlob: () => {},
  isCustomImage: false,
  setIsCustomImage: () => {},
  descriptionTitle: '',
  setDescriptionTitle: () => {},
  editorState: '',
  setEditorState: () => {},
  isPDFModal: false,
  setIsPDFModal: () => {},
  openFolderModal: false,
  setOpenFolderModal: () => {},
  fromIdea: false,
  setFromIdea: () => {},
  keyWord: '',
  setKeyWord: () => {},
  isModify: true,
  setIsModify: () => {},
  customImage: '',
  setCustomImage: () => {},
  customImageBlob: '',
  setCustomImageBlob: () => {},
  customVideo: '',
  setCustomVideo: () => {},
  customVideoBlob: '',
  setCustomVideoBlob: () => {},
  isLoading: false,
  setIsLoading: () => {},
  openSessionsModal: false,
  setOpenSessionsModal: () => {},
  selectedMenuTitle: 'IDEAS',
  setSelectedMenuTitle: () => {},
  sessionCategories: {
    EN: [],
    DU: [],
    FR: [],
    GE: [],
  },
  setSessionCategories: () => {},
  sessionCustomCategories: {
    EN: [],
    DU: [],
    FR: [],
    GE: [],
  },
  setSessionCustomCategories: () => {},
  sessions: [],
  setSessions: () => {},
  ideaType: 'idea',
  setIdeaType: () => {},
  sessionFolderType: 'All',
  setSessionFolderType: () => {},
  selectedSessionCategory: '',
  setSelectedSessionCategory: () => {},
  sessionKeyWord: '',
  setSessionKeyWord: () => {},
  sessionSortType: 'Newest first',
  setSessionSortType: () => {},
  sessionDetail: [],
  setSessionDetail: () => {},
  sessionID: -1,
  setSessionID: () => {},
  selectedSessionIdeaID: -1,
  setSelectedSessionIdeaID: () => {},
  newSession: {
    title_EN: '',
    title_DU: '',
    title_FR: '',
    title_GE: '',
    text_EN: '',
    text_DU: '',
    text_FR: '',
    text_GE: '',
    category_EN: '',
    category_DU: '',
    category_FR: '',
    category_GE: '',
    custom_category_EN: '',
    custom_category_DU: '',
    custom_category_FR: '',
    custom_category_GE: '',
    approval: false,
    isShared: false,
    rejected: false,
    subSessions: [
      {
        id: 0,
        session: -1,
        title_EN: '',
        title_DU: '',
        title_FR: '',
        title_GE: '',
        text_EN: '',
        text_DU: '',
        text_FR: '',
        text_GE: '',
        repeat_number: [0],
        execution_time: [''],
        ideas: [
          {
            id: 0,
            idea: -1,
          },
        ],
      },
    ],
  },
  setNewSession: () => {},
  searchSessionId: '',
  setSearchSessionId: () => {},
  modifySessionId: -1,
  setModifySessionId: () => {},
  saveIdeaName: '',
  setSaveIdeaName: () => {},
  openIdeasModal: false,
  setOpenIdeasModal: () => {},
  subSessionIds: [],
  setSubSessionIds: () => {},
  isNewSession: false,
  setIsNewSession: () => {},
  isResetIdeas: false,
  setIsResetIdeas: () => {},
  isShowCanvasTools: false,
  setIsShowCanvasTools: () => {},
  isFileUploading: false,
  setIsFileUploading: () => {},
});

const UserProvider = ({ children }: PropsType) => {
  const [isField, setIsField] = useState<boolean>(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [firstLevel, setFirstLevel] = useState<TabItemType[]>();
  const [secondLevel, setSecondLevel] = useState<TabItemType[]>();
  const [thirdLevel, setThirdLevel] = useState<TabItemType[]>();
  const [sceneCharacters, setSceneCharacters] = useState<SceneCharacterType[]>([]);
  const [characterRotationInfo, setCharacterRotationInfo] = useState<number[]>([]);
  const [currentFieldImage, setCurrentFieldImage] = useState<string>(
    'uploads/images/Sports-field/others-wood-flooring.jpg'
  );
  const [characterMaterials, setCharacterMaterials] = useState<TabItemType[][]>([]);
  const [selectedLang, setSelectedLang] = useState<'EN' | 'DU' | 'FR' | 'GE'>('EN');
  const [transformState, setTransformState] = useState<string>('');
  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState<number>(-1);
  const [sceneSportsObjects, setSceneSportsObjects] = useState<SceneSportsObjectType[]>([]);
  const [selectedSportsObjectIndex, setSelectedSportsObjectIndex] = useState<number>(-1);
  const [isObjectColor, setIsObjectColor] = useState<boolean>(false);
  const [isObjectColorTwo, setIsObjectColorTwo] = useState<boolean>(false);
  const [isObjectColorThree, setIsObjectColorThree] = useState<boolean>(false);
  const [sportsFieldObject, setSportsFieldObject] = useState<SceneSportsFiledObjectType>({
    object: '',
  });
  const [sportsObjectRotationInfo, setSportsObjectRotationInfo] = useState<number[]>([]);
  const [isTab, setIsTab] = useState<boolean>(false);
  const [isSecondLevel, setIsSecondLevel] = useState<boolean>(false);
  const [isSubSportsField, setIsSubSportsField] = useState<boolean>(true);
  const [animationStop, setAnimationStop] = useState<boolean>(true);
  const [membershipState, setMembershipState] = useState<number>(0);
  const [memberType, setMemberType] = useState<string>('member');
  const [userInfo, setUserInfo] = useState<{ [key: string]: any }>({});
  const [categories, setCategories] = useState<string[]>([]);
  const [customCategories, setCustomCategories] = useState<string[]>([]);
  const [singleIndex, setSingleIndex] = useState<number>(-1);
  const characterGroup = useRef<any>(null);
  const objectGroup = useRef<any>(null);
  const [userId, setUserId] = useState<number>(-1);
  const [isReload, setIsReload] = useState<boolean>(false);
  const [sceneId, setSceneId] = useState<number>(-1);
  const [screenshot, setScreenshot] = useState<string>('');
  const [names, setNames] = useState<Array<string>>([]);
  const [description, setDescription] = useState<any>();
  const [folderIdeas, setFolderIdeas] = useState<FolderIdeaType[]>([]);
  const [folderType, setFolderType] = useState<string>('Folders');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [isSavedState, setIsSavedState] = useState(false);
  const [sortType, setSortType] = useState('Newest first');
  const [isSearch, setIsSearch] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentImageBlob, setCurrentImageBlob] = useState('');
  const [isCustomImage, setIsCustomImage] = useState(false);
  const [descriptionTitle, setDescriptionTitle] = useState('');
  const [editorState, setEditorState] = useState('');
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [isPDFModal, setIsPDFModal] = useState(false);
  const [fromIdea, setFromIdea] = useState(false);
  const [keyWord, setKeyWord] = useState<string>('');
  const [isModify, setIsModify] = useState(true);
  const [customImage, setCustomImage] = useState<any>('');
  const [customImageBlob, setCustomImageBlob] = useState<any>('');
  const [customVideo, setCustomVideo] = useState<any>('');
  const [customVideoBlob, setCustomVideoBlob] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [openSessionsModal, setOpenSessionsModal] = useState(false);
  const [selectedMenuTitle, setSelectedMenuTitle] = useState('IDEAS');
  const [sessionCategories, setSessionCategories] = useState<SessionCategoryType>({
    EN: [],
    DU: [],
    FR: [],
    GE: [],
  });
  const [sessionCustomCategories, setSessionCustomCategories] = useState<SessionCategoryType>({
    EN: [],
    DU: [],
    FR: [],
    GE: [],
  });
  const [sessions, setSessions] = useState<SessionType[]>([]);
  const [ideaType, setIdeaType] = useState<string>('idea');
  const [sessionFolderType, setSessionFolderType] = useState<string>('All');
  const [selectedSessionCategory, setSelectedSessionCategory] = useState<string>('');
  const [sessionKeyWord, setSessionKeyWord] = useState<string>('');
  const [sessionSortType, setSessionSortType] = useState<string>('Newest first');
  const [sessionDetail, setSessionDetail] = useState<SubSessionType[]>([]);
  const [sessionID, setSessionID] = useState<number>(-1);
  const [selectedSessionIdeaID, setSelectedSessionIdeaID] = useState(-1);
  const [newSession, setNewSession] = useState<NewSessionType>({
    title_EN: '',
    title_DU: '',
    title_FR: '',
    title_GE: '',
    text_EN: '',
    text_DU: '',
    text_FR: '',
    text_GE: '',
    category_EN: '',
    category_DU: '',
    category_FR: '',
    category_GE: '',
    custom_category_EN: '',
    custom_category_DU: '',
    custom_category_FR: '',
    custom_category_GE: '',
    approval: false,
    isShared: false,
    rejected: false,
    subSessions: [
      {
        id: 0,
        session: -1,
        title_EN: '',
        title_DU: '',
        title_FR: '',
        title_GE: '',
        text_EN: '',
        text_DU: '',
        text_FR: '',
        text_GE: '',
        repeat_number: [0],
        execution_time: [''],
        ideas: [
          {
            id: 0,
            idea: -1,
          },
        ],
      },
    ],
  });
  const [searchSessionId, setSearchSessionId] = useState('');
  const [modifySessionId, setModifySessionId] = useState(-1);
  const [saveIdeaName, setSaveIdeaName] = useState('');
  const [openIdeasModal, setOpenIdeasModal] = useState(false);
  const [subSessionIds, setSubSessionIds] = useState<number[]>([]);
  const [isNewSession, setIsNewSession] = useState(false);
  const [isResetIdeas, setIsResetIdeas] = useState(false);
  const [isShowCanvasTools, setIsShowCanvasTools] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const addCharacterToScene = useCallback(
    (object: string, material: string, name: string, position: number[], scale: number[], len: number) => {
      setSceneCharacters(prevItems => [
        ...prevItems,
        {
          id: len,
          object: object,
          material: material,
          name: `${name}`,
          showModel: true,
          showSkeleton: false,
          opacity: 100,
          modifySpeed: 1,
          isSingleMode: false,
          position: [position[0], position[1], position[2]],
          scale: [scale[0], scale[1], scale[2]],
          rotation: [0, 0, 0],
          visible: true,
        },
      ]);
    },
    []
  );

  const addMaterials = useCallback((material: TabItemType[]) => {
    setCharacterMaterials(prevItems => [...prevItems, material]);
  }, []);

  const changeCharacterDetails = useCallback((id: number, updated: Partial<SceneCharacterType>) => {
    setSceneCharacters(prevItems => {
      prevItems[id] = {
        ...prevItems[id],
        ...updated,
      };
      return [...prevItems];
    });
  }, []);

  const addSportsObjectToScene = useCallback(
    (
      object: string,
      name: string,
      isColor: boolean,
      color: string,
      colorObj: string,
      isColorTwo: boolean,
      colorTwo: string,
      colorTwoObj: string,
      isColorThree: boolean,
      colorThree: string,
      colorThreeObj: string,
      position: number[],
      scale: number[],
      len: number
    ) => {
      setSceneSportsObjects(prevItems => [
        ...prevItems,
        {
          object: object,
          name: `${name}`,
          opacity: 100,
          color: color,
          isColor: isColor,
          colorObj: colorObj,
          colorTwo: colorTwo,
          isColorTwo: isColorTwo,
          colorTwoObj: colorTwoObj,
          colorThree: colorThree,
          isColorThree: isColorThree,
          colorThreeObj: colorThreeObj,
          position: [position[0], position[1], position[2]],
          rotation: [0, 0, 0],
          scale: [scale[0], scale[1], scale[2]],
          visible: true,
        },
      ]);
    },
    []
  );

  const changeSportsObjectDetails = useCallback((id: number, updated: Partial<SceneSportsObjectType>) => {
    setSceneSportsObjects(prevItems => {
      prevItems[id] = {
        ...prevItems[id],
        ...updated,
      };
      return [...prevItems];
    });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLang.toLowerCase());
  }, [selectedLang]);

  return (
    <UserContext.Provider
      value={{
        isField,
        setIsField,
        currentTabIndex,
        setCurrentTabIndex,
        firstLevel,
        setFirstLevel,
        secondLevel,
        setSecondLevel,
        thirdLevel,
        setThirdLevel,
        sceneCharacters,
        setSceneCharacters,
        addCharacterToScene,
        changeCharacterDetails,
        characterRotationInfo,
        setCharacterRotationInfo,
        characterMaterials,
        setCharacterMaterials,
        addMaterials,
        currentFieldImage,
        setCurrentFieldImage,
        selectedLang,
        setSelectedLang,
        transformState,
        setTransformState,
        selectedCharacterIndex,
        setSelectedCharacterIndex,
        sceneSportsObjects,
        setSceneSportsObjects,
        addSportsObjectToScene,
        changeSportsObjectDetails,
        sportsObjectRotationInfo,
        setSportsObjectRotationInfo,
        selectedSportsObjectIndex,
        setSelectedSportsObjectIndex,
        isObjectColor,
        setIsObjectColor,
        isObjectColorTwo,
        setIsObjectColorTwo,
        isObjectColorThree,
        setIsObjectColorThree,
        sportsFieldObject,
        setSportsFieldObject,
        isTab,
        setIsTab,
        isSecondLevel,
        setIsSecondLevel,
        isSubSportsField,
        setIsSubSportsField,
        animationStop,
        setAnimationStop,
        membershipState,
        setMembershipState,
        memberType,
        setMemberType,
        userInfo,
        setUserInfo,
        categories,
        setCategories,
        customCategories,
        setCustomCategories,
        singleIndex,
        setSingleIndex,
        characterGroup,
        objectGroup,
        userId,
        setUserId,
        isReload,
        setIsReload,
        sceneId,
        setSceneId,
        screenshot,
        setScreenshot,
        names,
        setNames,
        description,
        setDescription,
        folderIdeas,
        setFolderIdeas,
        folderType,
        setFolderType,
        selectedCategory,
        setSelectedCategory,
        isSavedState,
        setIsSavedState,
        sortType,
        setSortType,
        isSearch,
        setIsSearch,
        currentImage,
        setCurrentImage,
        currentImageBlob,
        setCurrentImageBlob,
        isCustomImage,
        setIsCustomImage,
        descriptionTitle,
        setDescriptionTitle,
        editorState,
        setEditorState,
        isPDFModal,
        setIsPDFModal,
        openFolderModal,
        setOpenFolderModal,
        fromIdea,
        setFromIdea,
        keyWord,
        setKeyWord,
        isModify,
        setIsModify,
        customImage,
        setCustomImage,
        customImageBlob,
        setCustomImageBlob,
        customVideo,
        setCustomVideo,
        customVideoBlob,
        setCustomVideoBlob,
        isLoading,
        setIsLoading,
        openSessionsModal,
        setOpenSessionsModal,
        selectedMenuTitle,
        setSelectedMenuTitle,
        sessionCategories,
        setSessionCategories,
        sessionCustomCategories,
        setSessionCustomCategories,
        sessions,
        setSessions,
        ideaType,
        setIdeaType,
        sessionFolderType,
        setSessionFolderType,
        selectedSessionCategory,
        setSelectedSessionCategory,
        sessionKeyWord,
        setSessionKeyWord,
        sessionSortType,
        setSessionSortType,
        sessionDetail,
        setSessionDetail,
        sessionID,
        setSessionID,
        selectedSessionIdeaID,
        setSelectedSessionIdeaID,
        newSession,
        setNewSession,
        searchSessionId,
        setSearchSessionId,
        modifySessionId,
        setModifySessionId,
        saveIdeaName,
        setSaveIdeaName,
        openIdeasModal,
        setOpenIdeasModal,
        subSessionIds,
        setSubSessionIds,
        isNewSession,
        setIsNewSession,
        isResetIdeas,
        setIsResetIdeas,
        isShowCanvasTools,
        setIsShowCanvasTools,
        isFileUploading,
        setIsFileUploading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
